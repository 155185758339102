<template>
  <div class="form-table-container">
    <div class="mt-7"></div>
    <div v-bind="result.formTableAttrs">
      <fieldsRows
          :templateContent="result"
          v-on="$listeners"
          @field-value-changed="checkInput(...arguments)"
          :valueLine="valueLine"
          :isValid="isValid"/>
    </div>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';

export default {
  components: {fieldsRows},
  props: ['result', 'valueLine', 'isValid'],
  computed: {
    localResult() {
      return this.result;
    },
  },
  methods: {
    checkInput(field, value) {
      // let localArray = { ...this.result };
      // localArray.fields[field].value = value;
      if (typeof this.localResult.valueLine == 'undefined') {
        this.$set(this.localResult, 'valueLine', {});
      }
      this.$set(this.localResult.valueLine, field, value);
      //console.log("dialog-result-changed", this.localResult);
      this.$emit('dialog-result-changed', this.localResult);
    },
  },
};
</script>