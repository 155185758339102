<template>
  <div ref="testStatus">
    <XDataTable
        v-model="items"
        :items-request="itemsRequest"
        :headers="headers"
        no-top
        responsive-height
        no-elevation
        class="test-status-table"
        :custom-range="range"
        :refresh="refresh"
        :no-page-controls="noPageControls"
        :items-per-page="itemsPerPage"
        :search-text="searchText"
        @loading="$emit('loading', $event)">
      <template #above-table>
        <div class="test-status-header">
          <div class="test-status-title">
            <span v-if="title">{{ title }}</span>
          </div>
          <div>
            <TestStatusLegend v-if="showLegend"/>
          </div>
        </div>
      </template>
      <template #[`item.groupName`]="{ value, row }">
        <router-link :to="getTestGroupPath(row.id)" class="test-group-link">
          {{ `${value} (${row.user.firstName} ${row.user.lastName})` }}
        </router-link>
      </template>
      <template #[`item.testsStatus`]="{ value, row }">
        <test-status-buttons
            :value="value"
            :total="row.testsTotal"
            :group-link="getTestGroupPath(row.id)"
            @split-click="goToTestGroup(...arguments, row.id)"/>
      </template>
    </XDataTable>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable';
import TestStatusButtons from '@/components/specific/TestStatusButtons';
import TestStatusLegend from '@/commonComponents/dynamic/testStatusLegend.vue';

export default {
  name: 'TestStatus',
  components: {
    TestStatusButtons,
    XDataTable,
    TestStatusLegend,
  },
  props: {
    title: String,
    range: Object,
    refresh: Number,
    noPageControls: Boolean,
    itemsPerPage: Number,
    showTestName: {
      type: Boolean,
      default: true,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    searchText: String,
  },
  data() {
    return {
      customHeight: 200,
      groupStartTypes: {
        1: 'Scheduled',
        2: 'Manual',
        3: 'API',
      },
      items: [],
    };
  },
  methods: {
    getFilteredStatusButtons(statusButtons) {
      for (const [key, value] of Object.entries(statusButtons)) {
        if (!value) delete statusButtons[key];
      }
      return Object.entries(statusButtons);
    },
    getTestGroupPath(groupId) {
      return `/?f=testing&f2=testsGroup&groupID=${groupId}`;
    },
    goToTestGroup(key, value, status, groupId) {
      this.safeClick(`${this.getTestGroupPath(groupId)}&status=${status}`);
    },
  },
  computed: {
    itemsRequest() {
      return this.services.chart.getTestStatus;
    },
    headers() {
      const headers = [
        {
          text: 'Start',
          value: 'startTime',
          formatter: (value) => {
            return this.unixToDateTimeString(value);
          },
          width: 154,
        },
        {
          text: 'Name',
          value: 'groupName',
          width: 300,
        },
        {
          text: 'Status',
          value: 'testsStatus',
          width: '100%',
        },
      ];

      if (!this.showTestName) {
        headers.splice(1, 1);
      }

      return headers;
    },
  },
};
</script>

<style scoped>
.test-status-table {
  height: 100%;
}

.total-button >>> .v-btn:not(.v-btn--round).v-size--default {
  font-size: 12px;
}

.test-status-title {
  font-size: 18px;
}

.test-status-header {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
</style>