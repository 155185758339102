<template>
  <v-toolbar-items >
    <v-col>
      <div id="upperMenu" v-if="!empty(menu) && menu!=null">
        <recursiveMenu :root="0" :iterator="menu"></recursiveMenu>
      </div>
    </v-col>
  </v-toolbar-items>
</template>
<script>
  import recursiveMenu from "@/commonComponents/recursiveMenu.vue";
  export default {
    components: { recursiveMenu },
    props: ["menu"],
    methods: {
      getpage: function() {}
    }
  };
</script>