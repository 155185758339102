<template>
  <div>
    <v-text-field
      v-if="$vuetify.breakpoint.smAndUp || !minify"
      v-model="localSearch"
      append-icon="mdi-magnify"
      :label="label"
      hide-details
      outlined
      dense
      @click.stop
      @focus="$emit('focus')"
      :id="id"
      clearable
    ></v-text-field>
    <!-- <primaryTextfield
      type="text"
      label="Search"
      v-model="localSearch"
      :fieldAttrInput="{ 'append-icon':'mdi-magnify'}"
    ></primaryTextfield> -->
    <div v-else>
      <v-hover>
        <template v-slot:default="{ hover }">
          <div>
            <v-fade-transition>
              <v-btn icon hover small v-if="!hover">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-fade-transition>
            <v-fade-transition>
              <v-overlay v-if="hover" absolute opacity="0.9">
                <v-text-field
                  class="float-right"
                  v-model="localSearch"
                  append-icon="mdi-magnify"
                  :label="label"
                  hide-details
                  outlined
                  dense
                  @click.stop
                  @focus="$emit('focus')"
                  :id="id"
                ></v-text-field>
              </v-overlay>
            </v-fade-transition>
          </div>
        </template>
      </v-hover>
    </div>
  </div>
</template>
<script>
// import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
export default {
  // components: { primaryTextfield },
  computed: {
    localSearch: {
      get: function () {
        //console.log(this.search);
        return this.value;
      },
      set: function (localSearch) {
        this.$emit("input", localSearch);
      },
    },
  },
  props: {
    value: {},
    label: {
      type: String,
      default: "Search",
    },
    minify: {
      type: Boolean,
      default: true,
    },
    id: { default: "tableSearchInput" },
  },
};
</script>