<template>
  <div class="x-accordion">
    <div class="x-accordion-header">
      <div class="x-accordion-header-left clickable" @click="handleOpenToggle">
        <v-icon color="text">mdi-drag-vertical</v-icon>
        {{ computedTitle }}
      </div>
      <div>
        <slot name="before-toggle"/>
        <v-icon color="text" :class="`toggle clickable ${computedOpen ? 'toggle-open' : ''}`" @click="handleOpenToggle">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>
    <div v-if="computedOpen" class="x-accordion-body">
      <slot name="body" :value="value" :input="handleInput"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XAccordion',
  props: {
    value: Object,
    title: [String, Function],
    titleKey: String,
    open: Boolean,
  },
  data() {
    return {
      dataOpen: undefined,
    };
  },
  computed: {
    computedTitle() {
      if (this.titleKey) return this.value[this.titleKey];
      if (this.title) {
        if (typeof this.title === 'function') return this.title(this.value);
        return this.title;
      }
      return 'New item';
    },
    computedOpen() {
      if (this.dataOpen !== undefined) return this.dataOpen;
      if (this.open !== undefined) return this.open;
      return false;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleOpenToggle() {
      let open = false;
      if (this.dataOpen === undefined) open = !this.open;
      else open = !this.dataOpen;
      this.dataOpen = open;
    },
  },
};
</script>

<style scoped>
.x-accordion {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  border-radius: 5px;
}

.x-accordion-header {
  display: flex;
  background-color: var(--v-primary-base);
  color: var(--v-text-base);
  border-radius: 5px 5px 0 0;
  padding: 10px 15px;
  gap: 5px;
  justify-content: space-between;
}

.x-accordion-header:last-child {
  border-radius: 5px;
}

.x-accordion-header-left {
  display: flex;
  gap: 5px;
  align-items: center;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.toggle {
  transition: .3s cubic-bezier(.25, .8, .5, 1), visibility 0s;
}

.toggle-open {
  transform: rotate(180deg);
}

.x-accordion-body {
  padding: 20px;
}
</style>