var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',_vm._b({},'v-col',_vm.colsBindings,false),[_c('fieldtypes',_vm._g({attrs:{"fieldAttributes":_vm.fieldAttributes,"field":_vm.field,"templateContent":_vm.templateContent,"checkReadOnly":_vm.checkReadOnly,"value":_vm.value,"valueLine":_vm.valueLine,"hideDetails":_vm.hideDetails}},_vm.$listeners))],1),(_vm.enableAdd !== false)?_c('v-col',{staticClass:"mt-2",staticStyle:{"max-width":"2%"},attrs:{"cols":"1"}},[(typeof _vm.fieldAttributes['addingURL'] != 'undefined')?_c('div',[(typeof _vm.fieldAttributes['addingFunction'] != 'undefined')?_c('dynamicComponent',{attrs:{"type":_vm.fieldAttributes['addingFunction'],"result":_vm.templateContent,"additional":{
          fieldAttributes: _vm.fieldAttributes,
          field: _vm.field,
          updatedDialogRef: this.templateContent.dialogRef,
          additionalAttributes: _vm.additionalAttributes,
          value: _vm.value,
          valueLine: _vm.valueLine,
          id: _vm.id + '_adding_button',
        },"hideDetails":_vm.hideDetails},on:{"updated-fields":_vm.emitUpdateFields}}):_c('addField',{attrs:{"path":_vm.fieldAttributes['addingURL'],"additionalAttributes":_vm.additionalAttributes,"dialogAttributes":_vm.fieldAttributes['addingDialogOptions']},on:{"updated-fields":_vm.emitUpdateFields}},[_c('v-icon',{staticClass:"ml-1",attrs:{"title":"Add","id":_vm.id + '_adding_button'}},[_vm._v("mdi-plus-box-outline")])],1)],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }