<template>
  <div v-if="typeof fieldAttributes.fieldAttrRow == 'undefined' || fieldAttributes['fieldAttrRow'] !== false">
    <lineFields
        v-if="inputType === 'line'"
        :fieldAttrRow="fieldAttrRow"
        :fields="fieldAttributes.fields"
        :templateContent="templateContent"
        v-on="$listeners"
        :valueLine="valueLine">
    </lineFields>
    <div v-else-if="predefined.indexOf(inputType) > -1">
      <fieldsTds
          :fieldAttrRow="fieldAttrRow"
          :fieldAttributes="fieldAttributes"
          :field="field"
          :templateContent="templateContent"
          v-on="$listeners"
          @input="checkvalue(field, ...arguments)"
          :value="valueLine[field]"
          :valueLine="valueLine">
      </fieldsTds>
    </div>
    <dynamicComponent
        v-else-if="!empty(inputType)"
        :type="inputType"
        :result="templateContent"
        :additional="{
          field: field,
          fieldAttributes: fieldAttributes,
          value: valueLine[field],
          valueLine: valueLine,
          isValid: isValid,
          fieldAttrRow: fieldAttrRow
        }"
        v-on="$listeners"
        @input="checkvalue(field, ...arguments)"
        @delete-input="deleteValue(field)">
    </dynamicComponent>
  </div>
</template>
<script>
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";

import fieldsTds from "@/commonComponents/fieldsTds.vue";
import lineFields from "@/commonComponents/lineFields.vue";

import {getfieldAttrRow} from "@/js/helper.js"; //Please use some authentic programming functional name!
export default {
  components: {dynamicComponent, fieldsTds, lineFields},
  data: function () {
    return {
      predefined: [
        "textarea",
        "text",
        "number",
        "file",
        "password",
        "select",
        "combobox",
        "autocomplete",
        "tagCombo",
        "hidden",
        "multipleText",
        "radio",
        "label",
        "selectMultipleList",
        "checkbox",
        "string",
        "switchGroup",
        "boolSwitch",
        "switch",
        "addable",
        "multipleSelect",
      ],
      additional: {field: this.field},
    };
  },
  computed: {
    inputType() {
      let inputType = "text";
      if (typeof this.fieldAttributes["type"] != "undefined") {
        inputType = this.fieldAttributes["type"];
      }

      return inputType;
    },
    fieldAttrRow: function () {
      return getfieldAttrRow(this.getFieldsAttributes, this.field);
    },
    fieldAttributes: function () {
      return this.getFieldsAttributes;
    },
    getFieldsAttributes: function () {
      let attrs = {};
      if (typeof this.templateContent.fields[this.field] != "undefined") {
        //console.log(this.templateContent.fields[this.field]);
        attrs = this.templateContent.fields[this.field];
      }
      return attrs;
    },
  },
  methods: {
    checkvalue(field, value, text) {
      //console.log("field single row",field, value, text);
      this.$emit("field-value-changed", field, value, text);
    },
    deleteValue(field) {
      //console.log("field single row",field, value, text);
      this.$emit("field-value-deleted", field);
    },
  },
  props: {
    //templateContent is the object which has fields as one of its properties
    // it is the result object in most cases
    templateContent: {},
    field: {},
    isValid: {},
    valueLine: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>