<template>
  <div class="explorers-status">
    <div>
      <v-menu min-width="245" offset-y open-on-hover top>
        <template v-slot:activator="{ on, attrs }">
          <div class="connection" v-bind="attrs" v-on="on">
            <div>
              <v-icon v-if="computedValue.online === 1" color="green">mdi-lan-connect</v-icon>
              <v-icon v-else-if="computedValue.online === 0" color="red">mdi-lan-disconnect</v-icon>
              <v-icon v-else-if="computedValue.online === 2" color="orange">mdi-lan-pending</v-icon>
            </div>
            <div>
              {{ formattedStatus[computedValue.current] }}
            </div>
          </div>
        </template>
        <v-list dense flat>
          <div class="subtitle-1 ml-2">
            {{ language.componentLabel.explorerStatus }}
          </div>
          <div class="caption ml-2">
            {{ language.componentLabel.explorerStatusUpdateTime }}:
            {{ dateToDateTimeString(new Date(computedValue.updateTime)) }}
          </div>
          <div class="caption ml-2">
            {{ language.componentLabel.explorerStatusEventTime }}: {{
              dateToDateTimeString(new Date(computedValue.eventTime))
            }}
          </div>
          <div class="caption ml-2">
            {{ language.componentLabel.explorerStatusPreviousStatus }}:
            {{ formattedStatus[computedValue.previous] }}
          </div>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ExplorerStatus',
  props: {
    explorerName: String,
    value: Object,
  },
  data: function () {
    return {
      dataValue: {},
      formattedStatus: {
        None: 'None',
        Installed: 'Installed',
        InStock: 'In Stock',
        Shipping: 'Shipping',
        InOperation: 'In Operation',
        Warning: 'Warning',
        Error: 'Error',
        Observation: 'Observation',
        ObservationDisrupted: 'Observation Disrupted',
        OperationDisrupted: 'Operation Disrupted',
        ActivationDelayed: 'ActivationDelayed',
        NotConnected: 'Not Connected',
        Return: 'Return',
        ReturnReceived: 'Return Received',
        ReturnDelayed: 'Return Delayed',
        Disposed: 'Disposed',
        Delete: 'Delete',
      },
    };
  },
  created() {
    if (!this.value) {
      this.services.explorerStatus.getStatus(this.explorerName, (status) => {
        this.dataValue = status;
      });
    }
  },
  computed: {
    computedValue() {
      if (this.value) return this.value;
      return this.dataValue;
    },
  },
};
</script>

<style scoped>
.explorers-status >>> .connection {
  display: flex;
  gap: 5px;
  align-items: center;
}
</style>