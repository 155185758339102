<template>
  <div>
    <v-row>
      <v-spacer> </v-spacer>
      <v-col>
        <associatedOption
          type="select"
          :fieldAttributes="{
            type: 'select',
            associatedOption: typeSelector,
            setEmpty: false,
            sort: false,
            name: 'Type',
            ignoreEmptyAdd: true,
          }"
          v-model="dataType"
          :hideDetails="true"
          ref="type"
        ></associatedOption>
      </v-col>
      <v-col>
        <associatedOption
          type="select"
          :fieldAttributes="{
            type: 'select',
            associatedOption: sourceSelector,

            sort: false,
            name: 'Source',
            setEmpty: false,
            ignoreEmptyAdd: true,
          }"
          v-model="dataSource"
          :hideDetails="true"
          ref="source"
        ></associatedOption>
      </v-col>
      <v-col>
        <searchInput
          v-model="localSearch"
          key="explorerLogSearch"
        ></searchInput>
      </v-col>

      <v-col cols="1" class="ml-3" style="min-width: 200px">
        <datePickerExtend
          v-model="info.data.timerange"
          @updateUnixDates="updateDates"
          :interval="refreshInterval"
          ref="datePicker"
        ></datePickerExtend>
      </v-col>
      <v-col id="dashboardRefreshInterval_container" style="max-width: 200px">
        <associatedOption
          type="select"
          :fieldAttributes="{
            type: 'select',
            associatedOption: [
              [0, 'off'],
              [10, '10s'],
              [30, '30s'],
              [60, '1m'],
              [120, '2m'],
              [300, '5m'],
              [600, '10m'],
            ],
            setEmpty: false,
            sort: false,
            name: 'Refresh',
            ignoreEmptyAdd: true,
          }"
          v-model="refreshInterval"
          :hideDetails="true"
        ></associatedOption>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredStatusLog"
      :loading="loading"
      sort-by="datetime"
      :sort-desc="true"
      :search="localSearch"
    >
      <template v-slot:[`item.datetime`]="{ item }">
        {{ formatTime(item.datetime) }}
      </template>
    </v-data-table>
    <v-row>
      <v-col>
        <primaryTextfield
          label="Message"
          v-model="message"
          :fieldAttrInput="{ disabled: messageLoading, id: 'manuelLogInput' }"
        ></primaryTextfield>
      </v-col>
      <v-col>
        <primaryButton
          label="Add Manual Entry"
          icon="mdi-send"
          @click="addStatusLog"
          type="button"
          color="primary"
          id="send_button"
          data-type="send_button"
          :loading="messageLoading"
        ></primaryButton>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import searchInput from "@/commonComponents/searchInput.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import datePickerExtend from "@/commonComponents/datePickerExtend.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: {
    primaryButton,
    primaryTextfield,
    datePickerExtend,
    associatedOption,
    searchInput,
  },
  props: {
    explorerName: String,
  },
  data() {
    return {
      dataType: "",
      dataSource: "",
      localSearch: "",
      interval: null,
      messageLoading: false,
      message: "",
      statusLog: [],
      filteredStatusLog: [],
      loading: true,
      headers: [
        {
          text: "Date",
          value: "datetime",
          width: 200,
        },
        { text: "Type", value: "type", width: 150 },
        { text: "Source", value: "source", width: 200 },
        { text: "Messsage", value: "msg" },
      ],
      manuallyChanged: false,
      pickerOptions: {
        period: "0.084",
      },
      normal: true,
      info: {
        data: {
          timerange: "Last 7 days",
          begin: "now-24h",
          end: "now",
        },
        meta: {
          refreshInterval: {
            name: "Refresh Interval",
            type: "select",
            possibleValues: [
              "off",
              "10 s",
              "30 s",
              "1 min",
              "2 min",
              "5 min",
              "10 min",
            ],
          },
        },
      },
      refreshInterval: "0",
      customPeriodDialog: false,
    };
  },
  created() {
    let now = Math.floor(Date.now() / 1000);
    this.$set(this.pickerOptions, "untilTime", now);
    let adayBefore = now - 86400;
    this.$set(this.pickerOptions, "fromTime", adayBefore);
    this.getStatusLog();
  },
  watch: {
    statusLog() {
      this.updateFilter();
    },
    dataType() {
      this.updateFilter();
    },
    dataSource() {
      this.updateFilter();
    },
  },
  beforeDestroy() {
    clearTimeout(this.interval);
  },
  computed: {
    typeSelector() {
      let typeSelector = [["", "All"]];
      this.statusLog.forEach((element) => {
        if (!typeSelector.includes(element.type)) {
          typeSelector.push(element.type);
        }
      });
      return typeSelector;
    },
    sourceSelector() {
      let typeSelector = [["", "All"]];
      this.statusLog.forEach((element) => {
        if (!typeSelector.includes(element.source)) {
          typeSelector.push(element.source);
        }
      });
      return typeSelector;
    },
  },
  methods: {
    formatTime(timeStamp) {
      var date = new Date(timeStamp);
      var hours = "0" + date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var dateString = date.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return (
        dateString +
        " " +
        hours.substr(-2) +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2)
      );
    },
    updateFilter() {
      // console.log("filter");
      //  this.filteredStatusLog = [...this.statusLog];
      this.filteredStatusLog = [];

      //  if (this.dataType != "") {
      this.statusLog.forEach((element) => {
        //console.log(element.type, this.dataType);
        if (
          (element.type == this.dataType || this.dataType == "") &&
          (element.source == this.dataSource || this.dataSource == "")
        ) {
          // this.$delete(this.filteredStatusLog, index);
          this.filteredStatusLog.push(element);
        }
      });
      //  }
    },

    updateDates(from, to) {
      // console.log("----------------->", from, to);
      // this.$set(this.info.data, "begin", from);
      // this.$set(this.info.data, "end", to);
      this.customPeriodDialog = true;

      this.$nextTick(() => {
        this.customPeriodDialog = false;
      });
      // //Need to think about it!
      // if (this.period != "custom" && this.interval != 0) {
      //   let now = Math.floor(Date.now() / 1000);
      this.$set(this.pickerOptions, "untilTime", to);
      // let fromTime = now - this.period * 86400;
      // console.log(this.period)
      this.$set(this.pickerOptions, "fromTime", from);
      // this.$emit("options-changed", this.pickerOptions);
      // }
      this.getStatusLog();
    },
    getStatusLog() {
      let caller = this;
      caller.loading = true;
      clearTimeout(this.interval);
      this.axios
        .post("serve.php?f=administration&f2=explorers", {
          function: "getStatusLog",
          name: this.explorerName,
          from: this.pickerOptions.fromTime,
          to: this.pickerOptions.untilTime,
        })
        .then(function (response) {
          if (response.data.result.statusLog != null) {
            caller.statusLog = response.data.result.statusLog;
          }
          caller.loading = false;
          caller.interval = setTimeout(function () {
            caller.getStatusLog();
          }, 60000);
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    addStatusLog() {
      let caller = this;
      this.messageLoading = true;
      let to = parseInt(Date.now() / 1000);
      this.$set(this.pickerOptions, "untilTime", to);
      this.axios
        .post("serve.php?f=administration&f2=explorers", {
          function: "addStatusLog",
          name: this.explorerName,
          message: this.message,
          from: this.pickerOptions.fromTime,
          to: to,
        })
        .then(function (response) {
          if (response.data.result.statusLog != null) {
            caller.statusLog = response.data.result.statusLog;
          }
        })
        .catch(function (response) {
          console.log(response);
        })
        .finally(() => {
          caller.message = "";
          caller.messageLoading = false;
        });
    },
  },
};
</script>