<template>
  <div
      ref="xDataTable"
      class="x-data-table"
      :style="height ? `height: ${typeof height === 'number' ? `${height}px` : height};` : 'height: 100%;'">
    <div
        v-if="crudRequests && crudRequests.create || tableName || range && !customRange || search
         || $slots['before-table-name'] || $slots['after-table-name']
         || $slots['before-search'] || $slots['after-search'] || $slots['below-top']"
        ref="top"
        :class="`top ${!noElevation ? 'elevated' : ''}`">
      <div
          v-if="crudRequests && crudRequests.create || tableName || range && !customRange || search || historyItem"
          class="top-main">
        <div v-if="crudRequests && crudRequests.create || tableName || historyItem" class="top-left">
          <div v-if="crudRequests && crudRequests.create || historyItem">
            <XBtn v-if="!historyItem" icon="mdi-plus" text="New" color="primary" @click="openDialog(item, false)"/>
            <XBtn v-else icon="mdi-step-backward" text="Back" color="primary" @click="closeHistory"/>
          </div>
          <template v-if="!historyItem">
            <slot name="after-new"/>
            <div v-if="csvHeaders" class="csv">
              <XBtn text="Export as CSV" color="primary" @click="loadData(true)"/>
            </div>
            <slot name="before-table-name"/>
          </template>
          <div v-if="tableName" class="table-name">
            <span v-if="!historyItem">{{ tableName }}</span>
            <span v-else>History of {{ historyItem.name }}</span>
            <span v-if="showTotal">({{ count }})</span>
          </div>
          <template v-if="!historyItem">
            <slot name="after-table-name"/>
          </template>
        </div>
        <DateTimeRange
            v-if="range && !customRange" v-model="settings.rangeValue" :default-range="0.041667" @input="saveRange"/>
        <div class="search-area">
          <template v-if="!historyItem">
            <slot name="before-search"/>
          </template>
          <XTextField
              v-if="search"
              v-model="settings.searchText"
              @input="handleSearchInput"
              delay
              label="Search"
              append-icon="mdi-magnify"
              class="search-text-field"/>
          <template v-if="!historyItem">
            <slot name="after-search"/>
          </template>
        </div>
      </div>
      <div>
        <slot name="below-top"/>
      </div>
    </div>
    <div
        :class="`table-wrapper ${!noElevation ? 'elevated' : ''}`"
        :style="`min-height: ${tableHeight}px; max-height: ${tableHeight}px;`">
      <div ref="basicInformation">
        <HeadlineBox
            v-if="historyItem" headline="Basic Information" content-padding="10" no-border>
          <div class="basic-information">
            <div>
              <div>
                Last Name:
              </div>
              <div>
                {{ historyItem.name }}
              </div>
            </div>
            <div>
              <div>
                Created At:
              </div>
              <div>
                {{ unixToDateTimeString(historyItem.history.createDate) }}
              </div>
            </div>
          </div>
        </HeadlineBox>
      </div>
      <div ref="table" class="table">
        <div ref="aboveTable" class="above-table" v-show="$slots['above-table'] && !historyItem">
          <slot name="above-table"/>
        </div>
        <div v-if="historyItem" ref="historyInformation" class="history-information">
          <div :style="`width: ${getColumnWidthSum(0, 4)}px;`">Journal Information</div>
          <div :style="`width: ${getColumnWidthSum(5)}px;`">Object Information</div>
        </div>
        <div ref="thead" class="thead">
          <div class="tr">
            <div
                v-for="(header, i) of computedHeaders"
                :key="i"
                :style="`min-width: ${columnWidths[i]}; max-width: ${columnWidths[i]};`"
                class="th">
              <span v-if="!header.sortable" class="th-content">{{ header.text }}</span>
              <div
                  v-else
                  :class="`th-content sortable ${header.value === settings.sortBy ? 'active' : ''}`"
                  @click="handleHeaderClick(header)">
                {{ header.text }}
                <v-icon
                    small
                    :class="`table-header-sort-arrow ${header.value === settings.sortBy ? 'active' : ''} ${settings.descending ? 'descending' : ''}`">
                  mdi-arrow-up
                </v-icon>
              </div>
            </div>
          </div>
        </div>
        <div :style="`height: ${tbodyHeight}px`" class="tbody">
          <div v-if="!computedLoading">
            <div v-if="dataItems.length">
              <div
                  v-for="(item, i) of dataItems"
                  :key="i"
                  class="tr"
                  :style="rowStyle ? (typeof rowStyle === 'function' ? rowStyle(item) : rowStyle) : ''">
                <div
                    v-for="(header, j) of computedHeaders"
                    :key="j"
                    :style="`min-width: ${columnWidths[j]}; max-width: ${columnWidths[j]};`"
                    class="td">
                  <XCheckbox
                      v-if="header.value === 'select'"
                      v-model="selectedItems[item.id]"
                      dense
                      class="x-data-table-select-checkbox"/>
                  <div v-if="header.value === 'rowActions'" class="row-actions">
                    <XBtn v-for="(rowAction, k) of rowActions" :key="k">

                    </XBtn>
                    <v-menu v-if="computedAdditionalRowActions.length" offset-y>
                      <template #activator="{ on, attrs }">
                        <XBtn icon="mdi-dots-horizontal" v-bind="attrs" v-on="on"/>
                      </template>
                      <v-list>
                        <v-list-item
                            v-for="(additionalRowAction, l) in computedAdditionalRowActions"
                            :key="l"
                            class="additional-row-action"
                            :style="getAdditionalRowActionStyle(l)"
                            @mouseenter="additionalRowActionHover = l"
                            @mouseleave="additionalRowActionHover = -1"
                            @click="additionalRowAction.click(item, i, loadData)">
                          <v-icon :color="getAdditionalRowActionTextColor(l)">
                            {{
                              typeof additionalRowAction.icon === 'function' ? additionalRowAction.icon(item, i) :
                                  additionalRowAction.icon
                            }}
                          </v-icon>
                          <div
                              :style="getAdditionalRowActionTextColor(l) ?
                                  `color: var(--v-${getAdditionalRowActionTextColor(l)}-base);` : ''">
                            {{
                              typeof additionalRowAction.text === 'function' ? additionalRowAction.text(item, i) :
                                  additionalRowAction.text
                            }}
                          </div>
                          <v-tooltip v-if="additionalRowAction.text === 'History'" right>
                            <template #activator="{on, attrs}">
                              <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                            </template>
                            <div class="version-information">Version Information</div>
                            <div
                                v-for="(versionInformationKey, m) of getFilteredVersionInformationKeys(item.history)"
                                :key="m"
                                class="version-information-row">
                              <div class="version-information-key">{{ versionInformationKey.text }}:</div>
                              <div>{{
                                  versionInformationKey.formatter ? versionInformationKey.formatter(
                                          item.history[versionInformationKey.value]) :
                                      item.history[versionInformationKey.value]
                                }}
                              </div>
                            </div>
                          </v-tooltip>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <slot
                      v-else-if="$scopedSlots[`item.${header.value}`]"
                      :name="`item.${header.value}`"
                      :value="getItemValue(item, header)"
                      :row="item"
                      :row-index="i"
                      :handle-name-click="handleNameClick"/>
                  <ClickableText
                      v-else-if="header.value === 'name'"
                      :text="header.formatter ? header.formatter(getItemValue(item, header), item) : getItemValue(item, header)"
                      @click="handleNameClick(item, i)"/>
                  <ClickableText
                      v-else-if="header.value === 'history.version' && !!item.history.action"
                      :text="header.formatter ? header.formatter(getItemValue(item, header), item) : getItemValue(item, header)"
                      @click="handleVersionClick(item, i)"/>
                  <a
                      v-else-if="header.href"
                      :href="typeof header.href === 'function' ? header.href(getItemValue(item, header), item) : header.href">
                    {{
                      header.formatter ? header.formatter(getItemValue(item, header), item) :
                          getItemValue(item, header)
                    }}
                  </a>
                  <span v-else-if="header.formatter">{{ header.formatter(getItemValue(item, header), item) }}</span>
                  <span v-else>{{ getItemValue(item, header) }}</span>
                </div>
              </div>
            </div>
            <div v-else class="no-records">No records found.</div>
          </div>
          <div v-else class="x-data-table-progress-circular">
            <v-progress-circular indeterminate color="primary" size="100" width="10"/>
          </div>
        </div>
      </div>
      <div ref="footer" class="footer">
        <div v-if="selectActions" ref="selectActions" class="x-data-table-select-actions-container">
          <div class="x-data-table-select-actions">
            <XCheckbox label="Select All" dense @input="handleSelectAll"/>
            <ClickableText
                v-for="(selectAction, i) of selectActions"
                :value="selectAction"
                :key="i"
                @click="handleSelectActionClick(selectAction)"/>
          </div>
        </div>
        <div v-if="!noPageControls" class="page-controls">
          <XBtn icon="mdi-chevron-left" :disabled="settings.page === 1" @click="settings.page--"/>
          <div ref="pageButtons" v-if="pagesShown.length" class="page-buttons">
            <div v-for="(pageValue, i) of pagesShown" :key="i" class="button-container">
              <x-btn
                  v-if="typeof pageValue === 'number'"
                  :text="pageValue"
                  class="page-button"
                  :color="pageValue === settings.page ? 'primary' : ''"
                  @click="settings.page = pageValue"/>
              <div v-else class="page-ellipsis">{{ pageValue }}</div>
            </div>
          </div>
          <XSelect
              v-else v-model="settings.page" label="Page" :items="getSimplePagesShown()" class="page-select" required/>
          <XBtn icon="mdi-chevron-right" :disabled="settings.page === pages" @click="settings.page++"/>
          <XSelect
              v-model="settings.itemsPerPage"
              :items="[10, 25, 50, 100]"
              label="Items per page"
              class="items-per-page"
              required
              :autocomplete="false"/>
        </div>
      </div>
    </div>
    <slot
        v-if="dialog"
        name="dialog"
        :value="dialog"
        :item="item"
        :close="closeDialog"
        :save="handleIndependentDialogSave"/>
    <FormDialog
        v-if="dialog && !$scopedSlots['dialog']"
        v-model="dialog"
        :item="item"
        :title="dialogTitle"
        :width="dialogWidth"
        @save="handleSave"
        :unpadded="unpaddedDialog">
      <template #dialog-content="{valid}">
        <slot name="dialog-form" :item="item" :valid="valid"/>
      </template>
    </FormDialog>
    <LoadingDialog :value="dialogLoading"/>
    <ReallyDeleteDialog v-model="reallyDeleteDialog" :item-name="this.itemToDelete.name" @yes="deleteItem"/>
  </div>
</template>

<script>
import XBtn from '@/components/basic/XBtn';
import XSelect from '@/components/basic/XSelect';
import XTextField from '@/components/basic/XTextField';
import XCheckbox from '@/components/basic/XCheckbox';
import ClickableText from '@/components/basic/ClickableText';
import DateTimeRange from '@/components/basic/DateTimeRange';
import FormDialog from '@/components/extended/FormDialog';
import LoadingDialog from '@/components/basic/LoadingDialog';
import ReallyDeleteDialog from '@/components/extended/ReallyDeleteDialog';
import HeadlineBox from '@/components/basic/HeadlineBox';

export default {
  name: 'XDataTable',
  components: {
    HeadlineBox,
    ReallyDeleteDialog,
    LoadingDialog,
    FormDialog,
    DateTimeRange,
    ClickableText,
    XCheckbox,
    XTextField,
    XSelect,
    XBtn,
  },
  props: {
    headers: Array,
    value: Array,
    item: Object,
    itemsUrl: String,
    itemsRequest: Function,
    itemsRequestParams: Array,
    crudRequests: Object,
    customRange: Object,
    refresh: Number,
    height: [Number, String],
    range: Boolean,
    search: Boolean,
    searchText: String,
    selectActions: Array,
    localStorageKey: String,
    tableName: String,
    showTotal: Boolean,
    itemName: String,
    dialogWidth: [String, Number],
    unpaddedDialog: Boolean,
    rowActions: {
      type: Array,
      default: () => [],
    },
    rowStyle: [String, Function],
    additionalRowActions: {
      type: Array,
      default: () => [],
    },
    noPageControls: Boolean,
    noElevation: Boolean,
    itemsPerPage: Number,
    csvHeaders: Array,
    loading: Boolean,
    history: Boolean,
  },
  data() {
    return {
      pages: 1,
      dataItems: [],
      count: 0,
      resizeObserver: null,
      pageButtonsResizeObserver: null,
      tableHeight: 0,
      tbodyHeight: 0,
      columnWidths: [],
      tick: null,
      elapsed: 0,
      dataLoading: false,
      lastSearch: new Date(),
      skippedFirst: false,
      selectedItems: {},
      pagesShown: [],
      settings: {
        rangeValue: this.customRange ? this.customRange : {},
        searchText: '',
        sortBy: '',
        descending: false,
        page: 1,
        itemsPerPage: 25,
      },
      skipNextSearch: false,
      rangeInitialized: false,
      dialog: false,
      dialogTitle: '',
      openingDialog: true,
      dialogLoading: false,
      itemToDelete: {},
      reallyDeleteDialog: false,
      historyItem: null,
      lastResize: new Date(),
      additionalRowActionHover: -1,
      itemHistory: null,
      versionInformationKeys: [
        {
          text: 'Version',
          value: 'version',
        },
        {
          text: 'Created by',
          value: 'createdBy',
        },
        {
          text: 'Created date',
          value: 'createDate',
          formatter: this.unixToDateTimeString,
        },
        {
          text: 'Modified by',
          value: 'modifiedBy',
        },
        {
          text: 'Modify date',
          value: 'modifyDate',
          formatter: this.unixToDateTimeString,
        },
        {
          text: 'Comment',
          value: 'comment',
        },
      ],
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.$refs.table);
    if (!this.noPageControls) {
      this.pageButtonsResizeObserver = new ResizeObserver(this.handlePageButtonsResize);
      this.pageButtonsResizeObserver.observe(this.$refs.pageButtons);
    }
    this.tick = setInterval(() => {
      if (!this.refresh || this.computedLoading) return;
      this.elapsed++;
      if (this.elapsed >= this.refresh) {
        this.elapsed = 0;
        this.$emit('refresh');
        this.loadData();
      }
    }, 1000);
    this.loadSettings();
    this.$nextTick(() => {
      this.recalculateTableHeight();
      this.recalculateColumnWidths();
    });
  },
  beforeDestroy() {
    clearInterval(this.tick);
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && this.dataItems !== value) this.dataItems = this.deepCopy(value);
      },
    },
    searchText: {
      immediate: true,
      handler(value) {
        if (value !== undefined) {
          this.settings.searchText = value;
        }
      },
    },
    count: {
      immediate: true,
      handler() {
        this.updatePages();
      },
    },
    'settings.itemsPerPage'() {
      this.updatePages();
      this.saveSettings();
      this.loadData();
    },
    customRange: {
      deep: true,
      handler(value) {
        this.settings.range = value;
        this.loadData();
      },
    },
    'settings.page'() {
      this.saveSettings();
      this.loadData();
    },
    itemsRequestParams: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  computed: {
    computedHeaders() {
      const computedHeaders = [];
      if (this.selectActions) {
        computedHeaders.push(
            {
              value: 'select',
              width: 68,
            });
      }
      if (this.rowActions.length || this.computedAdditionalRowActions.length) {
        const width = 32 + 36 * this.rowActions.length + 36 * (this.computedAdditionalRowActions.length ? 1 : 0);
        computedHeaders.push(
            {
              value: 'rowActions',
              width: width,
            });
      }
      if (this.historyItem) {
        const historyHeaders = [
          {
            text: 'Version',
            value: 'history.version',
            formatter: (value) => value ? value : this.dataItems.length > 1 ? this.dataItems[1].history.version + 1 : 1,
          },
          {
            text: 'Action',
            value: 'history.action',
            formatter: (value) => {
              if (!value) {
                return 'current';
              } else if (value === 'mod') {
                return 'modified';
              }
              return '';
            },
          },
          {
            text: 'Modified By',
            value: 'history.modifiedBy',
          },
          {
            text: 'Modify Date',
            value: 'history.modifyDate',
            formatter: value => this.unixToDateTimeString(value),
          },
          {
            text: 'Comment',
            value: 'history.comment',
          },
        ];
        computedHeaders.push(...historyHeaders);
      }
      computedHeaders.push(...this.headers);
      return computedHeaders;
    },
    computedAdditionalRowActions() {
      const computedAdditionalRowActions = [];
      if (this.historyItem) return computedAdditionalRowActions;
      computedAdditionalRowActions.push(...this.additionalRowActions);
      if (this.history) {
        computedAdditionalRowActions.push({
          text: 'History',
          icon: 'mdi-history',
          click: this.loadHistory,
        });
      }
      if (this.crudRequests && this.crudRequests.delete) {
        computedAdditionalRowActions.push({
          text: 'Delete',
          icon: 'mdi-delete',
          click: this.openDeleteDialog,
          hoverColors: {
            text: 'white',
            background: 'delete',
          },
        });
      }
      return computedAdditionalRowActions;
    },
    computedSelectedItems() {
      return Object.entries(this.selectedItems).filter(x => x[1]).map(x => x[0]);
    },
    computedLoading() {
      return this.loading || this.dataLoading;
    },
  },
  methods: {
    loadData(csv = false) {
      if (!this.itemsUrl && !this.itemsRequest) return;

      if (!csv) this.dataLoading = true;
      this.$emit('loading', true);

      let from = 0;
      let to = Math.trunc(new Date().getTime() / 1000);

      const rangeValue = this.customRange ? this.customRange : this.settings.rangeValue;

      if (rangeValue) {
        if (rangeValue.seconds) {
          from = Math.trunc(new Date().getTime() / 1000) - rangeValue.seconds;
          to = Math.trunc(new Date().getTime() / 1000);
        } else {
          if (rangeValue.from) {
            from = Math.trunc(this.settings.rangeValue.from.getTime() / 1000);
          }
          if (rangeValue.to) {
            to = Math.trunc(this.settings.rangeValue.to.getTime() / 1000);
          }
        }
      }

      csv = typeof csv === 'boolean' ? csv : false;

      const params = {
        page: this.settings.page,
        'items-per-page': !this.itemsPerPage ? this.settings.itemsPerPage : this.itemsPerPage,
        from: from,
        to: to,
        search: this.settings.searchText,
        sortBy: this.settings.sortBy,
        descending: this.settings.descending,
        historyFor: this.historyItem ? this.historyItem.id : 0,
        full: csv,
      };

      const countThen = (count) => {
        this.count = count;
        this.recalculatePageButtons();
      };

      let itemsRequestParams = this.itemsRequestParams ? this.itemsRequestParams : [];

      if (this.itemsUrl) {
        this.getRequest(this.itemsUrl, {
          ...params,
          count: true,
        }, countThen);
      } else if (this.itemsRequest) {
        this.itemsRequest(...itemsRequestParams, {
          ...params,
          count: true,
        }, countThen);
      }

      const then = (result) => {
        if (!csv) this.dataLoading = false;
        this.$emit('loading', false);
        if (csv) {
          const formattedDate = this.dateToIsoDateString(new Date());
          this.downloadStringAsFile(`${this.tableName ? `${this.tableName} ` : ''}Export ${formattedDate}.csv`,
              this.convertToCsv(result));
        } else if (!this.historyItem) {
          this.dataItems = result;
          this.$emit('input', this.dataItems);
        } else {
          if (this.settings.page === 1) {
            const items = [this.historyItem, ...result];
            if (items.length > this.settings.itemsPerPage) items.splice(items.length - 1, 1);
            this.dataItems = items;
          } else {
            this.dataItems = result;
          }
        }
        this.elapsed = 0;
      };

      const errorHandler = (error) => {
        this.dataLoading = false;
        this.$emit('loading', false);
        this.$emit('reload-error', error);
      };

      if (this.itemsUrl) {
        this.getRequest(this.itemsUrl, {
          ...params,
          count: false,
        }, then, errorHandler);
      } else if (this.itemsRequest) {
        this.itemsRequest(...itemsRequestParams, {
          ...params,
          count: false,
        }, then, errorHandler);
      }
    },
    updatePages() {
      let pages = this.count / this.settings.itemsPerPage;
      if (pages % 1 > 0) pages++;
      pages = Math.trunc(pages);
      if (pages === 0) pages = 1;
      this.pages = pages;
      if (this.settings.page > this.pages) this.settings.page = this.pages;
      this.pagesShown = this.getSimplePagesShown();
    },
    handleResize() {
      if (new Date().getTime() - this.lastResize.getTime() < 16) return;
      this.$nextTick(() => {
        this.recalculateColumnWidths();
        this.$nextTick(() => {
          this.recalculateTableHeight();
          this.recalculatePageButtons();
          this.lastResize = new Date();
        });
      });
    },
    handlePageButtonsResize() {
      this.$nextTick(() => {
        this.recalculatePageButtons();
      });
    },
    recalculateTableHeight() {
      const xDataTable = this.$refs.xDataTable;
      if (!xDataTable) return;
      const xDataTableHeight = typeof this.height === 'string' && !this.height.includes('vh') ? this.getInt(this.height,
          xDataTable.clientHeight) : xDataTable.clientHeight;
      const topHeight = this.$refs.top ? this.$refs.top.clientHeight : 0;
      const footerHeight = this.$refs.footer.clientHeight;
      const tableHeight = xDataTableHeight - topHeight - 8;
      const basicInformationHeight = this.$refs.basicInformation.clientHeight;
      const aboveTableHeight = this.$refs.aboveTable.clientHeight;
      const historyInformation = this.$refs.historyInformation;
      const historyInformationHeight = historyInformation ? historyInformation.clientHeight : 0;
      const theadHeight = this.$refs.thead.clientHeight;
      const tbodyHeight = tableHeight - basicInformationHeight - aboveTableHeight - historyInformationHeight -
          theadHeight - footerHeight;
      this.tableHeight = tableHeight;
      this.tbodyHeight = tbodyHeight;
    },
    recalculateColumnWidths() {
      const table = this.$refs.table;
      if (!table) {
        if (!this.columnWidths.length) {
          const columnWidths = Array(this.computedHeaders.length);
          columnWidths.fill(0);
          this.columnWidths = columnWidths;
        }
        return;
      }
      const tableWidth = table.clientWidth - 15;
      const pixelWidths = [];
      let totalFixedPixelWidth = 0;
      for (const header of this.computedHeaders) {
        let pixelWidth = {
          width: parseInt(tableWidth / this.computedHeaders.length),
          fixed: false,
        };
        if (header.width !== undefined) {
          if (typeof header.width === 'number' || header.width.includes('px')) {
            pixelWidth.width = parseInt(header.width);
            pixelWidth.fixed = true;
            totalFixedPixelWidth += pixelWidth.width;
          } else if (header.width.includes('%')) {
            pixelWidth.width = parseFloat(header.width.substring(0, header.width.length - 1)) / 100;
            pixelWidth.fixed = false;
          }
        }
        pixelWidths.push(pixelWidth);
      }

      const columnWidths = [];
      const pixelsLeft = tableWidth - totalFixedPixelWidth;
      for (const pixelWidth of pixelWidths) {
        let realWidth = pixelWidth.width;
        if (!pixelWidth.fixed) realWidth = parseInt(pixelsLeft / pixelWidths.filter(x => !x.fixed).length);
        columnWidths.push(`${realWidth}px`);
      }

      this.columnWidths = columnWidths;
    },
    recalculatePageButtons() {
      if (!this.$refs.xDataTable) return;
      const selectActionsWidth = this.selectActions ? this.$refs.selectActions.clientWidth : 0;
      const pageLeftRightWidth = 36 * 2;
      const itemsPerPageWidth = 104;
      const paddingWidth = 20;
      const footer = this.$refs.footer;
      const footerWidth = footer ? footer.clientWidth : 0;
      let width = footerWidth - selectActionsWidth - pageLeftRightWidth - itemsPerPageWidth - paddingWidth;
      if (width > 430) width = 430;
      if (this.pages * 44 - 10 > width) {
        const blocksAllowed = parseInt((width + 10) / 44);
        const pagesShown = [];
        if (blocksAllowed >= 9) {
          const ellipsis = '⋯';
          pagesShown.push(1, 2);
          const halfCenter = Math.trunc((blocksAllowed - 6) / 2);
          if (this.settings.page < halfCenter + 4 || this.settings.page > this.pages - halfCenter - 2) {
            for (let i = 3; i < Math.trunc(blocksAllowed / 2) + 1; i++) {
              pagesShown.push(i);
            }
            pagesShown.push(ellipsis);
            for (let i = this.pages - Math.trunc(blocksAllowed / 2) + 2; i < this.pages - 1; i++) {
              pagesShown.push(i);
            }
          } else {
            pagesShown.push(ellipsis);
            for (let i = this.settings.page - halfCenter + 1; i < this.settings.page + halfCenter + 1; i++) {
              pagesShown.push(i);
            }
            pagesShown.push(ellipsis);
          }
          pagesShown.push(this.pages - 1, this.pages);
          this.pagesShown = pagesShown;
        } else {
          this.pagesShown = [];
        }
      } else {
        this.pagesShown = this.getSimplePagesShown();
      }
    },
    getSimplePagesShown() {
      const pagesShown = [];
      for (let i = 1; i <= this.pages; i++) {
        pagesShown.push(i);
      }
      return pagesShown;
    },
    handleHeaderClick(header) {
      if (this.settings.sortBy === header.value) {
        if (!this.settings.descending) {
          this.settings.descending = true;
        } else {
          this.settings.sortBy = '';
          this.settings.descending = false;
        }
      } else {
        this.settings.sortBy = header.value;
        this.settings.descending = false;
      }
      this.saveSettings();
      this.loadData();
    },
    handleSelectAll(value) {
      const selectedItems = {};
      for (const item of this.dataItems) {
        selectedItems[item.id] = value;
      }
      this.selectedItems = selectedItems;
    },
    handleSelectActionClick(selectAction) {
      if (selectAction.click) selectAction.click(this.computedSelectedItems);
    },
    loadSettings() {
      if (!this.localStorageKey || !this.parseBoolean(localStorage.getItem('back'))) {
        this.loadData();
        return;
      }
      localStorage.setItem('back', 'false');
      const settings = JSON.parse(localStorage.getItem(this.localStorageKey));
      if (!settings) return;
      for (const key of Object.keys(this.settings)) {
        if (settings[key]) {
          if (key !== 'rangeValue') {
            this.settings[key] = settings[key];
          } else {
            this.settings[key] = {
              from: new Date(settings.rangeValue.from),
              to: new Date(settings.rangeValue.to),
              seconds: settings.rangeValue.seconds,
            };
          }
        }
      }
      this.loadData();
    },
    saveSettings() {
      if (!this.localStorageKey || this.parseBoolean(localStorage.getItem('back'))) return;
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.settings));
    },
    saveRange() {
      if (this.parseBoolean(localStorage.getItem('back'))) return;
      this.saveSettings();
      this.$emit('range', this.deepCopy(this.settings.rangeValue));
      if (this.rangeInitialized) this.loadData();
      this.rangeInitialized = true;
    },
    openDialog(item, edit) {
      if (!edit) {
        this.dialogTitle = `New ${this.itemName}`;
        this.setDefaultValues(item);
        this.$emit('update-item', item);
        this.dialog = true;
      } else {
        this.dialogTitle = `Edit ${item.name}`;
        this.dialogLoading = true;
        let version = 0;
        if (item.history && !!item.history.action) version = item.history.version;
        this.crudRequests.get.request(item.id, version, (newItem) => {
          newItem.id = item.id;
          if (item.history) newItem.history = item.history;
          this.$emit('update-item', newItem);
          this.dialog = true;
          this.dialogLoading = false;
        });
      }
      this.$emit('dialog', true);
    },
    handleNameClick(item, i) {
      this.openDialog(item, true);
      this.$emit('name-click', item, i);
    },
    handleVersionClick(item, i) {
      this.openDialog(item, true);
      this.$emit('version-click', item, i);
    },
    handleSave() {
      this.dialogLoading = true;
      if (!this.item.id) {
        this.crudRequests.create.request(this.item, () => {
          this.loadData();
          this.dialogLoading = false;
        });
      } else {
        this.crudRequests.update.request(this.item, () => {
          this.loadData();
          this.dialogLoading = false;
        });
      }
    },
    handleIndependentDialogSave(value) {
      if (this.historyItem) {
        this.dataItems[1].history.version = 0;
        this.dataItems[1].history.comment = value.history.comment;
        this.historyItem = this.dataItems[1];
      }
      this.loadData();
    },
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.reallyDeleteDialog = true;
    },
    deleteItem() {
      this.crudRequests.delete.request(this.itemToDelete.id, () => {
        this.loadData();
      });
    },
    getItemValue(item, header) {
      if (!header.value) {
        console.error('Table headers must have a "value" attribute.');
        return 'ERROR';
      }
      if (!header.value.includes('.')) {
        return item[header.value];
      } else {
        const parts = header.value.split('.');
        return item[parts[0]][parts[1]];
      }
    },
    convertToCsv(rows) {
      let csv = '';
      for (const header of this.csvHeaders) {
        csv += `${header.text};`;
      }
      csv += '\n';
      for (const row of rows) {
        for (const header of this.csvHeaders) {
          let value = header.formatter !== undefined ? header.formatter(row[header.value]) : row[header.value];
          if (value === undefined || value === null) value = '';
          csv += `${value.replace('\r', '').replace('\n', '')};`;
        }
        csv += '\n';
      }
      return csv;
    },
    handleSearchInput(value) {
      this.$emit('search', value);
      this.loadData();
    },
    getAdditionalRowActionStyle(index) {
      let style = '';
      if (index === this.additionalRowActionHover) {
        const hoverColors = this.computedAdditionalRowActions[index].hoverColors;
        if (hoverColors) {
          if (hoverColors.text) style += `color: var(--v-${hoverColors.text}-base);`;
          if (hoverColors.background) style += `background-color: var(--v-${hoverColors.background}-base);`;
        }
      }
      return style;
    },
    getAdditionalRowActionTextColor(index) {
      if (index === this.additionalRowActionHover) {
        const hoverColors = this.computedAdditionalRowActions[index].hoverColors;
        if (hoverColors && hoverColors.text) return hoverColors.text;
      }
      return '';
    },
    closeDialog() {
      this.dialog = false;
    },
    loadHistory(item) {
      this.historyItem = item;
      this.$nextTick(() => {
        this.recalculateColumnWidths();
        this.recalculateTableHeight();
      });
      this.loadData();
    },
    closeHistory() {
      this.historyItem = null;
      this.$nextTick(() => {
        this.recalculateColumnWidths();
        this.recalculateTableHeight();
      });
      this.loadData();
    },
    getColumnWidthSum(start, end) {
      let sum = 0;
      if (!end) end = this.columnWidths.length - 1;
      for (let i = start; i <= end; i++) {
        sum += parseInt(this.columnWidths[i]);
      }
      return sum;
    },
    getFilteredVersionInformationKeys(history) {
      return this.versionInformationKeys.filter(x => !!history[x.value]);
    },
  },
};
</script>

<style scoped>
.x-data-table {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.table-name {
  font-size: 20px;
  font-weight: 500;
}

.top {
  display: flex;
  flex-direction: column;
}

.top.elevated {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.top-main {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  gap: 10px;
}

.top-left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.csv {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-area {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 50%;
  flex-grow: 1;
  justify-content: flex-end;
}

.search-text-field {
  flex: 1 1 auto;
  max-width: 350px;
}

.table-wrapper {
  border-radius: 4px;
}

.table-wrapper.elevated {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.basic-information {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  padding: 0 6px;
}

.basic-information > * {
  display: flex;
}

.basic-information > * > *:first-child {
  width: 90px;
}

.above-table {
  padding: 10px;
}

.table {
  flex: 1 0 auto;
  min-height: 150px;
}

.tr {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 32px;
}

.tr.deactivated {
  background-color: var(--v-rowDeactivated-base);
}

.tr.clickable {
  cursor: pointer;
}

.history-information {
  background-color: var(--v-primary-base);
  display: flex;
  color: white;
  padding: 6px 16px;
  font-size: 14px;
  gap: 16px;
}

.tbody {
  border-top: 1px solid var(--v-tableBorder-base);
  overflow-y: auto;
}

.tbody .tr {
  border-bottom: 1px solid var(--v-tableBorder-base);
}

.tbody .tr:hover {
  background-color: var(--v-tableRowHover-base);
}

th {
  text-align: left;
}

.th-content {
  color: var(--v-tableHeader-base);
  font-weight: 700;
  user-select: none;
}

.th-content.sortable:hover,
.th-content.active {
  color: var(--v-tableHeaderActive-base);
}

.th-content.sortable {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
}

.table-header-sort-arrow {
  visibility: hidden;
}

.th-content:hover .table-header-sort-arrow:not(.active) {
  visibility: visible;
  color: var(--v-tableHeaderSortArrow-base);
}

.table-header-sort-arrow.active {
  visibility: visible;
  color: var(--v-tableHeaderActive-base);
}

.active.descending {
  rotate: -180deg;
}

.th, .td {
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
}

.td {
  font-size: 14px;
}

.footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.page-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px;
}

.page-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  height: 40px;
  overflow: hidden;
  padding-right: 3px;
  padding-bottom: 2px;
}

.page-button >>> button.v-btn:not(.v-btn--round).v-size--default {
  min-width: 34px;
  padding: 0 0;
}

.items-per-page {
  width: 104px;
  flex-shrink: 0;
}

.x-data-table-progress-circular {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.x-data-table-select-checkbox >>> .v-input--selection-controls__input {
  margin-right: 0;
}

.x-data-table-select-actions-container {
  flex-shrink: 0;
}

.x-data-table-select-actions {
  padding-left: 16px;
  display: flex;
  gap: 10px;
}

.button-container {
  width: 34px;
  height: 36px;
}

.no-records {
  color: var(--v-noRecords-base);
  text-align: center;
  margin-top: 20px;
}

.additional-row-action {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.additional-row-action:hover {
  background-color: var(--v-menuItemHover-base);
}

.page-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 36px;
}

.page-select {
  width: 120px;
}

.additional-row-action >>> .v-icon {
  transition: none;
}

.version-information {
  font-weight: bold;
  font-size: 16px;
}

.version-information-row {
  display: flex;
  gap: 5px;
}

.version-information-key {
  font-weight: bold;
  width: 85px;
}

.table-wrapper >>> .headline-text {
  font-weight: normal;
  padding-left: 5px;
}
</style>