<template>
  <div>
    <RemoteTooltip
        :path="`serve.php/${testInfoLink}`" :additionalAttributes="additionalAttributes" :dialogAttributes="{}">
      <a :href="testLink">
        <v-icon>mdi-information</v-icon>
      </a>
    </RemoteTooltip>
  </div>
</template>

<script>
import RemoteTooltip from '@/commonComponents/remoteTooltip';

export default {
  name: 'TestInfoButton',
  components: {RemoteTooltip},
  props: {
    testId: Number,
    testIdKey: String,
  },
  data() {
    return {
      testInfoLink: '?f=testing&f2=testInfo&function=getInfo&n_id=',
    };
  },
  computed: {
    testLink() {
      return `${this.testInfoLink}${this.testId}&n_id_key=${this.testIdKey}`;
    },
    additionalAttributes() {
      return {
        function: 'getInfoMini',
        n_id: this.testId,
        n_id_key: this.testIdKey,
      };
    },
  },
};
</script>

<style scoped>

</style>