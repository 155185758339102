<template>
  <div>

      <div v-if="disabled === true">
        {{ languagePack("info_notActiveType") }}
      </div>


    <v-list class="thirdLevel" dense shaped>
      <v-list-item v-for="(menuItem, index) in submenuList" :key="index">
        <v-list-item-content>
          <v-list-item-title style="font-weight:unset!important;">
            <v-divider v-if="menuItem['type'] === 'line'"></v-divider>
            <div
              v-else-if="menuItem['type'] === 'title'"
              v-bind="menuItem['attr']"
              class="primary--text childMenuHeader"
            >{{ getName(menuItem["name"]) }}
            </div>
            <!-- <a
        v-else-if="typeof menuItem['children']=='undefined'"
        v-bind="menuItem['attr']"
          >{{getName(menuItem["name"])}}</a>-->

            <menuLink
              v-else-if="typeof menuItem['children'] == 'undefined'"
              :menuItem="menuItem"
              :index="index"
              :disabled="disabled"
            ></menuLink>

            <!-- <div v-else>
        <h3 v-bind="menuItem['attr']">{{language[menuItem["name"]]}}</h3>

        <cloud
          :submenuList="menuItem['children']"
          v-if="typeof menuItem['type'] !='undefined' && menuItem['type']=='cloud'"
        ></cloud>

        <div v-else>
          <div
            v-for="(subMenuItem,subIndex)  in menuItem['children'] "
            :key="subIndex "
            class="secondLevelMegaCol"
          >
            <div v-if="typeof subMenuItem['children'] !='undefined'">
              <h4 v-bind="subMenuItem['attr']">{{language[subMenuItem["name"]]}}</h4>

              <cloud :submenuList="subMenuItem['children']"></cloud>
            </div>

            <a v-else v-bind="subMenuItem['attr']">{{language[subMenuItem["name"]]}}</a>
          </div>
        </div>
          </div>-->
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
//import cloud from "@/components/cloud.vue";
import menuLink from "@/commonComponents/menuLink.vue";
export default {
  components: { /*cloud,*/ menuLink },
  props: ["submenuList", "disabled"],
  methods: {},
};
</script>
