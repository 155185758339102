<template>
  <div>
    <div :style="style" class="headline-box-headline primary">
      <!--
        When the content toggle switch is clicked.
        @event input
        @property {boolean} value - Content toggle switch value.
      -->
      <x-switch
          v-if="toggleable" v-model="dataValue" class="switch" color="success" @change="$emit('input', $event)"/>
      <div class="headline-text" v-text="headline"/>
      <div class="after-headline">
        <!-- @slot The content of the headline box. -->
        <slot name="after-headline"/>
      </div>
    </div>
    <div v-if="contentEnabled" :style="contentStyle" :class="!noBorder ? 'content' : ''">
      <!-- @slot The area after the headline text. -->
      <slot/>
    </div>
  </div>
</template>

<script>
import XSwitch from '@/components/basic/XSwitch';

/**
 * A box with a headline and a content area.
 *
 * ![HeadlineBox](../../../img/HeadlineBox.png?raw=true)
 */
export default {
  name: 'HeadlineBox',
  components: {XSwitch},
  props: {
    /**
     * Whether the content area is shown or not. Only works if toggleable is true.
     * @model
     */
    value: Boolean,
    /**
     * The text displayed as the headline.
     */
    headline: String,
    /**
     * Whether the content area can be displayed and hidden with a switch.
     */
    toggleable: Boolean,
    /**
     * The padding of the content area. Overwrites standard padding. Examples: 20px, 10%.
     */
    contentPadding: [String, Number],
    /**
     * Removes padding of the content area.
     */
    noPadding: Boolean,
    /**
     * Removes border of the content area.
     */
    noBorder: Boolean,
  },
  data() {
    return {
      dataValue: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.dataValue) {
          this.dataValue = value;
        }
      },
    },
  },
  computed: {
    contentEnabled() {
      if (!this.toggleable) return true;
      return this.dataValue;
    },
    style() {
      return this.contentEnabled ? 'border-top-left-radius: 10px; border-top-right-radius: 10px;' :
          'border-radius: 10px;';
    },
    contentStyle() {
      if (this.noPadding) return '';
      let padding = '20px';
      if (this.contentPadding) {
        if (typeof this.contentPadding !== 'string' || !this.contentPadding.includes('%') ||
            !this.contentPadding.includes('px')) {
          padding = `${this.contentPadding}px`;
        } else {
          padding = this.contentPadding;
        }
      }
      return `padding: ${padding}`;
    },
  },
};
</script>

<style scoped>
.headline-box-headline {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 11px;
  color: white;
  min-height: 40px;
}

.headline-text {
  flex: 1 0 auto;
  font-weight: 500;
}

.after-headline {
  flex: 1 1 100%;
}

.headline-text:first-child {
  padding-left: 9px;
}

.switch {
  max-height: 30px;
  margin: 0;
  padding-top: 3px;
}

.content {
  border: 1px solid gray;
}
</style>