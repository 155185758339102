<template>
  <div  class="" >
    <a
   
    style="vertical-align: super;"
      v-for="(info, index) in featureIcons"
      :key="index"
      :href="info.link"
      target="_blank"
    >
      <v-tooltip  top max-width=400>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-small v-bind="attrs" v-on="on"
            ><v-icon  x-small >mdi-{{ info.icon }}</v-icon></v-btn
          >
        </template>
        <span>{{ info.tooltip }}</span>
      </v-tooltip>
    </a>
  </div>
</template>
<script>
export default {
  props: ["menuItem", "disabled"],
  computed: {
    featureIcons() {
      let featureIcons = [
       
      ];
      if (
        typeof this.menuItem.features != "undefined" &&
        typeof this.menuItem.features.featureIcons != "undefined"
      ) {
        featureIcons = this.menuItem.features.featureIcons;
      }

      return featureIcons;
    },
  },
};
</script>

