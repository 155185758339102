<template>
  <div :class="centered ? 'centered' : ''">
    <v-progress-circular indeterminate color="primary" size="100" width="10"/>
  </div>
</template>

<script>
export default {
  name: 'XThrobber',
  props: {
    centered: Boolean,
  }
};
</script>

<style scoped>
.centered {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>