<template>
  <v-navigation-drawer v-model="localDrawer" absolute temporary width="90%">
    <!-- <v-list-item>
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>John Leider</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider> -->

    <v-list>
      <v-list-item @click="$refs.projectSwitcher.switchProjectRequest()">
        <v-list-item-icon>
          <v-icon>mdi-move-resize-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Switch Project</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <projectSwitcher ref="projectSwitcher"></projectSwitcher>

      <template v-for="(info, i) in cleanedIterator">
        <template
          v-if="
            typeof info['type'] != 'undefined' && info['type'] == 'upperLink'
          "
        >
          <menuLink :menuItem="info" :disabled="false" :index="i" :key="i">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>{{ info.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ getName(info["name"]) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </menuLink>
        </template>

        <v-list-group
          v-else-if="info['features']['useOnMobile']"
          :key="i"
          :prepend-icon="info.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ language[info.name] }}</v-list-item-title>
          </template>
          <template v-if="info['features']['useOnMobile']">
            <template v-for="(childrenInfo, childIndex) in info.children">
              <v-list-group
                :key="childIndex"
                no-action
                sub-group
                v-if="childrenInfo['features']['useOnMobile']"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      language[childrenInfo.name]
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template
                  v-for="(
                    subChildrenInfo, subChildIndex
                  ) in childrenInfo.children"
                >
                  <v-list-item
                    v-if="subChildrenInfo['features']['useOnMobile']"
                    :key="subChildIndex"
                    link
                  >
                    <ocsTabsMobile
                      v-if="subChildrenInfo['type'] == 'ocsTabs'"
                      :menu="subChildrenInfo"
                    ></ocsTabsMobile>
                    <v-divider
                      v-else-if="subChildrenInfo['type'] == 'line'"
                    ></v-divider>
                    <menuLink
                      v-else
                      :menuItem="subChildrenInfo"
                      :disabled="false"
                      :index="subChildIndex"
                    ></menuLink>

                    <!-- <v-list-item-title>{{
              language[subChildrenInfo.name]
            }}</v-list-item-title> -->

                    <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </template>
        </v-list-group>
      </template>
      <v-divider></v-divider>
      <v-list-item href="?f=logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import projectSwitcher from "@/components/legacy/projectSwitcher.vue";
import menuLink from "@/commonComponents/menuLink.vue";
import ocsTabsMobile from "@/commonComponents/ocsTabsMobile.vue";

export default {
  components: { menuLink, ocsTabsMobile, projectSwitcher },
  props: ["value", "menu"],
  data() {
    return {
      //  drawer: null,
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
    };
  },
  computed: {
    cleanedIterator: function () {
      // seep copy to keep origin intact
      let cleanedIterator = { ...this.menu };
      this.$delete(cleanedIterator, "systemAdministration");
      return cleanedIterator;
    },
    localDrawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>