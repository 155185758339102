<template>
  <x-dialog
      :buttons="buttons" :text="text" :title="title" :value="value" :width="width" @input="$emit('input', $event)">
    <template #dialog-content>
      <slot/>
    </template>
  </x-dialog>
</template>

<script>
import XDialog from '@/components/basic/XDialog';

export default {
  name: 'YesNoDialog',
  components: {XDialog},
  props: {
    value: Boolean,
    title: String,
    text: String,
    width: {
      Number,
      String,
    },
    yesText: String,
    yesColor: String,
    yesIcon: String,
    yesTextColor: String,
    yesDisabled: Boolean,
    noText: String,
    noColor: String,
    noIcon: String,
    noTextColor: String,
  },
  data() {
    return {
      buttons: [
        {
          text: this.yesText ? this.yesText : 'Yes',
          icon: this.yesIcon ? this.yesIcon : 'mdi-check',
          color: this.yesColor ? this.yesColor : 'primary',
          textColor: this.yesTextColor ? this.yesTextColor : 'white',
          disabled: () => {
            return this.yesDisabled;
          },
          click: this.yes,
        },
        {
          text: this.noText ? this.noText : 'No',
          icon: this.noIcon ? this.noIcon : 'mdi-cancel',
          color: this.noColor ? this.noColor : 'secondary',
          textColor: this.noTextColor ? this.noColor : '',
          click: this.no,
        },
      ],
    };
  },
  methods: {
    yes() {
      this.$emit('yes');
      this.$emit('input', false);
    },
    no() {
      this.$emit('no');
      this.$emit('input', false);
    },
  },
};
</script>

<style scoped></style>