<template>
  <!-- <v-col {$attributeString}> -->
  <v-col cols="2">
    <field-name-only :fieldAttributes="fieldAttributes"></field-name-only>
  </v-col>
</template>
   <script>
import fieldNameOnly from "./fieldNameOnly.vue";
export default {
  components: {
    fieldNameOnly,
  },
  props: ["fieldAttributes"],
};
/*    
  {$fieldTdNameAttributes=[]}
  v-if="typeof $templateContent['fieldsFormat']) && isset($templateContent['fieldsFormat']['title'] !='undefined'"
   {$fieldTdNameAttributes=$templateContent['fieldsFormat']['title']}
    {/if}
  v-if="typeof fieldAttributes['fieldAttrNameTd'] !='undefined'"
    {$secoundArray=fieldAttributes['fieldAttrNameTd']}
 {$fieldTdNameAttributes=mergeTwoAttributesArray($fieldTdNameAttributes,$secoundArray)}
{/if}
  {$attributeString=convertAttributeArrayToString($fieldTdNameAttributes)} */
</script>