import Vue from 'vue';

let snackbarId = 0;

let vuexCfg = {
    state: {
        pagePost: {},
        dialogRef: '',
        fieldDataMutated: false,
        tablesData: {},
        additionalStepsInfo: {},
        additionalSubmitData: {},
        dialogDataObject: {},
        submittedResult: {},
        submittedData: {},
        checkStartParameters: {},
        subscriberCategories: [],
        pageLoading: false,
        openedDialogIndex: null,
        showRecoveryList: false,
        notifications: [],
        tableDialog: {
            active: false,
            title: '',
            text: '',
            width: 350,
            yes: () => {
                return undefined;
            },
        },
        fiberNet: JSON.parse(localStorage.getItem('fiberNet')),
        role: 0,
        project: {
            id: 0,
            hash: '',
            name: '',
        },
        organization: {
            id: 0,
            hash: '',
            name: '',
        },
    },
    mutations: {
        showRecoveryList(state, post) {
            state.showRecoveryList = post;
        },
        openInfoDialog(state, post) {
            state.openedDialogIndex = post;
        },
        pagePostChanged(state, post) {
            state.pagePost = post;
        },
        subscriberCategoriesChanged(state, post) {
            state.subscriberCategories = post;
        },
        pageLoading(state, post) {
            state.pageLoading = post;
        },
        checkStartParameters(state, post) {
            state.checkStartParameters = post;
        },
        tableChanged(state, table) {
            Vue.set(state.tablesData, table.id, table);
        },
        dialogResultChanged(state, result) {
            if (typeof state.dialogDataObject[result.dialogRef] == 'undefined') {
                Vue.set(state.dialogDataObject, result.dialogRef, {});
            }
            Vue.set(state.dialogDataObject[result.dialogRef], 'result', result);
        },
        submittedResultChanged(state, result) {
            if (typeof state.submittedResult[result.dialogRef] == 'undefined') {
                Vue.set(state.submittedResult, result.dialogRef, {});
            }
            Vue.set(state.submittedResult[result.dialogRef], 'result', result);
        },
        dataSubmitted(state, response) {
            Vue.set(state.submittedData, response.data.result.dialogRef, response);
            Vue.set(state.submittedData, 'latestRef', response.data.result.dialogRef);

        },
        additionalSubmitDataChanged(state, additionalSubmitData) {
            for (const [key, value] of Object.entries(additionalSubmitData)) {
                if (value !== undefined) {
                    state.additionalSubmitData[key] = value;
                } else {
                    delete state.additionalSubmitData[key];
                }
            }
        },
        resultChanged(state, result) {
            state.result = result;
        },
        closeDialog(state, dialogRef) {
            state.dialogRef = dialogRef;
        },
        openResultDialog(state, post) {
            state.dialogResult = post;
        },
        dialogButtonsLoading(state, post) {
            state.dialogButtonLoading = post;
        },
        setFieldDataMutated(state, post) {
            state.fieldDataMutated = post;
        },
        stepResorted() {

        },
        notification(state, payload) {
            let text = payload;
            let length = 2000;
            if (typeof payload === 'object') {
                text = payload.text;
                if (payload.length) length = payload.length;
            }
            const notification = {
                id: snackbarId,
                text: text,
                show: true,
            };
            snackbarId++;
            state.notifications.push(notification);
            setTimeout(() => {
                notification.show = false;
                state.notifications.splice(state.notifications.indexOf(notification), 1);
            }, length);
        },
        updateTableDialog(state, payload) {
            state.tableDialog = payload;
        },
        toggleFibernet(state) {
            state.fiberNet = !state.fiberNet;
            localStorage.setItem('fiberNet', state.fiberNet.toString());
            location.reload();
        },
        setUserData(state, payload) {
            state.role = payload.role;
            state.project = payload.project;
            state.organization = payload.organization;
        },
        setProject(state, payload) {
            state.project = payload;
        },
        setProjectHash(state, payload) {
            state.projectHash = payload;
        },
    },
};
export {
    vuexCfg,
};