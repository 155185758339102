<template>
  <XBtn
      :text="split && split.text ? split.text(value) : value"
      :color="color"
      height="25"
      elevation="0"
      class="total-button"
      :width="split ? split.width : undefined"
      @click="$emit('click', $event)"/>
</template>

<script>
import XBtn from '@/components/basic/XBtn';

export default {
  name: 'TotalButton',
  components: {XBtn},
  props: {
    value: Number,
    split: Object,
    active: Boolean,
  },
  computed: {
    color() {
      return `dark${this.active ? '' : ' lighten-3'}`;
    },
  },
};
</script>

<style scoped>
.total-button {
  flex: 0 0 auto;
}

.total-button >>> .v-btn:not(.v-btn--round).v-size--default {
  color: var(--v-text-inverted-base);
  font-size: 12px;
}
</style>