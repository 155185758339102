<template>
  <div ref="leaflet" id="main"></div>
</template>

<script>
import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/scatter";
import "echarts/lib/chart/effectScatter";
import "@/js/echarts-leaflet/echarts-leaflet";
import light from "@/js/acctopus-light.json";
//import  "JS/acctopus-light.js";
//  var echarts__default = 'default' in echarts ? echarts : echarts; was changed in  tom make it work

export default {
  props: ["option", "rangeChanged"],
  data() {
    return {
      charts: null,
    };
  },
  mounted() {
    let el = this.$refs.leaflet;
    echarts.registerTheme("acctopus-light", light);
    this.charts = echarts.init(el, "acctopus-light");
    this.initMaps(this.option);
    /*   let el = this.$refs.leaflet;
    echarts.registerTheme("acctopus-light", light);
    var myChart = echarts.init(el,"acctopus-light");
    myChart.setOption(this.option);*/
  },
  watch: {
    rangeChanged(value) {
      if (value) {
        this.charts.clear();
      }
    },
    option(value) {
      this.initMaps(value);
    },
  },
  methods: {
    initMaps(option) {
      this.charts.setOption(option);
    },
  },
};
</script>

<style scoped>
#main {
  width: 100%;
  height: 100%;
  margin: 0;
}

@import "~leaflet/dist/leaflet.css";
</style>