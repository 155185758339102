<template>
  <XDialog
      :persistent="modified"
      :title="title"
      :value="value"
      content-height="768"
      unpadded
      width="1800"
      @input="close"
      @click:outside="close">
    <template #dialog-content>
      <div class="explorer-dialog-content">
        <v-tabs v-model="selectedTab">
          <v-tab
              v-for="tab in tabs"
              :key="tab"
              :disabled="(!configurationValid && tab !== tabs[selectedTab]) || tab === 'Logging'"
              v-text="tab"/>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <ExplorerStatistics class="padded" :explorer-name="explorer.name"/>
          </v-tab-item>
          <v-tab-item>
            <ExplorerConfiguration class="padded" v-model="dataExplorer" :disabled="history"/>
          </v-tab-item>
          <v-tab-item>
            <ExplorerSystemInfo class="padded" :value="explorer"/>
          </v-tab-item>
          <v-tab-item>
            <StatusLog class="padded" :explorer-name="explorer.name"/>
          </v-tab-item>
          <v-tab-item/>
          <v-tab-item>
            <ExplorerTestResults class="padded" :explorer-name="explorer.name"/>
          </v-tab-item>
          <v-tab-item>
            <ExplorerLocalServices v-model="dataExplorer.localServices" @valid="localServicesValid = $event"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <UnsavedChangedDialog v-model="unsavedChangesDialog" @yes="$emit('input', false)"/>
      <LoadingDialog v-model="loading"/>
    </template>
    <template #dialog-bottom>
      <InputRow>
        <XTextField v-model="dataExplorer.history.comment" label="Comment" class="comment"/>
        <XBtn
            :text="!history ? 'Save' : 'Restore'"
            color="save"
            :icon="!history ? 'mdi-content-save' : 'mdi-backup-restore'"
            :disabled="!valid"
            @click="save"/>
        <XBtn text="Cancel" color="secondary" icon="mdi-cancel" @click="close"/>
      </InputRow>
    </template>
  </XDialog>
</template>

<script>
import XDialog from '@/components/basic/XDialog';
import LoadingDialog from '@/components/basic/LoadingDialog';
import UnsavedChangedDialog from '@/components/extended/UnsavedChangesDialog';
import ExplorerStatistics from '@/components/specific/ExplorerStatistics';
import ExplorerConfiguration from '@/components/specific/ExplorerConfiguration';
import ExplorerSystemInfo from '@/components/legacy/explorerSystemInfo';
import StatusLog from '@/components/legacy/statusLog';
import ExplorerTestResults from '@/components/specific/ExplorerTestResults';
import ExplorerLocalServices from '@/components/specific/ExplorerLocalServices.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XBtn from '@/components/basic/XBtn.vue';

export default {
  name: 'ExplorerDialog',
  components: {
    XBtn,
    XTextField,
    InputRow,
    ExplorerLocalServices,
    ExplorerTestResults,
    StatusLog,
    ExplorerSystemInfo,
    ExplorerConfiguration,
    ExplorerStatistics,
    UnsavedChangedDialog,
    LoadingDialog,
    XDialog,
  },
  props: {
    value: Boolean,
    explorer: Object,
  },
  data() {
    return {
      selectedTab: '',
      tabs: ['Statistic', 'Configuration', 'System information', 'Logbook', 'Logging', 'Last test results', 'Local services'],
      pickerOptions: {
        period: '0.084',
      },
      info: {
        data: {
          timerange: 'Last 24 hours',
          begin: 'now-24h',
          end: 'now',
        },
        meta: {
          refreshInterval: {
            name: 'Refresh Interval',
            type: 'select',
            possibleValues: [
              'off',
              '10 s',
              '30 s',
              '1 min',
              '2 min',
              '5 min',
              '10 min',
            ],
          },
        },
      },
      refreshInterval: '0',
      configurationValid: true,
      unsavedChangesDialog: false,
      modified: false,
      dataExplorer: {},
      loading: false,
      localServicesValid: true,
    };
  },
  computed: {
    title() {
      return !this.history ? `Explorer: ${this.explorer.name}`
          : `Explorer: ${this.explorer.name} (version: ${this.explorer.history.version})`;
    },
    history() {
      return this.explorer.history && !!this.explorer.history.action;
    },
    valid() {
      return this.configurationValid && this.localServicesValid;
    },
  },
  watch: {
    value() {
      if (!this.parseBoolean(localStorage.getItem('back'))) {
        this.selectedTab = 0;
      } else {
        this.selectedTab = 5;
      }
    },
    explorer: {
      immediate: true,
      handler(value) {
        const dataExplorer = this.deepCopy(value);
        if (dataExplorer.localServices && dataExplorer.localServices['PNSolDeltaQ']) {
          dataExplorer.localServices['PNSolDeltaQ'] = [dataExplorer.localServices['PNSolDeltaQ']];
        }
        this.setDefaultValues(dataExplorer, {
          localServices: {
            'PNSolDeltaQ': [],
            'iperf': [],
            'udpst': [],
          },
        });
        if (!dataExplorer.history) dataExplorer.history = {};
        dataExplorer.history.comment = '';
        this.dataExplorer = dataExplorer;
        this.modified = false;
      },
    },
  },
  methods: {
    save() {
      if (!this.valid) return;
      this.loading = true;
      const dataExplorer = this.deepCopy(this.dataExplorer);
      delete dataExplorer.localServices;
      this.services.explorerStatus.updateExplorer(dataExplorer, () => {
        const convertedLocalServices = this.deepCopy(this.dataExplorer.localServices);
        convertedLocalServices['PNSolDeltaQ'] = convertedLocalServices['PNSolDeltaQ'][0];
        this.services.explorerStatus.saveServiceConfiguration(this.dataExplorer.id, convertedLocalServices, () => {
          this.$emit('input', false);
          this.$emit('update-explorer', this.dataExplorer);
          this.loading = false;
        });
      });
    },
    close() {
      if (!this.modified) {
        this.$emit('input', false);
      } else {
        this.unsavedChangesDialog = true;
      }
    },
    handleExplorerConfigurationInput(value) {
      this.modified = true;
      this.dataExplorer = value;
    },
  },
};
</script>

<style scoped>
.padded {
  padding: 20px;
}

.statistic {
  overflow-y: scroll;
  height: 600px;
}

.comment {
  width: 500px;
}
</style>