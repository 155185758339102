<template>
  <v-radio :label="label" :value="value" @change="$emit('change', $event)"/>
</template>

<script>
export default {
  name: 'XRadio',
  props: {
    label: String,
    value: [String, Number],
  },
};
</script>