<template>
  <XTextField
      :label="label" :value="dataValue" @input="handleInput" :rules="computedRules" :tooltip="tooltip"/>
</template>

<script>
import XTextField from '@/components/basic/XTextField';

export default {
  name: 'DurationTextField',
  components: {XTextField},
  props: {
    value: [String, Number],
    label: String,
    required: Boolean,
    rules: Array,
    type: {
      type: String,
      default: 'duration',
    },
  },
  data() {
    return {
      dataValue: '',
      regex: /^(\d+d)?(\d+h)?(\d+m)?(\d+s)?$/,
      rangeRegex: /^(?!-)(\d+d)?(\d+h)?(\d+m)?(\d+s)?-(?=.)(\d+d)?(\d+h)?(\d+m)?(\d+s)?(?!-)$/,
    };
  },
  computed: {
    computedRules() {
      const computedRules = [];
      if (this.rules) computedRules.push(...this.rules);
      if (this.required) computedRules.push(this.getRequiredRule());
      computedRules.push(v => this.check(v) || `Invalid ${this.type === 'range' ? 'range' : 'duration'}.`);
      return computedRules;
    },
    tooltip() {
      let tooltip = 'Examples:<br />';
      if (this.type === 'range') tooltip += '1s-5s, 30m-2h, 13m-37m, 4h-20h';
      else tooltip += '2h, 2h30m, 13m37s, 4m20s';
      return tooltip;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.type === 'seconds') value = this.secondsToDuration(value);
        this.dataValue = value;
      },
    },
  },
  methods: {
    handleInput(value) {
      if (this.check(value)) {
        if (this.type === 'seconds') value = this.durationToSeconds(value);
        this.$emit('input', value);
      }
    },
    check(value) {
      if (this.type === 'duration') return this.regex.test(value) && value.match(this.regex)[0] !== '';
      else if (this.type === 'range') return this.rangeRegex.test(value);
    },
  },
};
</script>

<style scoped>

</style>