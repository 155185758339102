<template>
  <div class="test-result-counts">
    <TotalButton
        :value="value.total"
        @click="handleTotalClick"
        :split="splits.total"
        :active="activeSplit === 'total'"/>
    <SplitButton
        :value="value"
        :splits="splits"
        :total="value.total"
        :active-split="activeSplit"
        class="split-button"
        @split-click="handleSplitClick"
        @update:active-split="activeSplit = $event"/>
  </div>
</template>

<script>
import TotalButton from '@/components/extended/TotalButton';
import SplitButton from '@/components/basic/SplitButton';

export default {
  name: 'TotalAndSplit',
  components: {
    SplitButton,
    TotalButton,
  },
  props: {
    value: Object,
    splits: Object,
  },
  data() {
    return {
      activeSplit: 'total',
    };
  },
  created() {
    if (this.value.total === undefined) {
      this.$emit('input', {
        total: 0,
      });
    }
  },
  methods: {
    handleTotalClick() {
      this.activeSplit = 'total';
      this.$emit('split-click', 'total', this.value.total, ...this.splits.total.clickParams);
      if (this.splits.total.click) this.splits.total.click('total', this.value.total, ...this.splits.total.clickParams);
    },
    handleSplitClick(key, value, ...clickParams) {
      this.$emit('split-click', key, value, ...clickParams);
    },
  },
};
</script>

<style scoped>
.test-result-counts {
  display: flex;
  gap: 4px;
}

.split-button {
  flex-grow: 1;
}
</style>