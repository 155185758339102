<template>
  <div class="x-textarea-field">
    <v-textarea
        v-model="dataValue"
        :label="label"
        :rules="computedRules"
        auto-grow
        dense
        outlined
        hide-details="auto"
        @input="$emit('input', $event)"/>
    <HelpButton :id="id"/>
  </div>
</template>
<script>
import getRule from '@/js/rules';
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  name: 'XTextarea',
  components: {HelpButton},
  props: {
    value: String,
    label: String,
    required: Boolean,
    rules: Array,
    validateImmediately: Boolean,
    id: String,
  },
  data() {
    return {
      dataValue: '',
    };
  },
  watch: {
    value(value) {
      this.dataValue = value;
    },
  },
  mounted() {
    if (this.validateImmediately) {
      this.dataValue = ' ';
      this.$nextTick(() => {
        this.dataValue = this.value;
      });
    } else {
      this.dataValue = this.value;
    }
  },
  computed: {
    computedRules() {
      let computedRules = [];
      if (this.required) {
        computedRules.push(this.getRequiredRule());
      }
      if (this.rules) {
        for (const rule of this.rules) {
          if (typeof rule === 'string') computedRules.push(getRule(rule));
          else computedRules.push(rule);
        }
      }
      if (!computedRules.length) computedRules = undefined;
      return computedRules;
    },
  },
};
</script>

<style scoped>
.x-textarea-field {
  display: flex;
}

.x-textarea-field >>> .help-button {
  margin-top: 2px;
}
</style>