<template>
  <div class="date-time-range-and-refresh">
    <DateTimeRange v-model="range" @input="emitInput"/>
    <XSelect
        v-model="refreshTime"
        label="Refresh"
        :items="refreshTimes"
        @input="emitInput"
        class="refresh-select"
        :autocomplete="false"
        y-offset/>
  </div>
</template>

<script>
import XSelect from '@/components/basic/XSelect';
import DateTimeRange from '@/components/basic/DateTimeRange';

export default {
  name: 'DateTimeRangeAndRefresh',
  components: {
    DateTimeRange,
    XSelect,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      range: {
        from: new Date(new Date().getTime() - 86400 * 1000),
        to: new Date(),
        seconds: 86400,
      },
      selectItems: ['Last 24 hours'],
      refreshTime: 0,
      refreshTimes: [
        {
          text: 'off',
          value: 0,
        },
        {
          text: '30s',
          value: 30,
        },
        {
          text: '1m',
          value: 60,
        },
        {
          text: '2m',
          value: 120,
        },
        {
          text: '5m',
          value: 300,
        },
        {
          text: '10m',
          value: 600,
        },
      ],
      menu: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        let noValue = false;
        if (!value || !Object.keys(value).length) {
          value = {
            range: {},
            refresh: 0,
          };
          noValue = true;
        }
        this.range.from = value.range.from ? new Date(value.range.from) : new Date(new Date().getTime() - 86400 * 1000);
        this.range.to = value.range.to ? new Date(value.range.to) : new Date();
        this.range.seconds = value.range.seconds ? value.range.seconds : 86400;
        this.refreshTime = value.refresh ? value.refresh : 0;
        if (noValue) this.emitInput();
      },
    },
  },
  methods: {
    emitInput() {
      this.$emit('input', {
        range: this.deepCopy(this.range),
        refresh: this.refreshTime,
      });
    },
  },
};
</script>

<style scoped>
.date-time-range-and-refresh {
  display: flex;
  gap: 10px;
  align-items: center;
}

.refresh-select {
  width: 116px;
}
</style>