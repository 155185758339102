<template>
  <div class="x-btn">
    <v-btn
        v-if="!tooltip"
        ref="button"
        :class="medium ? 'x-btn-medium' : ''"
        :color="color"
        :disabled="disabled"
        :height="height"
        :icon="!!icon && !text && !floating"
        :fab="floating"
        :x-small="xSmall"
        :small="floating || small"
        :text="flat"
        :depressed="floating"
        :large="large"
        :elevation="elevation"
        :block="block"
        :style="`${textColor ? `color: ${textColor};` : ''}
        ${width ? `width: ${typeof width === 'number' ? `${width}px` : width};`: ''}`"
        :to="to"
        @click="$emit('click', $event)">
      <v-icon v-if="icon && !iconAfterText" :left="!!text">{{ icon }}</v-icon>
      {{ text }}
      <v-icon :color="color"  v-if="icon && iconAfterText" :right="!!text"> {{ icon }}</v-icon>
    </v-btn>
    <v-tooltip v-else v-model="showTooltip" top>
      <template v-slot:activator="{}">
        <v-btn
            ref="button"
            :class="medium ? 'x-btn-medium' : ''"
            :color="color"
            :disabled="disabled"
            :height="height"
            :icon="!!icon && !text && !floating"
            :fab="floating"
            :x-small="xSmall"
            :small="floating || small"
            :text="flat"
            :depressed="floating"
            :large="large"
            :elevation="elevation"
            :block="block"
            :style="`${textColor ? `color: ${textColor};` : ''}
            ${width ? `width: ${typeof width === 'number' ? `${width}px` : width};`: ''}`"
            :to="to"
            @click="$emit('click', $event)"
            @mouseenter="showTooltip = true"
            @mouseleave="showTooltip = false">
          <v-icon v-if="icon && !iconAfterText" :left="!!text">{{ icon }}</v-icon>
          {{ text }}
          <v-icon v-if="icon && iconAfterText" :right="!!text">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span v-if="tooltip" v-html="tooltip"/>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'XBtn',
  props: {
    text: [String, Number],
    icon: String,
    color: String,
    textColor: String,
    tooltip: [Number, String],
    width: [Number, String],
    height: [Number, String],
    xSmall: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    disabled: Boolean,
    flat: Boolean,
    elevation: [Number, String],
    to: String,
    floating: Boolean,
    iconAfterText: Boolean,
    block: Boolean,
  },
  data() {
    return {
      showTooltip: false,
    };
  },
};
</script>

<style scoped>
.x-btn .x-btn-medium {
  height: 40px;
  padding: 0 20px;
}

.white--text .x-btn * {
  color: white;
  caret-color: white;
}
</style>