var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.userInfo &&  _vm.userInfo.loggedIn == true && !_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"mb-3 mt-3"},[(typeof _vm.userInfo!='undefined' &&
                             typeof _vm.userInfo['isDemoProject']!='undefined' &&
                             _vm.userInfo['isDemoProject']!=null &&
                             _vm.userInfo['isDemoProject']===true)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-toolbar',{staticClass:"mt-n3 mb-n7",staticStyle:{"background-color":"#66a56994","z-index":"1!important"},attrs:{"height":"55px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9","xl":"9","lg":"7","md":"4"}},[_c('div',{staticClass:"ml-2",staticStyle:{"color":"rgb(30,58,15)"}},[_vm._v(" Acctopus Degust Demo Project (view only)"),_c('br'),_vm._v(" Once you have installed your first Degust Explorer, you will want to switch to your own project.")])]),_c('v-col',{attrs:{"cols":"3","xl":"3","lg":"5","md":"8"}},[_c('v-row',{staticClass:"mt-n2",staticStyle:{"float":"right"}},[_c('v-col',{staticStyle:{"max-width":"225px"}},[_c('div',{staticStyle:{"width":"fit-content"}},[_c('addField',{attrs:{"path":_vm.tableUrl,"additionalAttributes":{
                                                function: 'downloadImageRequest',
                                                requestType: 'ajax',
                                             },"dialogAttributes":{},"showDialog":_vm.openDialogFlag},on:{"dialog-closed":function($event){_vm.openDialogFlag == false}}},[_c('div',{},[_c('primaryButton',{attrs:{"type":"button","label":"Get Explorer Image","color":"primary","id":"importExplorer"}})],1)])],1)]),_c('v-col',{staticStyle:{"max-width":"225px"}},[_c('primaryButton',{attrs:{"type":"button","label":"Change Project","color":"primary","id":"changeProject","disabled":_vm.disabled},on:{"click":function($event){return _vm.changeProject()}}})],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }