<template>
  <primaryButton
    label="Cancel"
    icon="mdi-cancel"
    v-on="$listeners"
    type="button"
    color="secondary"
    id="cancel_button"
    data-type="cancel_button"
  ></primaryButton>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  props: { buttonsIDPrefix: { default: "" } },
  components: { primaryButton },
};
</script>