<template>
  <v-app v-if="!publicContent" :key="currentHash" v-cloak>
    <!-- <graphql>
      </graphql> -->
    <notifications
        group="foo" style="width: 36%; top: 50%; left: 32%; z-index: 9999; position: absolute" classes="my-custom"/>
    <!-- <v-navigation-drawer app>
    </v-navigation-drawer>-->
    <headerContent
        :userInfo="userInfo"
        :menu="menu"
        :result="result"
        :loading="loading"
        :small-header="smallHeader"></headerContent>
    <!-- <v-app-bar app>
    </v-app-bar>-->
    <!-- Sizes your content based upon application components -->
    <v-main>
      <div v-if="true">
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <!-- <editDialog /> -->
          <div id="loginWindow" title="Login" style="visibility: hidden"></div>
          <div id="pageWarper">
            <notMobilePage
                v-if="result!=null &&typeof result.features!='undefined' && !result.features.useOnMobile && $vuetify.breakpoint.mobile"></notMobilePage>
            <dynamicComponent
                v-else
                v-for="type in content"
                :key="type"
                :type="type"
                :result="result"
                :additional="{ userInfo:userInfo}">
              {{ result }}
            </dynamicComponent>
          </div>
        </v-container>
      </div>
      <dynamicComponent
          v-else v-for="type in content" :key="type" :type="type" :result="result" :additional="{}">
        {{ result }}
      </dynamicComponent>
    </v-main>
    <footerBlock :userInfo="userInfo" :result="result"></footerBlock>
    <!-- <webapp></webapp> -->
    <div>
      <v-snackbar
          v-for="(notification, i) of $store.state.notifications"
          :key="notification.id"
          v-model="notification.show"
          :style="`margin-bottom: ${i * 60 + 30}px;`">
        {{ notification.text }}
      </v-snackbar>
    </div>
    <div class="refresh-container" v-if="hashChanged && $root.env !== 'development'">
      <div class="notification-header">
        <button type="button" class="close-refresh-modal" @click="closeModal" aria-label="Close">
          <span aria-hidden="true"><i class="fal fa-times fa-sm"></i></span>
        </button>
      </div>
      <div class="notification-body">
        <div class="notification-button">
          <p class="text-center font12">An update is available. Please save all current work and click update below. You can also accept these updates by refreshing your browswer at any time.</p>
          <p class="text-center"><span class="font10">Not updating may result in errors.</span></p>
        </div>
        <div class="refresh-button text-center">
          <button class="btn btn-default" @click="reloadApp">Update</button>
        </div>
      </div>
    </div>
  </v-app>
  <PublicContent v-else/>
</template>

<script>
import dynamicComponent from '@/commonComponents/dynamicComponent.vue';
import headerContent from '@/commonComponents/headerContent.vue';
import footerBlock from '@/components/legacy/footerBlock.vue';
import notMobilePage from '@/components/legacy/notMobilePage.vue';
import PublicContent from '@/components/specific/PublicContent';
import {refreshPageMixin} from '@/mixins/refresh-page';

export default {
  components: {
    PublicContent,
    dynamicComponent,
    headerContent,
    footerBlock,
    notMobilePage,
  },
  mixins: [refreshPageMixin],
  data() {
    return {
      loading: false,
      post: null,
      error: null,
      content: [],
      result: null,
      menu: null,
      userInfo: null,
    };
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  mounted() {
    window.addEventListener(
        'orientationchange',
        this.handleOrientationChange,
    );
  },
  watch: {
    // call again the method if the route changes
    $route: 'callFetchData',
    publicContent: {
      immediate: true,
      handler(value) {
        if (value) return;
        this.loadApp();
      },
    },
  },
  computed: {
    smallHeader() {
      return this.result != null && 'version' in this.result && this.result.version === 2;
    },
    publicContent() {
      return this.$route.fullPath.startsWith('/charts');
    },
  },
  methods: {
    loadApp() {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData('', () => {
      });

      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'pageLoading') {
          this.loading = state.pageLoading;
        } else if (mutation.type === 'pagePostChanged') {

          // clearing the tables for a new page
          this.$store.state.tablesData = {};
          let post = state.pagePost;
          this.post = post;

          this.result = post.data.result;
          this.content = [];
          if (typeof this.result != 'undefined') {
            this.content = this.result.content;
          }
          this.userInfo = post.data.userInfo;
          if (typeof post.data.menu != 'undefined') {
            this.menu = post.data.menu;
          }

          if (this.result != null) {
            document.title = this.result.pageTitle;
          }
          if (
              (this.menu == null || this.menu.length === 0) &&
              typeof this.result != 'undefined' &&
              this.result.disableMenu !== true
          ) {
            this.getMenu();
          }
          //this.menu = post.data.menu;
          //this.language = post.data.language;

          if (
              typeof this.result != 'undefined' &&
              typeof this.result.table != 'undefined'
          ) {
            // this.tablesData[this.result.table.id] = this.result.table;
            this.$store.commit('tableChanged', this.result.table);
          }
        } else if (mutation.type === 'resultChanged') {
          let result = state.result;
          this.$set(this, 'result', result);
          if (typeof this.result != 'undefined') {
            this.content = this.result.content;
          }
        }
      });

      this.updateProjectState();
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === 'portrait-primary') {
        // portrait mode
        this.$router.go();
      } else if (orientation === 'landscape-primary') {
        // landscape mode
      }
    },
    getMenu() {
      let caller = this;
      this.frameworkLegacyGetRequest(['menu'], (post) => {
        if (typeof post.data != 'undefined') {
          caller.menu = post.data;
        }
      }, false);
    },
    callFetchData() {
      this.fetchData(undefined, undefined);
    },
  },
};
</script>

<style>
@import "./assets/styles/style.css";

[v-cloak] {
  display: none;
}
</style>

<style scoped>
.refresh-container {
  width: 15%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: white;
  padding: 25px;
  -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
}
.close-refresh-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
}
</style>
