<template>
  <v-form
      ref="form"
      v-model="valid"
      class="explorer-configuration"
      @input="$emit('valid-change', $event);"
      :disabled="disabled">
    <XSwitch v-model="dataValue.active" label="Explorer active" type="numberString" @input="emitInput"/>
    <div class="top-part">
      <div class="top-left-part">
        <XTextField v-model="dataValue.alias" label="Alias" @input="emitInput"/>
        <TagSelect v-model="dataValue.tags" :items="tags" @input="emitInput"/>
      </div>
      <XTextarea
          v-model="dataValue.description"
          :class="`description ${dataValue.description ? '' : 'empty'}`"
          label="Description"
          @input="emitInput"/>
    </div>
    <HeadlineBox headline="IP Configuration" padded>
      <div class="ip-configuration">
        <object-input-row v-model="dataValue.configuration.testing" :columns="testingColumns" @input="emitInput"/>
        <object-input-row v-model="dataValue.configuration.network" :columns="ipTypeColumns" @input="emitInput"/>
        <div v-if="dataValue.configuration.network.type === 'static'" class="ip-types">
          <object-input-row v-model="dataValue.configuration.network.ipv4" :columns="ipV4Columns" @input="emitInput"/>
          <object-input-row v-model="dataValue.configuration.network.ipv6" :columns="ipV6Columns" @input="emitInput"/>
        </div>
      </div>
    </HeadlineBox>
  </v-form>
</template>

<script>
import XTextField from '@/components/basic/XTextField';
import XTextarea from '@/components/basic/XTextarea';
import ObjectInputRow from '@/components/basic/ObjectInputRow';
import XSwitch from '@/components/basic/XSwitch';
import HeadlineBox from '@/components/basic/HeadlineBox';
import TagSelect from '@/components/specific/TagSelect';

export default {
  name: 'ExplorerConfiguration',
  components: {
    TagSelect,
    HeadlineBox,
    ObjectInputRow,
    XSwitch,
    XTextField,
    XTextarea,
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      dataValue: null,
      tags: [],
      testingColumns: [
        {
          type: 'checkbox',
          value: 'enableIPv4',
          label: 'Enable IPv4 Testing',
        },
        {
          type: 'checkbox',
          value: 'enableIPv6',
          label: 'Enable IPv6 Testing',
        },
      ],
      ipTypeColumns: [
        {
          type: 'radio',
          value: 'type',
          direction: 'row',
          items: [
            {
              value: 'dhcp',
              text: 'DHCP',
            },
            {
              value: 'static',
              text: 'Static',
            },
          ],
        },
        {
          type: 'spacer',
        },
        {
          type: 'span',
          value: 'Fallback to DHCP after',
          condition: () => this.dataValue.configuration.network.type === 'static',
        },
        {
          type: 'int',
          value: 'fallback',
          width: 60,
          required: true,
          condition: () => this.dataValue.configuration.network.type === 'static',
        },
        {
          type: 'span',
          value: 's.',
          condition: () => this.dataValue.configuration.network.type === 'static',
        },
      ],
      ipV4Columns: [
        {
          type: 'span',
          value: 'v4',
        },
        {
          type: 'text',
          value: 'ip',
          label: 'IP address',
          width: '20%',
          required: true,
          rules: ['ipv4'],
        },
        {
          type: 'text',
          value: 'maskOrCidr',
          label: 'Mask/CIDR',
          width: '20%',
          rules: ['maskOrCidr'],
        },
        {
          type: 'text',
          value: 'gateway',
          label: 'Gateway',
          required: true,
          rules: ['ipv4'],
        },
        {
          type: 'text',
          value: 'dns1',
          label: 'DNS1',
          rules: ['ipv4'],
        },
        {
          type: 'text',
          value: 'dns2',
          label: 'DNS2',
          rules: ['ipv4'],
        },
      ],
      ipV6Columns: [
        {
          type: 'span',
          value: 'v6',
        },
        {
          type: 'text',
          value: 'ip',
          label: 'IP address',
          width: 'calc(40% + 10px)',
          rules: ['ipv6'],
        },
        {
          type: 'text',
          value: 'gateway',
          label: 'Gateway',
          rules: [v => !this.dataValue.configuration.network.ipv6.ip || !!v || this.getRequiredMessage(), 'ipv6'],
        },
        {
          type: 'text',
          value: 'dns1',
          label: 'DNS1',
          rules: ['ipv6'],
        },
        {
          type: 'text',
          value: 'dns2',
          label: 'DNS2',
          rules: ['ipv6'],
        },
      ],
      valid: false,
    };
  },
  mounted() {
    this.services.explorerStatus.getExplorerTags((tags) => {
      this.tags = tags;
    });
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.$refs.form.validate();
        this.$nextTick(() => {
          this.$emit('valid-change', this.valid);
        });
      });
    });
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.dataValue) {
          this.setDefaultValues(value, this.createDataValue());
          this.dataValue = value;
        }
      },
    },
  },
  methods: {
    createDataValue() {
      return {
        alias: '',
        tags: [],
        description: '',
        active: true,
        configuration: {
          network: {
            type: 'dhcp',
            fallback: 60,
            ipv4: {},
            ipv6: {},
          },
          testing: {
            enableIPv4: true,
            enableIPv6: true,
          },
        },
      };
    },
    emitInput() {
      this.$emit('input', this.deepCopy(this.dataValue));
      this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.explorer-configuration {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.top-part {
  display: flex;
  gap: 16px;
}

.top-part > * {
  flex: 1 1 50%;
}

.top-left-part {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.description >>> .v-input__control {
  height: calc(100% + 4px);
}

.description >>> .v-input__slot {
  height: calc(100% + 4px);
}

.ip-configuration, .ip-types {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>