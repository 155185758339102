<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template #activator="{}">
      <XTextField
          v-model="selectText" label="Time range" @click="menu = true" readonly class="range-text-field">
        <template #append>
          <v-icon @click="menu = true" :class="`range-text-field-icon ${menu ? ' rotated-icon' : ''}`">
            mdi-menu-down
          </v-icon>
        </template>
      </XTextField>
    </template>
    <v-card class="menu">
      <div class="menu-content">
        <div class="menu-content-left-side">
          <v-date-picker
              v-model="pickerDates"
              range
              :min="min ? dateToIsoDateString(min) : undefined"
              :max="max ? dateToIsoDateString(max) : undefined"
              @change="handlePickerInput"/>
          <v-form v-model="valid" class="from-to-text-fields">
            <DateTimeRangeTextField v-model="range" @input="custom = true"/>
          </v-form>
        </div>
        <v-list outlined dense>
          <v-subheader class="predefined-time-ranges-headline">Predefined Time Ranges</v-subheader>
          <v-list-item-group v-model="predefinedRange" @change="setRange">
            <v-list-item
                v-for="(predefinedRangesItem, i) of predefinedRanges" :key="i" class="predefined-time-range">
              {{ predefinedRangesItem.text }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <v-divider/>
      <div class="menu-buttons">
        <XBtn text="OK" color="primary" @click="emitInput" :disabled="!valid"/>
        <XBtn text="Cancel" color="secondary" @click="menu = false"/>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import DateTimeRangeTextField from '@/components/extended/DateTimeRangeTextField';
import XTextField from '@/components/basic/XTextField';
import XBtn from '@/components/basic/XBtn';

export default {
  name: 'DateTimeRange',
  components: {
    DateTimeRangeTextField,
    XTextField,
    XBtn,
  },
  props: {
    value: Object,
    min: Date,
    max: Date,
  },
  data() {
    return {
      range: {
        from: new Date(new Date().getTime() - 86400 * 1000),
        to: new Date(),
        seconds: 86400,
      },
      menu: false,
      selectText: 'Last 24 hours',
      pickerDates: [
        this.dateToIsoDateString(this.value ? this.value.from : new Date(new Date().getTime() - 86400 * 1000)),
        this.dateToIsoDateString(this.value ? this.value.to : new Date()),
      ],
      predefinedRange: 6,
      predefinedRanges: [
        {
          value: 300,
          text: 'Last 5 min',
        },
        {
          value: 900,
          text: 'Last 15 min',
        },
        {
          value: 1800,
          text: 'Last 30 min',
        },
        {
          value: 3600,
          text: 'Last 1 hour',
        },
        {
          value: 21600,
          text: 'Last 6 hours',
        },
        {
          value: 43200,
          text: 'Last 12 hours',
        },
        {
          value: 86400,
          text: 'Last 24 hours',
        },
        {
          value: 172800,
          text: 'Last 2 days',
        },
        {
          value: 604800,
          text: 'Last 7 days',
        },
        {
          value: 1209600,
          text: 'Last 14 days',
        },
        {
          value: 2592000,
          text: 'Last 30 days',
        },
      ],
      valid: false,
      custom: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        let noValue = false;
        if (!value.from || !value.seconds) {
          noValue = true;
          value = {};
        }
        this.range.from = value.from ? new Date(value.from) : new Date(new Date().getTime() - 86400 * 1000);
        this.range.to = value.to ? new Date(value.to) : new Date();
        this.range.seconds = value.seconds ? value.seconds : 86400;
        if (value.from) this.custom = true;
        if (value.seconds) {
          this.custom = false;
          this.predefinedRange = this.predefinedRanges.findIndex(x => x.value === value.seconds);
        }
        this.updateText();
        if (noValue) this.emitInput();
      },
    },
  },
  methods: {
    emitInput() {
      this.$emit('input', this.deepCopy(this.range));
      this.updateText();
      this.menu = false;
    },
    updateText() {
      this.selectText =
          this.custom ?
              `${this.dateToDateTimeString(this.range.from)}  -  ${this.dateToDateTimeString(this.range.to)}` :
              this.predefinedRanges[this.predefinedRange].text;
    },
    handlePickerInput(value) {
      this.range.from = this.isoDateStringToDate(value[0]);
      const to = this.isoDateStringToDate(value[1]);
      to.setHours(23);
      to.setMinutes(59);
      to.setSeconds(59);
      to.setMilliseconds(999);
      this.range.to = to;
      this.range.seconds = 0;
      this.custom = true;
    },
    setRange(index) {
      const range = this.predefinedRanges[index];
      const now = new Date();
      this.range.from = new Date(now.getTime() - range.value * 1000);
      this.range.to = now;
      this.range.seconds = range.value;
      this.custom = false;
    },
  },
};
</script>

<style scoped>
.range-text-field {
  width: 321px;
}

.range-text-field,
.range-text-field >>> .v-text-field > .v-input__control > .v-input__slot,
.range-text-field >>> input {
  cursor: pointer;
}

.range-text-field-icon {
  transition: .3s cubic-bezier(.25, .8, .5, 1), visibility 0s;
}

.range-text-field-icon.rotated-icon {
  transform: rotate(180deg);
}

.menu-content {
  display: flex;
  gap: 16px;
  padding: 16px;
}

.menu-content-left-side {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.from-to-text-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.predefined-time-ranges-headline {
  color: var(--v-text-base);
  font-weight: bold;
}

.predefined-time-range {
  font-size: 13px;
  font-weight: 500;
}

.menu-buttons {
  display: flex;
  gap: 10px;
  padding: 16px;
}

.menu-content >>> .v-picker__title {
  display: none;
}
</style>