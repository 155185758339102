var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(typeof _vm.fieldAttributes['inputFieldFormatter'] != 'undefined')?_c('dynamicComponent',_vm._g({attrs:{"type":_vm.fieldAttributes['inputFieldFormatter'],"result":_vm.templateContent,"additional":{
      field: _vm.field,
      fieldAttributes: _vm.fieldAttributes,
      value: _vm.value,
      valueLine: _vm.valueLine,
      fieldAttrInput: _vm.fieldAttrInput
    },"checkReadOnly":_vm.checkReadOnly,"hideDetails":_vm.hideDetails}},_vm.$listeners)):_c('defaultFieldTypes',_vm._g({attrs:{"fieldAttributes":_vm.fieldAttributes,"field":_vm.field,"templateContent":_vm.templateContent,"checkReadOnly":_vm.checkReadOnly,"value":_vm.value,"valueLine":_vm.valueLine,"hideDetails":_vm.hideDetails}},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }