<template>
  <div>
    <!-- <div>
      <v-menu
        v-for="(item, key) in iterator"
        :key="key"
        :close-on-content-click="false"
        open-on-hover
        bottom
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn color="primary" depressed v-on="on">{{language[item.name]}}</v-btn>
        </template>

        <v-list>
          <div
            v-for="(childItem, childKey) in item.children"
            :key="childKey"
            :prepend-icon="item.action"
            no-action
          >
  

            <v-list-item v-for="subItem in childItem.children" :key="subItem.name">
              <v-list-item-content>
                <v-list-item-title>
                  <router-link
                    v-if="subItem.attr && subItem.attr.href"
                    :to="subItem.attr.href"
                  >{{ language[subItem.name] }}</router-link>
                  <span v-else>{{ language[subItem.name] }}</span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>{{ subItem.action }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          
          </div>
        </v-list>
      </v-menu>
    </div>-->
    <v-menu
      v-for="(mainMenuItem, index) in cleanedIterator"
      :key="index"
      :close-on-content-click="false"
      open-on-hover
      bottom
      offset-y
      :close-delay="200"
      v-model="open[index]"
    >
      <template v-slot:activator="{ on }">
        <template  v-if="typeof mainMenuItem['type'] != 'undefined' &&
                  mainMenuItem['type'] === 'upperLink'">

       <router-link
               v-if="typeof mainMenuItem['features'].granted != 'undefined' &&
                  mainMenuItem['features'].granted === 'active'"
               :to="mainMenuItem.attr.href"
       >
       <v-btn
          depressed
          color="primary"
          :icon="$vuetify.breakpoint.xs"
          :id="index+'_main_menu'"
           :x-small=" $vuetify.breakpoint.smAndDown"
          >
           <template v-if="!$vuetify.breakpoint.xs">{{ language[mainMenuItem.name] }}</template>
       </v-btn>
       </router-link>
        </template>
        <template  v-else
        >
          <v-btn
          :x-small=" $vuetify.breakpoint.smAndDown"
         
          color="primary"
          depressed
          v-on="on"
          :icon="$vuetify.breakpoint.xs"
          :id="index+'_main_menu'"
          ><v-icon v-if="$vuetify.breakpoint.xs" color="white">{{mainMenuItem.icon}}</v-icon
          ><template v-if="!$vuetify.breakpoint.xs">{{ language[mainMenuItem.name] }}</template></v-btn
        >
        </template>
      </template>
      <v-card v-if="typeof mainMenuItem['type'] == 'undefined' ||
                  mainMenuItem['type'] !== 'upperLink' ">
        <v-list>
          <v-list-item>
            <div class="megaMenu">
              <div
                v-if="
                  typeof mainMenuItem['type'] != 'undefined' &&
                  mainMenuItem['type'] === 'layers'
                "
              >
                <!-- <div v-for="(menuItem ,tabIndex) in mainMenuItem['children']" :key="tabIndex">
                  <megaMenu
                    v-if="typeof menuItem['children'] !='undefined'"
                    :submenuList="menuItem['children']"
                    :enableTabs="getEnableTabs(mainMenuItem['children'])"
                    :containerWidth="getContainerWidth(mainMenuItem)"
                    :mainMenuItem="mainMenuItem"
                    :menuItem="menuItem"
                  ></megaMenu>
                </div>-->

                <v-tabs v-model="tab" right>
                  <v-tab
                    v-for="(menuItem, tabIndex, count) in mainMenuItem[
                      'children'
                    ]"
                    :key="tabIndex"
                    @mouseover="changeTab(count)"
                    @mouseout="resetTab(count)"
                    >{{ language[menuItem.name] }}</v-tab
                  >

                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="(menuItem, tabIndex) in mainMenuItem['children']"
                      :key="tabIndex"
                    >
                      <v-card flat>
                        <v-card-text>
                          <megaMenu
                            v-if="typeof menuItem['children'] != 'undefined'"
                            :submenuList="menuItem['children']"
                            :enableTabs="
                              getEnableTabs(mainMenuItem['children'])
                            "
                            :mainMenuItem="mainMenuItem"
                            :menuItem="menuItem"
                          ></megaMenu>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
                <!-- <v-btn
                    v-for="(menuItem ,tabIndex,count) in mainMenuItem['children']"
                    :key="tabIndex"
                    @mouseover="changeTab(count)"
                    @mouseout="resetTab(count)"
                >{{language[ menuItem.name ]}}</v-btn>-->
              </div>
              <div v-else>
                <megaMenu
                  :submenuList="mainMenuItem['children']"
                  :enableTabs="false"
                  :tabIndex="0"
                  :mainMenuItem="mainMenuItem"
                  :menuItem="{}"
                ></megaMenu>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <!-- <div v-for="(mainMenuItem, index) in iterator" :key="index">
      <li
        v-if="typeof mainMenuItem['children'] !='undefined'"
        class="firstLevel extended"
        :data-for="index"
        :id="'menu'+index"
      ></li>
      <li v-else class="firstLevel">
        <a v-bind="mainMenuItem.attr">{{mainMenuItem["name"]}}</a>
        <center>
          <div class="arrowplaceHolder"></div>
        </center>
        <div
          class="menuContainer"
          :id="'menuContainer'+mainMenu"
          :data-for="index"
          :data-activeTab="activeTab"
        ></div>
      </li>
    </div>-->
  </div>
</template>
<script>
import megaMenu from "@/commonComponents/megaMenu.vue";
//import menuLink from "@/commonComponents/menuLink.vue";

export default {
  components: {
    megaMenu,
  //  menuLink
  },
  data: function () {
    return { tab: 0, activeTab: 0, activeCalled: false, isHidden: false ,open:{}};
  },
  props: ["iterator"],
  computed: {
    cleanedIterator: function () {
      // seep copy to keep origin intact
      let cleanedIterator = { ...this.iterator };
      this.$delete(cleanedIterator, "systemAdministration");
      //console.log(cleanedIterator);
      return cleanedIterator;
    },
    // activeTab: function() {
    //   //   let activeTab="";
    //   // {$activeIndex={index}}
    //   // if(typeof  $session["activeTabs"]) && isset( $session["activeTabs"][index] !='undefined'){
    //   // activeTab="data-activeTab="|cat:$session["activeTabs"][index]
    //   // }
    //   return 0;
    // }
  },
    watch:{
    $route (){
       this.open={};
    }
  } ,
  methods: {
    getEnableTabs: function (menuItem) {
      let enableTabs = false;
      if (Object.keys(menuItem).length > 1) {
        enableTabs = true;
      }
      return enableTabs;
    },
    removeItem: function (menuList, value) {
      if (menuList != null && typeof menuList[value] != "undefined") {
        delete menuList[value];
      }
      return menuList;
    },
    changeTab: function (tabIndex) {
      this.activeCalled = setTimeout(
        function () {
          //  if (this.activeTab == tabIndex) {
          this.tab = tabIndex;
          // }
        }.bind(this),
        100
      );
    },
    resetTab: function () {
      clearTimeout(this.activeCalled);
    },
  },
};
</script>
