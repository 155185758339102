<template>
  <yes-no-dialog
      :value="value"
      no-text="Cancel"
      title="Notice"
      width="400"
      yes-color="primary"
      yes-text="Ok"
      @input="$emit('input', $event)"
      @yes="$emit('yes')">
    Changes have been made.
    <br/>
    Do you want to close without saving the changes?
  </yes-no-dialog>
</template>

<script>
import YesNoDialog from '@/components/extended/YesNoDialog';

export default {
  name: 'UnsavedChangedDialog',
  components: {YesNoDialog},
  props: {
    value: Boolean,
  },
};
</script>