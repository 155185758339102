<template>
  <div class="x-chart">
    <v-chart :option="dataOption" :theme="acctopusLightTheme" autoresize/>
  </div>
</template>

<script>
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {LineChart, PieChart, BarChart} from 'echarts/charts';
import {TitleComponent, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent} from 'echarts/components';
import VChart from 'vue-echarts';
import acctopusLightTheme from '@/js/acctopus-light.json';

use([
  CanvasRenderer,
  LineChart,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
]);

export default {
  name: 'XChart',
  components: {
    VChart,
  },
  props: {
    name: String,
    option: Object,
    request: Function,
    requestParams: Array,
    range: Object,
    refresh: Number,
  },
  data() {
    return {
      loaded: false,
      dataOption: null,
      tick: null,
      elapsed: 0,
      loading: false,
      acctopusLightTheme: acctopusLightTheme,
    };
  },
  mounted() {
    if (!this.refresh) this.reloadData();
    this.tick = setInterval(() => {
      if (!this.refresh || this.loading) return;
      this.elapsed++;
      if (this.elapsed >= this.refresh) {
        this.elapsed = 0;
        this.reloadData();
      }
    }, 1000);
  },
  watch: {
    option: {
      immediate: true,
      handler(value) {
        if (value !== undefined) {
          if (value && 'series' in value) {
            this.dataOption = value;
          }
          this.loaded = true;
        }
      },
    },
    request() {
      this.reloadData();
    },
    requestParams: {
      deep: true,
      handler() {
        this.reloadData();
      },
    },
    range: {
      deep: true,
      handler() {
        this.reloadData();
      },
    },
  },
  methods: {
    reloadData() {
      if (!this.request || !this.range) return;
      this.request(...this.requestParams, this.range, (option) => {
            if (option.series === null) {
              option = {
                title: {
                  text: this.name,
                  textStyle: {
                    fontWeight: 'normal',
                  },
                  left: 0,
                },
                backgroundColor: '#fff',
                legend: {
                  data: [],
                  align: 'left',
                  left: 'left',
                  icon: 'pin',
                  backgroundColor: 'transparent',
                  bottom: true,
                  type: 'scroll',
                },
                tooltip: {
                  show: true,
                  trigger: 'axis',
                },
                xAxis: {
                  type: 'time',
                  data: [],
                  axisLabel: {
                    showMinLabel: false,
                  },
                },
                yAxis: [
                  {
                    type: 'value',
                    name: 'Mbps',
                    nameLocation: 'start',
                    axisLabel: {
                      showMinLabel: false,
                    },
                  },
                  {
                    type: 'value',
                    name: 'Time',
                    nameLocation: 'start',
                    axisLabel: {
                      showMinLabel: false,
                    },
                  },
                ],
                series: [],
                grid: {
                  left: 50,
                  top: 35,
                  right: 35,
                  bottom: 45,
                },
                toolbox: {
                  right: 10,
                  feature: {
                    dataZoom: {
                      show: true,
                      yAxisIndex: 'none',
                    },
                    restore: {
                      show: true,
                    },
                    saveAsImage: {
                      show: true,
                    },
                  },
                },
              };
            }
            this.dataOption = option;
            this.loading = false;
            this.loaded = true;
          },
      );
    },
  },
}
;
</script>

<style scoped>
.x-chart,
.x-chart > div {
  width: 100%;
  height: 100%;
}
</style>