<template>
  <div class="x-checkbox">
    <v-checkbox :input-value="value" :label="label" hide-details="auto" @change="$emit('input', $event)"/>
  </div>
</template>

<script>
export default {
  name: 'XCheckbox',
  props: {
    value: Boolean,
    label: String,
  },
};
</script>

<style scoped>
.x-checkbox >>> .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>