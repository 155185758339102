<template>
  <v-dialog content-class="projectDialog" v-model="projectSwitch" fullscreen>
    <fields
      :result="projectInfo"
      @close-dialog="projectSwitch = false"
    ></fields>
  </v-dialog>
</template>
<script>
import fields from "@/commonComponents/dynamic/fields.vue";

export default {
  components: {
    fields,
  },
  data: function () {
    return {
      projectSwitch: false,
      projectInfo: null,
    };
  },

  watch: {
    projectSwitch(newVal) {
      if (!newVal) {
        this.projectInfo = {};
      }
    },
  },
  props: ["userInfo", "result", "menu"],
  methods: {
    switchProjectRequest() {
      let options = {
        function: "getSwitchProject",
        requestType: "ajax",
        hideButtons: "true",
      };
      let sentURI = "serve.php?f=administration&f2=projectAdministration";
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: sentURI,
        data: options,
      })
          .then(function (response) {
          caller.projectSwitch = true;
          caller.projectInfo = response.data.result;
          console.log(response.data.result);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    },
  },
};
</script>
