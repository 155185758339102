<template>
  <div class="breakablecontainer">
    <div>
      <span>
        <searchInput v-model="filter" @input="$emit('input', filter)"></searchInput>
      </span>
    </div>
    <v-row>
      <v-col v-for="(item,sublist) in submenuListGroups" :key="sublist">
        <v-list dense>
          <div v-for="(index)  in getKeysInRange(sublist)" :key="index">
            <v-list-item v-show="filterItem(submenuList[index])">
              <v-list-item-content>
                <v-list-item-title>
                  <menuLink
                    v-if="typeof submenuList[index]['children']=='undefined'"
                    :menuItem="submenuList[index]"
                    :disabled="disabled"
                     :index="index"
                  >
                   <template v-if="typeof submenuList[index].features.inactiveTooltip!='undefined'">
                      <v-tooltip  top max-width=400>
                          <template v-slot:activator="{ on, attrs }">
                             <span  v-bind="attrs" v-on="on">
                                 <span v-html="markedPassage(submenuList[index])"></span>
                             </span>
                          </template>
                          <span>{{ submenuList[index].features.inactiveTooltip }}</span>
                      </v-tooltip>
                     </template>
                     <template v-else>
                         <span v-html="markedPassage(submenuList[index])"></span>
                     </template>
                  </menuLink>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import menuLink from "@/commonComponents/menuLink.vue";
import searchInput from "@/commonComponents/searchInput.vue";
export default {
  data: function() {
    return { filter: "", limit: 8 };
  },
  components: { menuLink, searchInput },
  props: ["submenuList","disabled"],
  computed: {
    submenuListGroups: function() {
      let groups = Math.ceil(Object.keys(this.submenuList).length / this.limit);
     // console.log(groups);
      return groups;
    }
  },
  methods: {
    filterItem(item) {
      let name = this.getName(item.name);

      const regex = RegExp(this.filter, "i");

      return regex.test(name);
    },

    markedPassage(menuItem) {
      let name = this.getName(menuItem.name);
      var regex = new RegExp(this.filter, "gi");
      var response = name.replace(regex, function(str) {
        return "<mark>" + str + "</mark>";
      });
      return response;
    },
    getKeysInRange: function(from) {
      let objectKeys= Object.keys(this.submenuList).slice(
        from * this.limit,
        (from + 1) * this.limit
      );
     // console.log(from,this.submenuList,objectKeys);
      return objectKeys;
    }
  }
};
</script>
<style scoped>
.breakablecontainer {
  width: 300px;
}
</style>