<template>
  <v-card-title class="headline-box-headline primary" primary-title>
    <v-row class="headline-box-headline white--text w100" no-gutters>
      <v-col style="white-space: nowrap;"><span class="dialogTitle">{{title}}</span></v-col>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-row>
  </v-card-title>
</template>
<script>
export default {
  props: ["title"],
};
</script>