<template>
  <div class="x-switch">
    <span v-if="frontLabel" class="front-label" v-text="label"/>
    <v-switch ref="input" :color="color" :disabled="disabled" :input-value="dataValue" class="x-input"
              hide-details="auto" @change="emitInput"/>
    <span v-if="!frontLabel" v-text="label"/>
    <HelpButton :id="id"/>
  </div>
</template>

<script>
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  name: 'XSwitch',
  components: {HelpButton},
  props: {
    value: [String, Boolean],
    disabled: Boolean,
    label: String,
    frontLabel: Boolean,
    color: String,
    id: String,
  },
  data() {
    return {
      dataValue: false,
      type: 'boolean',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.type = typeof this.value;
        if (this.type === 'string') {
          if (this.value === '') this.type = 'emptyString';
          else if (this.value === '0' || this.value === '1') this.type = 'numberString';
        }
        this.dataValue = this.parseBoolean(value);
      },
    },
  },
  methods: {
    emitInput(value) {
      switch (this.type) {
        case 'string':
          value = value ? 'true' : 'false';
          break;
        case 'emptyString':
          value = value ? 'true' : '';
          break;
        case 'numberString':
          value = value ? '1' : '0';
          break;
        case 'number':
          value = value ? 1 : 0;
          break;
      }
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
.x-switch {
  display: flex;
  align-items: center;
}

.front-label {
  margin-right: 7px;
}

.x-switch >>> .help-button {
  margin-top: 2px;
}

.x-input.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>