<template>
  <div>
    <v-footer padless fixed class="font-weight-small" :height="$vuetify.breakpoint.smAndUp?'25':'50'">
      <template v-if="$vuetify.breakpoint.smAndUp">
      <template
        v-if="
          result != null &&
          typeof result.sdt != 'undefined' &&
          result.sdt != '' &&
          result.sdt == 1
        "
      >
        <v-switch
          dense
          flat
          v-model="$vuetify.theme.dark"
          title="Theme Switch"
          style="position: absolute; left: 10px"
        ></v-switch>
     <!--   <span v-if="typeof result.gitV != 'undefined'">
          <v-icon class="gBranch">mdi-git</v-icon>
          <span class="gbPosition" title="Git Version">{{ result.gitV }}</span>
        </span>-->
        <span class="pl-12"></span>
      </template>
      <projectmate
        v-if="userInfo != null && userInfo.loggedIn == true"
      ></projectmate>
      </template>
      <v-spacer></v-spacer>
      <v-row no-gutters style="max-width: 590px" justify="center">
        <v-col style="min-width: 310px; max-width: 310px">
          {{ language.footer.copyrights }} {{ new Date().getFullYear() }} |
          {{ language.footer.companyName }}
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          style="min-width: 155px; max-width: 155px"
        >
          <template>|   <a href="https://www.acctopus.com/" target="_blank">{{ language.footer.companyWebsite }}</a></template>
        </v-col>
        <v-col style="min-width: 120px; max-width: 120px">
          <template v-if="$vuetify.breakpoint.smAndUp">| </template>
          <a href="https://www.acctopus.com/legal/" target="_blank">Legal</a>
          |

          <a href="https://www.acctopus.com/legal/" target="_blank">Contact</a>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-footer>
  </div>
</template>



<script>
import projectmate from "@/components/legacy/projectmate.vue";
//import { notifyMe } from "@/js/desktopNotify.js";

// import snackbar from "@/components/snackbar.vue";
export default {
  components: {
    projectmate,
    // snackbar
  },
  props: ["userInfo", "result"],
  /*     created () {
          this.getDesktopNotification(this);
     },
       methods:{
          getDesktopNotification:function(caller){
            caller.frameworkAxiosRequest({
              method: "POST",
              url: "serve.php?f=administration&f2=userSelfService&f3=myAccount",
              dataType: "json",
              data: {
                requestType: "ajax",
                function:"getPageSetup"
              },
            })
              .then(function (response) {
                //console.log(response);
                if(response.data.result!='undefined' && response.data.result.json!='undefined') {
                  var jsonVal = response.data.result.json;
                  let notification = 0;
                  let frequency = 0;
                  if (jsonVal != null) {
                    notification = jsonVal['notification'];
                    frequency = jsonVal["frequency"];
                  }
                  if (notification == 1) {
                    if (frequency < 5) {
                      console.log("Nootfy 1");//no need!
                    } else {
                      // frequency='3000'; // For test purpose
                      setInterval(function () {
                        caller.getRunningStatus(caller);
                        var runningTC = window.tc;
                        // console.log(runningTC);
                        console.log(window.tc);
                        // var runningTC = 5;
                        notifyMe(runningTC);
                      }, frequency);
                    }
                  }
                }
              })
              .catch(function (response) {
                console.log(response);
              });
          },
          getRunningStatus: function (caller) {
            var options = {
              'function': 'getCurrentRunningCase',
              'requestType': 'ajax',
            };
            caller.frameworkAxiosRequest({
              method: "POST",
              url: "serve.php?f=administration&f2=userSelfService&f3=myAccount",
              responseType: 'json',
              data: options,
            })
              .then(response => {
                //console.log(response.data.result.runningCase);
                window.tc= response.data.result.runningCase;
                //  return response.data.result;
              })
              .catch(error => {
                console.log(error);
              });
          },
        }*/
};
</script>
<!--
{/if}
<footer style="text-align:center;" class="footer">
<span class="fileExist" style="display: none;">{$showFile}</span>
<span class="process" style="display: none;">{$process}</span>
<span class="isNewUser" style="display: none;">{$isNewUser}</span>
<span class="newUserSession" style="display: none;">{$newUserSession}</span>
<span class="runningTestCase" style="display: none;"></span>
<span class="pageName" style="display: none;">{{pageName}}</span>
<span class="login" style="display: none;">{$login}</span>
<span class="callPage" style="display: none;">{$callPage}</span>
<span class="qID" style="display: none;">{$qID}</span>
<span class="pID" style="display: none;">{$pID}</span>
<span class="hash" style="display: none;">{$hash}</span>
{*<span class="super" style="display: none;">{if !empty($super)}{$super}{/if}</span>*}
<span class="switchMiniValue" style="display: none;">{if !empty($miniMode)}{$miniMode}{/if}</span>
<span class="switchModeValue" style="display: none;">{if !empty($switchMode)}{$switchMode}{/if}</span>
<span style="font-size: 18px;vertical-align: middle"> &copy;</span>  {$year}{language("acctopusAddress")}
</footer>
</html></template>-->
