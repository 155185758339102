<template>
  <div>
    <x-dialog
        :buttons="buttons"
        :persistent="modified"
        :title="computedTitle"
        :value="value"
        :width="width"
        :unpadded="unpadded"
        @input="closeDialog"
        @click:outside="closeDialog">
      <template #dialog-content>
        <v-form ref="form" v-model="valid" :disabled="disabled">
          <slot name="dialog-content" :valid="valid"/>
        </v-form>
      </template>
    </x-dialog>
    <yes-no-dialog
        v-model="unsavedChangesDialog"
        text="You have unsaved changes."
        title="Really close?"
        width="240"
        yes-icon="mdi-close"
        @yes="$emit('input', false)"/>
  </div>
</template>

<script>
import XDialog from '@/components/basic/XDialog';
import YesNoDialog from '@/components/extended/YesNoDialog';

export default {
  name: 'FormDialog',
  components: {
    YesNoDialog,
    XDialog,
  },
  props: {
    value: Boolean,
    title: String,
    item: Object,
    itemId: Number,
    itemName: String,
    width: {
      Number,
      String,
    },
    customValid: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    unpadded: Boolean,
  },
  data() {
    return {
      buttons: [
        {
          text: 'Save',
          icon: 'mdi-content-save',
          color: 'save',
          textColor: 'white',
          disabled: true,
          click: this.save,
        },
        {
          text: 'Cancel',
          icon: 'mdi-cancel',
          color: 'secondary',
          textColor: 'white',
          click: this.closeDialog,
        },
      ],
      valid: false,
      error: false,
      unsavedChangesDialog: false,
      modified: false,
      waitingForInput: false,
    };
  },
  watch: {
    value(value) {
      if (value) {
        this.validateIfInitialized();
        this.$emit('open');
        this.reload();
      } else {
        this.$emit('close');
      }
    },
    item: {
      deep: true,
      handler() {
        this.modified = true;
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
    },
    valid() {
      this.buttons[0].disabled = !this.combinedValid;
    },
    customValid() {
      this.buttons[0].disabled = !this.combinedValid;
    },
  },
  created() {
    this.modified = false;
  },
  computed: {
    saveDisabled() {
      return this.loading || !this.valid || !!this.error;
    },
    computedTitle() {
      if (this.title) return this.title;
      if (this.itemName) {
        if (!this.itemId) return `New ${this.itemName}`;
        if ('name' in this.item) return `Edit ${this.itemName}: ${this.item.name}`;
        return `Edit ${this.itemName}`;
      }
      return '';
    },
    combinedValid() {
      return this.valid && this.customValid;
    },
  },
  methods: {
    reload() {
      if (this.itemId === 0 || this.itemId === undefined) {
        this.error = '';
        this.$emit('reload', null);
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
        this.waitForInput();
      }
    },
    closeDialog() {
      if (!this.modified) {
        this.$emit('input', false);
      } else {
        this.unsavedChangesDialog = true;
      }
    },
    waitForInput() {
      this.$nextTick(() => {
        this.waitingForInput = true;
        this.modified = false;
      });
    },
    save() {
      this.$emit('save');
      this.$emit('input', false);
    },
    validateIfInitialized() {
      if (this.$refs.form) {
        this.$refs.form.validate();
      } else {
        this.$nextTick(this.validateIfInitialized);
      }
    },
  },
};
</script>

<style scoped>

</style>