<template>
  <div class="buttonCollectionContainer" style="width: 100%">
    <div v-if="divider == true">
      <v-divider></v-divider>
    </div>
    <v-row :class="customClass" style no-gutters v-bind="rowAttr">
      <v-col
        v-if="versioninput == true"
        cols="12"
        md="4"
        class="mr-2"
        style="height: 40px"
      >
        <primaryTextfield
          label="Commit Message"
          type="text"
          name="c_change_message"
          :fieldAttrInput="{
            placeholder: language.componentLabel.phVersion,
            class: 'versionChangeMessage tPlus',
          }"
          :fieldAttributes="{ value: versioningValue }"
          :templateContent="result"
          :hideDetails="true"
        ></primaryTextfield>
      </v-col>
      <v-col class="d-flex" v-bind="colAttr">
        <input
          type="hidden"
          name="function"
          :value="functionName"
          key="function"
        />
        <div
          v-for="(functionElement, name) in result['function']"
          v-bind:key="name"
        >
          <dynamicComponent
            v-if="functionElement['template']"
            :type="functionElement['template']"
            :result="result"
            v-on:click.stop="setFunctionName(functionElement['function'])"
            v-on="$listeners"
            :additional="{
              isValid: isValid,
              calcDisabled: !isValid || loading,
              loading:
                loading &&
                isValid &&
                functionElement['function'] == functionName,

              localLoading: loading,
              functionElement: functionElement,
              id: getId(functionElement.function),
            }"
          ></dynamicComponent>

          <primaryButton
            v-else-if="functionElement['selfLink']"
            :label="functionElement['functionName']"
            :icon="iconsMapping[functionElement['selfLinkName']]"
            :color="colorSelect(functionElement)"
            :class="
              typeof functionElement['className'] != 'undefined'
                ? functionElement['className']
                : ''
            "
            elevation="1"
            v-on:click.stop="redirectURL(functionElement['selfLink'])"
            :attributesArray="{
              id: getId(functionElement.selfLinkName),
            }"
          ></primaryButton>

          <primaryButton
            v-else
            class="mr-2"
            :attributesArray="{
              ...functionElement['attributesArray'],
              id: getId(functionElement.function),
            }"
            type="button"
            :value="functionElement['functionName']"
            v-on:click.stop="setFunctionName(functionElement['function'])"
            :label="functionElement['functionName']"
            :icon="
              typeof functionElement['customIconName'] != 'undefined'
                ? iconsMapping[functionElement['customIconName']]
                : iconsMapping[functionElement['function']]
            "
            :color="colorSelect(functionElement)"
            :disabled="!isValid || loading"
            :loading="
              loading && isValid && functionElement['function'] == functionName
            "
            :shrink="
              typeof functionElement['shrink'] != 'undefined'
                ? functionElement['shrink']
                : ''
            "
          ></primaryButton>
        </div>
        <div
          v-for="(value, type) in result.additionalFieldsButtons"
          :key="type"
          class="mr-2 d-inline-block"
        >
          <dynamicComponent
            :type="type"
            :additional="{ buttonAttrs: value }"
          ></dynamicComponent>
        </div>
        <cancelButton
          :result="result"
          v-if="typeof result['closeButton'] != 'undefined'"
          :id="getId('cancel')"
          @confirmation-ok="closeDialog(...arguments)"
        ></cancelButton>
        <router-link
          v-if="typeof routerLink != 'undefind' && routerLink != null"
          :to="routerLink.to"
          :class="routerLink.class"
          >{{ routerLink.text }}</router-link
        >
      </v-col>
    </v-row>
  </div>
</template>
<script >
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import cancelButton from "@/commonComponents/dynamic/cancelButton.vue";

export default {
  components: {
    dynamicComponent,
    primaryButton,
    cancelButton,
    primaryTextfield,
  },
  data: function () {
    return {
      loading: false,
      functionName: "",
      iconsMapping: {
        add: "mdi-content-save",
        addAndUpdateSelected: "mdi-content-save",
        submitMultiFields: "mdi-content-save",
        edit: "mdi-content-save",
        addHSSWithoutSubscriber: "mdi-content-save",
        addLdapWithoutSubscriber: "mdi-content-save",
        newAndRun: "mdi-play-box-multiple",
        requestlogin: "mdi-login-variant",
        saveAsNew: "mdi-content-save-all",
        recoverAsNew: "mdi-content-save-all",
        editAndRun: "mdi-play-box-multiple",
        back: "mdi-step-backward",
        restart: "mdi-restart",
        verify2FA: "mdi-shield-check",
        verifyRecovery: "mdi-shield-check",
        logout: "mdi-logout-variant",
        recover: "mdi-backup-restore",
        saveTagsOnTestCases: "mdi-content-save",
        copySelectedObjects: "mdi-content-copy",
        executeTest: "mdi-play",
        passwordRequest: "mdi-send",
        clearTest: "mdi-stop",
        executeSelectedTest: "mdi-play",
        addPdf: "mdi-file-pdf-box",
        addMulti: "mdi-content-save",
        switchSave: "mdi-content-save-move",
        switchUser: "mdi-account-switch",
        editLdapWithoutSubscriber: "mdi-content-save",
        saveAsNewWithoutSubscriber: "mdi-content-save-all",
        reset: "mdi-lock",
        forgetPass: "mdi-lock-question",
        moveObjectsToProject: "mdi-folder-move",
        deleteProjectAndObj: "mdi-delete",
        uploadExploreList: "mdi-database-import",
        uploadDictionary: "mdi-database-import",
        saveConfiguredVariables: "mdi-content-save",
        register: "mdi-text-box-check",
        activationRequest: "mdi-email-send-outline",
        login: "mdi-login-variant",
        eTesting: "mdi-table-eye",
        deleteExplorer: "mdi-delete",
        deleteUserRelations: "mdi-delete",
      },
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "dialogButtonsLoading") {
        this.loading = state.dialogButtonLoading;
      }
    });
  },
  computed: {
    customClass() {
      let className = "buttonCollection mt-3";
      if (
        typeof this.result != "undefined" &&
        typeof this.result.customButtCollectionClass != "undefined"
      ) {
        className = this.result.customButtCollectionClass + " " + className;
      }
      //console.log(className);
      return className;
    },
    versioningValue() {
      let versioningValue = "";
      if (
        typeof this.result["fields"] != "undefined" &&
        typeof this.result["fields"]["c_change_message"] != "undefined" &&
        typeof this.result.valueLine.c_change_message != "undefined"
      ) {
        versioningValue = this.result.valueLine.c_change_message;
      }
      return versioningValue;
    },
    rowAttr() {
      let attrs = {};
      if (
        typeof this.result["centeredButtons"] != "undefined" &&
        this.result["centeredButtons"] == true
      ) {
        attrs = { align: "center", justify: "center" };
      }
      return attrs;
    },
    colAttr() {
      let attrs = {};
      if (
        typeof this.result["centeredButtons"] != "undefined" &&
        this.result["centeredButtons"] == true
      ) {
        attrs = { sm: "8", md: "3", class: "pl-2" };
      }
      return attrs;
    },
    // a computed getter
    versioninput: function () {
      // console.log(this.result["editFunction"]);
      // console.log(this.result["changeMessage"]);
      if (
        this.isset(this.result["editFunction"]) &&
        this.result["editFunction"] === true &&
        this.isset(this.result["changeMessage"]) &&
        this.result["changeMessage"] == true
      ) {
        return true;
      } else {
        return false;
      }
    },
    versionValue: function () {
      if (!this.empty(this.result.valueLine.c_change_message)) {
        return this.result.valueLine.c_change_message;
      } else {
        return "false";
      }
    },
  },
  watch: {
    isValid(newVal) {
      if (!newVal) {
        this.loading = false;
      }
    },
  },
  props: {
    result: { type: Object },
    isValid: { type: Boolean, default: true },
    divider: { type: Boolean, default: true },
    routerLink: { type: Object, default: null },
  },
  methods: {
    redirectURL(source) {
      return this.$router.push(source);
    },
    getId(name) {
      let id;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.additionlFormAtts != "undefined" &&
        typeof this.result.additionlFormAtts["data-className"] != "undefined" &&
        typeof name != "undefined"
      ) {
        id = this.result.additionlFormAtts["data-className"] + "_" + name;
      } else if (
        typeof this.result != "undefined" &&
        typeof this.result.contentID != "undefined" &&
        typeof name != "undefined"
      ) {
        id = this.result.contentID + "_" + name;
      }
      return id;
    },
    colorSelect(attr) {
      return typeof attr["color"] != "undefined" ? attr["color"] : "save";
    },
    closeDialog() {
      //  this.$store.state.closeDialog = !confirmation;
      // console.log(this.result);
      this.$store.commit("closeDialog", this.result.dialogRef);
      //console.log("field", confirmation);
    },
    setFunctionName(functionName) {
      this.loading = true;
      this.functionName = functionName;
       this.$emit("submitted", functionName);
    },
  },
};
</script>

