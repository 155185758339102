<template>
  <v-row v-bind="fieldAttrRow" no-gutters>
    <fieldName
        v-if="templateContent.enableFieldNameCol === true"
        :fieldAttributes="fieldAttributes">
    </fieldName>

    <v-col>
      <fieldsValues
          :fieldAttributes="fieldAttributes"
          :field="field"
          :templateContent="templateContent"
          v-on="$listeners"
          :checkReadOnly="checkReadOnly()"
          :value="value"
          :valueLine="valueLine">
      </fieldsValues>
    </v-col>
  </v-row>
  <!-- <v-spacer></v-spacer> -->
</template>
<script>
import fieldName from "./fieldName.vue";
import fieldsValues from "@/commonComponents/fieldsValues.vue";

export default {
  name: "fieldsTds",
  components: {
    fieldName,
    fieldsValues,
  },
  props: {
    fieldAttributes: Object,
    field: String,
    templateContent: {
      default() {
        return {};
      },
    },
    fieldAttrRow: {},
    value: {},
    valueLine: {},
  },
  methods: {
    checkReadOnly() {
      if (this.fieldAttributes.readOnly) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>