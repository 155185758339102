<template>
  <v-card-text :style="style">
    <slot></slot>
  </v-card-text>
</template>
<script>

export default {
  props: ['result'],
  data: function () {
    return {
      height: 0,
    };
  },
  created() {
    this.onResize();
  },
  computed: {
    style() {
      let style = 'max-height:' + this.height + 'px;overflow-y: auto;';
      if (this.fullscreen) {
        style += 'min-height:' + this.height + 'px;';
      }

      return style;
    },
    fullscreen: function () {
      let fullscreen = false;
      if (
          typeof this.result.json != 'undefined' &&
          typeof this.result.json.dialogFormat != 'undefined' &&
          this.result.json.dialogFormat.maximized === true
      ) {
        fullscreen = true;
      }
      return fullscreen;
    },
    customForm: function () {
      return this.result.fields.customForm.type;
    },
  },
  methods: {
    onResize() {
      let subtract = 190;
      if (this.result['dialogFullHeight']) {
        subtract = 0;
      } else if (this.fullscreen) {
        subtract = 138;
      }
      this.height = window.innerHeight - subtract;
    },
  },
  // watch: {
  //   windowHeight(newHeight, oldHeight) {
  //     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
  //   },
  // },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>