<template>
  <span>
    <span>{{fieldAttributes["name"]}}</span>
    <span v-if="requireTest" class="redAsterisk">*</span>
  </span>
</template>
<script>
import { getfieldAttrInput } from "../js/helper.js";
export default {
  data() {
    return {};
  },
  computed: {
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    requireTest() {
      if (
        typeof this.fieldAttrInput != "undefined" &&
        typeof this.fieldAttrInput["class"] == "string" &&
        this.fieldAttrInput["class"].indexOf("required") > -1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  props: {
    fieldAttributes: {
      type: Object,
      /*default: function() {
        return {};
      }*/
    },
  },
};
</script>
