<template>
  <yes-no-dialog :value="value" title="Really delete?" :text="text" @input="$emit('input', $event)"
                 @yes="$emit('yes')" @no="$emit('no')" width="250"/>
</template>

<script>
import YesNoDialog from '@/components/extended/YesNoDialog';

export default {
  name: 'ReallyDeleteDialog',
  components: {YesNoDialog},
  props: {
    value: Boolean,
    itemName: String,
  },
  computed: {
    text() {
      return this.itemName ? `This will delete '${this.itemName}'.` : 'Please confirm.';
    },
  },
};
</script>