var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.features.useOnMobile || !_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"d-flex"},[(typeof _vm.menuItem.template != 'undefined')?_c('dynamicComponent',{key:_vm.menuItem.template,attrs:{"type":_vm.menuItem.template,"result":{},"additional":{ row: _vm.menuItem }}}):_vm._e(),(this.features.granted !== 'invisible')?_c('div',[(
        _vm.menuItem.attr &&
        _vm.menuItem.attr.href &&
        _vm.disabled !== true &&
        this.features.granted === 'active'
      )?_c('div',{staticStyle:{"margin-top":"2px"}},[(!_vm.checkSameCondition)?_c('router-link',{attrs:{"to":_vm.menuItem.attr.href}},[_vm._t("default",function(){return [_c('span',{class:_vm.dotted ? 'dotted' :'',attrs:{"id":_vm.id}},[_vm._v(" "+_vm._s(_vm.getName(_vm.menuItem["name"]))+" ")])]})],2):_c('a',{attrs:{"href":_vm.menuItem.attr.href},on:{"click":function($event){$event.stopPropagation();return _vm.checkSameLink.apply(null, arguments)}}},[_vm._t("default",function(){return [_c('span',{class:_vm.dotted ? 'dotted' :'',attrs:{"id":_vm.id}},[_vm._v(" "+_vm._s(_vm.getName(_vm.menuItem["name"]))+" ")])]})],2),(typeof _vm.menuItem.features!='undefined' &&
                  typeof _vm.menuItem.features.showBeta!='undefined' &&
                  _vm.menuItem.features.showBeta===true &&
                  _vm.checkDate)?_c('label',{staticClass:"green--text",staticStyle:{"position":"absolute","top":"1px"},attrs:{"title":"New Feature!"}},[_vm._v("Beta")]):_vm._e()],1):(
        this.features.granted === 'inactive' &&
        typeof this.features.inactiveLink != 'undefined'
      )?_c('div',{staticStyle:{"margin-top":"2px"}},[_c('a',{staticClass:"grey--text text--darken-1",attrs:{"href":this.features.inactiveLink}},[_vm._t("default",function(){return [_c('span',{class:_vm.dotted ? 'dotted' :'',attrs:{"id":_vm.id}},[(typeof _vm.features.inactiveTooltip != 'undefined')?_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getName(_vm.menuItem["name"])))])]}}],null,false,2994239566)},[_c('span',[_vm._v(_vm._s(_vm.features.inactiveTooltip))])]):_vm._e()],1)]})],2)]):_c('div',{class:_vm.dotted ? 'dotted' :'',attrs:{"color":"gray","id":_vm.id}},[_vm._v(" "+_vm._s(_vm.getName(_vm.menuItem["name"]))+" ")])]):_vm._e(),_c('div',[_c('menuFeatureIcon',{attrs:{"menuItem":_vm.menuItem}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }