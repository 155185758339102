import Vue from 'vue';
import fiberNet from '@/cfg/fiberNet.json';

Array.prototype.concatUnique = (a) => {
    return this.concat(a.filter((item) => this.indexOf(item) < 0));
};

Vue.mixin({
    methods: {
        deepCopy(source, target) {
            if (typeof target === 'undefined') target = this.getDefaultPrimitiveValue(source);
            if (!source) {
                target = source;
                return;
            }

            if (typeof source !== 'object') {
                target = source;
                return target;
            }
            if (Array.isArray(source) && !Array.isArray(target)) {
                console.error('Source and target not compatible.\nSource: array\nTarget: object.');
                return;
            }
            if (!Array.isArray(source) && Array.isArray(target)) {
                console.error('Source and target not compatible.\nSource: object\nTarget: array.');
            }

            if (Array.isArray(source)) {
                for (let i = 0; i < source.length; i++) {
                    if (typeof source[i] === 'object') {
                        if (!(source[i] instanceof Date)) target.push(this.deepCopy(source[i]));
                        else target.push(new Date(source[i]));
                    } else {
                        target.push(source[i]);
                    }
                }
            } else {
                for (let key in source) {
                    if (typeof source[key] === 'object') {
                        if (!source[key]) target[key] = source[key];
                        else if (!target[key]) target[key] = this.getDefaultPrimitiveValue(source[key]);
                        if (!(source[key] instanceof Date)) this.deepCopy(source[key], target[key]);
                        else target[key] = new Date(source[key]);
                    } else if (typeof source[key] === 'function') {
                        target[key] = source[key].bind(target);
                    } else {
                        target[key] = source[key];
                    }
                }
            }

            return target;
        },
        deepEquals(a, b) {
            const ok = Object.keys, ta = typeof a, tb = typeof b;
            return a && b && ta === 'object' && ta === tb ? (ok(a).length === ok(b).length &&
                ok(a).every(key => this.deepEquals(a[key], b[key]))) : (a === b);
        },
        deepClear(target) {
            if (typeof target !== 'object') {
                console.error('Only arrays and objects can be deep-cleared.');
                return;
            }

            if (Array.isArray(target)) {
                for (let i = 0; i < target.length; i++) {
                    if (typeof target[i] === 'object') {
                        this.deepClear(target[i]);
                    } else {
                        target[i] = this.getDefaultPrimitiveValue(target[i]);
                    }
                }
            } else {
                for (let key in target) {
                    if (typeof target[key] === 'object') {
                        this.deepClear(target[key]);
                    } else {
                        target[key] = this.getDefaultPrimitiveValue(target[key]);
                    }
                }
            }

            return target;
        },
        deepReplace(data, searchValue, replaceValue, caseSensitive) {
            if (typeof data === 'undefined') data = this.getDefaultPrimitiveValue(data);

            if (typeof data === 'string') {
                return data.replace(searchValue, replaceValue);
            } else if (typeof data !== 'object') {
                console.error('Only arrays, objects and strings can be deep-replaced.');
                return;
            }

            const stack = [];
            stack.push(data);

            while (stack.length) {
                const current = stack.pop();
                if (Array.isArray(current)) {
                    for (let i = 0; i < current.length; i++) {
                        if (typeof current[i] === 'object') {
                            stack.push(current[i]);
                        } else if (typeof current[i] === 'string') {
                            if (!caseSensitive) current[i] = current[i].replace(searchValue, replaceValue);
                            else {
                                const startIndex = current[i].toLowerCase().indexOf(searchValue.toLowerCase());
                                if (startIndex >= 0) {
                                    current[i] = current[i].substring(0, startIndex) + replaceValue +
                                        current[i].substring(startIndex + searchValue.length);
                                }
                            }
                        }
                    }
                } else {
                    for (let key in current) {
                        if (typeof current[key] === 'object') {
                            stack.push(current[key]);
                        } else if (typeof current[key] === 'string') {
                            if (!caseSensitive) current[key] = current[key].replace(searchValue, replaceValue);
                            else {
                                const startIndex = current[key].toLowerCase().indexOf(searchValue.toLowerCase());
                                if (startIndex >= 0) {
                                    current[key] = current[key].substring(0, startIndex) + replaceValue +
                                        current[key].substring(startIndex + searchValue.length);
                                }
                            }
                        }

                        if (!caseSensitive) {
                            const startIndex = key.indexOf(searchValue);
                            if (startIndex >= 0) {
                                delete Object.assign(current,
                                    {[key.replace(searchValue, replaceValue)]: current[key]})[key];
                            }
                        } else {
                            const startIndex = key.toLowerCase().indexOf(searchValue.toLowerCase());
                            if (startIndex >= 0) {
                                delete Object.assign(current, {
                                    [key.substring(0, startIndex) + replaceValue +
                                    key.substring(startIndex + searchValue.length)]: current[key],
                                })[key];
                            }
                        }
                    }
                }
            }

            return data;
        },
        getDefaultPrimitiveValue(value, defaultObjectValue = {}) {
            switch (typeof value) {
                case 'undefined':
                    return undefined;
                case 'object':
                    if (Array.isArray(value)) {
                        return [];
                    }
                    return defaultObjectValue;
                case 'boolean':
                    return false;
                case 'number':
                    return 0;
                case 'string':
                    return '';
            }
            return undefined;
        },
        getRequiredRule() {
            return v => !!v || this.getRequiredMessage();
        },
        getRequiredMessage() {
            return 'This field is required.';
        },
        setDefaultValues(target, defaultValue) {
            let changed = false;
            if (target === null && defaultValue && typeof defaultValue === 'object' && !Array.isArray(defaultValue)) {
                target = {};
                changed = true;
            }
            if (defaultValue) {
                if (typeof defaultValue === 'object') {
                    if (!Array.isArray(defaultValue)) {
                        for (const [key, value] of Object.entries(defaultValue)) {
                            if (typeof value === 'object') {
                                if (!target[key]) target[key] = this.getDefaultPrimitiveValue(value);
                                const newChanged = this.setDefaultValues(target[key], value);
                                changed = changed ? changed : newChanged;
                            } else if ((target[key] === undefined || target[key] === null) && target[key] !== value) {
                                target[key] = value;
                                changed = true;
                            }
                        }
                    } else {
                        for (let i = 0; i < defaultValue.length; i++) {
                            if (typeof defaultValue[i] === 'object') {
                                changed = changed ? changed : this.setDefaultValues(target[i], defaultValue[i]);
                            } else {
                                target[i] = defaultValue[i];
                                changed = true;
                            }
                        }
                    }
                }
            } else {
                if (!Array.isArray(target)) {
                    for (const [key, value] of Object.entries(target)) {
                        if (typeof value === 'object') {
                            if (!Array.isArray(value)) {
                                changed = this.setDefaultValues(target[key]);
                            } else {
                                target[key] = [];
                                changed = true;
                            }
                        } else {
                            target[key] = this.getDefaultPrimitiveValue(value);
                            if (target[key] !== value) changed = true;
                        }
                    }
                }
            }
            return changed;
        },
        parseBoolean(value) {
            if (typeof value === 'boolean') return value;
            if (typeof value === 'string') {
                value = value.toLowerCase();
                return value === 'true' || value === '1';
            }
            if (typeof value === 'number') return value !== 0;
            if (value === undefined || value === null) return 0;
        },
        nullEmptyObjects(value) {
            if (typeof value === 'object') {
                if (!value) {
                    return null;
                } else if (!Array.isArray(value)) {
                    const entries = Object.entries(value);
                    if (!entries.length) return null;
                    for (const [k, v] of entries) {
                        value[k] = this.nullEmptyObjects(v);
                    }
                } else {
                    for (let i = 0; i < value.length; i++) {
                        value[i] = this.nullEmptyObjects(value[i]);
                    }
                }
            }
            return value;
        },
        objectToArray(object, keyName, valueName = 'value') {
            const array = [];
            for (const [key, value] of Object.entries(object)) {
                const newObject = {};
                newObject[keyName] = key;
                newObject[valueName] = value;
                array.push(newObject);
            }
            return array;
        },
        arrayToObject(array, keyName, valueName = 'value') {
            const object = {};
            for (const item of array) {
                object[item[keyName]] = item[valueName];
            }
            return object;
        },
        dateToIsoDateString(d) {
            if (!d) return null;

            const year = d.getFullYear().toString().padStart(4, '0');
            const month = (d.getMonth() + 1).toString().padStart(2, '0');
            const day = d.getDate().toString().padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        isoDateStringToDate(s) {
            if (!/^\d{4}-\d{2}-\d{2}$/.test(s)) return null;

            const year = parseInt(s.substring(0, 4));
            const month = parseInt(s.substring(5, 7)) - 1;
            const day = parseInt(s.substring(8, 10));

            const result = new Date();
            result.setYear(year);
            result.setMonth(month);
            result.setDate(day);
            result.setHours(0);
            result.setMinutes(0);
            result.setSeconds(0);
            result.setMilliseconds(0);

            return result;
        },
        dateToDateTimeString(d) {
            if (!d) return '';

            const year = d.getFullYear().toString().padStart(4, '0');
            const month = (d.getMonth() + 1).toString().padStart(2, '0');
            const day = d.getDate().toString().padStart(2, '0');
            const hour = d.getHours().toString().padStart(2, '0');
            const minute = d.getMinutes().toString().padStart(2, '0');

            const date = `${year}-${month}-${day}`;
            const time = `${hour}:${minute}`;

            return `${date} ${time}`;
        },
        unixToDateTimeString(unix) {
            return this.dateToDateTimeString(new Date(unix * 1000));
        },
        dateTimeStringToDate(s) {
            if (!/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/.test(s)) return null;

            const year = parseInt(s.substring(0, 4));
            const month = parseInt(s.substring(5, 7)) - 1;
            const day = parseInt(s.substring(8, 10));
            const hour = parseInt(s.substring(11, 13));
            const minute = parseInt(s.substring(14, 16));

            const result = new Date();
            result.setYear(year);
            result.setMonth(month);
            result.setDate(day);
            result.setHours(hour);
            result.setMinutes(minute);
            result.setSeconds(0);
            result.setMilliseconds(0);

            return result;
        },
        secondsToDuration(seconds) {
            let duration = '';
            if (seconds >= 86400) duration += `${parseInt(seconds / 86400)}d`;
            if (seconds % 86400 >= 3600) duration += `${parseInt(seconds % 86400 / 3600)}h`;
            if (seconds % 3600 >= 60) duration += `${parseInt(seconds % 3600 / 60)}m`;
            if (seconds % 60 > 0 || seconds / 60 < 1) duration += `${parseInt(seconds % 60)}s`;
            return duration;
        },
        durationToSeconds(duration) {
            const now = Math.trunc(new Date().getTime() / 1000);
            duration = duration.replace('now', `${now}s`);
            let currentNumber = '';
            let seconds = 0;
            let sign = 1;
            for (const letter of duration) {
                if (!isNaN(parseInt(letter))) {
                    currentNumber += letter;
                } else {
                    let number = parseInt(currentNumber);
                    switch (letter) {
                        case 'd':
                        case 'h':
                        case 'm':
                        case 's':
                            switch (letter) {
                                case 'd':
                                    number *= 86400;
                                    break;
                                case 'h':
                                    number *= 3600;
                                    break;
                                case 'm':
                                    number *= 60;
                                    break;
                            }
                            seconds += sign * number;
                            break;
                        case '+':
                            sign = 1;
                            break;
                        case '-':
                            sign = -1;
                            break;
                        case ' ':
                            continue;
                        default:
                            return NaN;
                    }
                    currentNumber = '';
                }
            }

            return seconds;
        },
        toColonDuration(seconds) {
            const hoursString = parseInt(seconds % 86400 / 3600).toString().padStart(2, '0');
            const minutesString = parseInt(seconds % 3600 / 60).toString().padStart(2, '0');
            const secondsString = parseInt(seconds % 60).toString().padStart(2, '0');
            return `${hoursString}:${minutesString}:${secondsString}`;
        },
        replaceWithFibernet(data) {
            if (this.$store.state.fiberNet) {
                for (const replacement of fiberNet) {
                    data = this.deepReplace(data, replacement.searchValue, replacement.replaceValue,
                        replacement.caseSensitive);
                }
            }
            return data;
        },
        pxOrNot(property, value) {
            if (!value) return '';
            if (typeof value === 'string' && value.includes('%') || value.includes(
                'px')) return `${property}: ${value}`;
            return `${property}: ${value}px`;
        },
        getUnixFromToByRange(range) {
            if (range.seconds) {
                return {
                    from: Math.trunc(Date.now() / 1000 - range.seconds),
                    to: Math.trunc(Date.now() / 1000),
                };
            }
            return {
                from: Math.trunc(range.from.getTime() / 1000),
                to: Math.trunc(range.to.getTime() / 1000),
            };
        },
        downloadStringAsFile(filename, text) {
            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        getInt(value, defaultValue = 0) {
            switch (typeof value) {
                case 'number':
                    return Math.trunc(value);
                case 'string':
                    return parseInt(value);
                default:
                    return defaultValue;
            }
        },
        showNotification(message, length = 2000) {
            this.$store.commit('notification', {
                text: message,
                length: length,
            });
        },
        updateProjectState(then) {
            if(this.isPublicUrl()) return;
            this.services.user.getUserData((userData) => {
                this.$store.commit('setUserData', userData);
                if (then) then();
            });
        },
        getGetParameter(name, defaultValue) {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            if (params[name] !== null) return params[name];
            return defaultValue;
        },
    },
});