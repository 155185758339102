<template>
  <span>
    <primaryButton
      label="Cancel"
      icon="mdi-cancel"
      @click="confirmCloseDialog()"
      type="button"
      color="secondary"
      :attributesArray="{ id: id }"
    ></primaryButton>
    <confirmationDialog
      :value="showConfirmationDialog"
      title="Notice"
      :message="language.componentLabel.msgChangesMade"
      :key="showConfirmationDialog"
      @confirmation-ok="checkCloseDialog(...arguments)"
      maxWidth="350"
    ></confirmationDialog>
  </span>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
export default {
  props: ["result", "id"],
  created: function () {
    this.$store.state.closeDialog = true;
    window.addEventListener("beforeunload", this.onBeforeUnload);

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setFieldDataMutated") {
        this.mutated = state.fieldDataMutated;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  },
  data: function () {
    return { showConfirmationDialog: false, mutated: false };
  },
  components: { confirmationDialog, primaryButton },
  // watch: {
  //   result() {
  //     console.log("result changed");
  //   },
  // },
  methods: {
    onBeforeUnload(e) {
      if (this.mutated) {
        e.preventDefault();
        e.returnValue = "";
        return;
      }

      delete e["returnValue"];
    },
    checkCloseDialog(confirm) {
      if (confirm) {
        this.$emit("confirmation-ok", true);
      } else {
        this.showConfirmationDialog = false;
      }
    },
    confirmCloseDialog: function () {
      if (!this.confirmBeforeClose()) {
        this.$emit("confirmation-ok", true);
      }
    },
    confirmBeforeClose: function () {
      if (this.mutated && this.result.confirmCancel) {
        this.showConfirmationDialog = true;
        return true;
      } else {
        return false;
      }
    },
  },
};

// function closeDialog($dialog) {
//   // use only this function to close for dialogs
//   var $closable = $dialog.closest(".closable");
//   if ($closable.hasClass("ui-dialog-content")) {
//     $closable.dialog("close");
//     $closable.dialog("destroy");
//     $closable.removeAttr("data-callerid");
//     $closable.empty();

//     $closable.removeClass("focused");
//     // $dialog.empty();
//     $closable.unbind("resize");
//   }
//   //$(this).dialog('destroy').remove();
//   restoreDialogWindowStructure($dialog);
//   $(".testCases.ui-accordion.ui-sortable").accordion("refresh");
// }
</script>