<template>
  <div ref="testStatusButtons" class="test-status-buttons">
    <TotalAndSplit
        :value="value" :splits="splits" @split-click="handleSplitClick"/>
  </div>
</template>

<script>
import TotalAndSplit from '@/components/extended/TotalAndSplit';

export default {
  name: 'TestStatusButtons',
  components: {
    TotalAndSplit,
  },
  props: {
    value: Object,
    total: Number,
    groupLink: String,
  },
  data() {
    return {
      splits: {
        total: {
          clickParams: [-1],
        },
        pending: {
          color: 'pendingGray',
          clickParams: [0],
        },
        running: {
          color: 'testStatusRunning',
          clickParams: [1],
        },
        ok: {
          color: 'ok',
          clickParams: [2],
        },
        warning: {
          color: 'testStatusWarning',
          clickParams: [3],
        },
        error: {
          color: 'testStatusError',
          clickParams: [4],
        },
        aborted: {
          color: 'testStatusAborted',
          clickParams: [5],
        },
        runtimeWarning: {
          color: 'testStatusRuntimeWarning',
          clickParams: [7],
        },
        runtimeError: {
          color: 'testStatusRuntimeError',
          clickParams: [8],
        },
      },
    };
  },
  methods: {
    handleSplitClick(key, value, ...clickParams) {
      this.$emit('split-click', key, value, ...clickParams);
    },
  },
};
</script>

<style scoped>
.test-status-buttons {
  width: 100%;
}
</style>