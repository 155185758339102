<template>
  <div v-if="value" class="x-dialog">
    <v-dialog
        v-if="!loading"
        :height="height"
        :persistent="persistent"
        :value="value"
        :width="width"
        @input="$emit('input', $event)"
        @click:outside="$emit('click:outside', $event)">
      <v-card :style="height ? `height: ${height}px;` : ''">
        <div v-if="this.$slots['dialog-top'] || title" class="dialog-top">
          <div v-if="!this.$slots['dialog-top']" class="dialog-top-bar white--text">
            <div v-if="title" class="dialog-title" v-text="title"/>
            <div class="corner-buttons">
              <x-btn
                  v-for="(cornerButton, i) in computedCornerButtons"
                  :key="i"
                  :icon="cornerButton.icon"
                  text-color="white"
                  @click="cornerButton.click"/>
            </div>
          </div>
          <slot name="dialog-top"/>
        </div>
        <div
            :class="!unpadded ? `dialog-content ${$slots['dialog-bottom'] || buttons ? '' : 'dialog-content-bottom-padding'}` : ''"
            :style="contentHeight ? `height: ${contentHeight}px; overflow-y: auto;` : ''">
          <div v-if="text" v-text="text"/>
          <slot name="dialog-content"/>
        </div>
        <div v-if="$slots['dialog-bottom'] || buttons" class="dialog-bottom">
          <v-btn
              v-for="button of buttons"
              :key="button.name"
              :color="button.color"
              :disabled="typeof button.disabled === 'function' ? button.disabled() : button.disabled"
              :style="`color: ${button.textColor}`"
              @click="button.click">
            <v-icon v-if="button.icon" dark left>{{ button.icon }}</v-icon>
            {{ button.text }}
          </v-btn>
          <slot name="dialog-bottom"/>
        </div>
      </v-card>
    </v-dialog>
    <LoadingDialog v-if="loading" :value="loading"/>
    <HelpButton :id="id"/>
  </div>
</template>

<script>
import XBtn from '@/components/basic/XBtn';
import LoadingDialog from '@/components/basic/LoadingDialog';
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  name: 'XDialog',
  components: {
    LoadingDialog,
    XBtn,
    HelpButton
  },
  props: {
    value: Boolean,
    title: String,
    text: String,
    buttons: Array,
    cornerButtons: Array,
    width: {
      type: [Number, String],
      default: 400,
    },
    height: {
      Number,
      String,
    },
    contentHeight: {
      Number,
      String,
    },
    persistent: Boolean,
    unpadded: Boolean,
    loading: Boolean,
    id: String,
  },
  computed: {
    computedCornerButtons() {
      if (this.cornerButtons) return this.cornerButtons;
      return [
        {
          icon: 'mdi-close',
          click: this.closeDialog,
        },
      ];
    },
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
  },
};
</script>

<style scoped>
.dialog-top {
  font-size: 24px;
  font-weight: 400;
}

.dialog-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 400;
  background-color: var(--v-primary-base);
}

.dialog-title {
  padding: 10px 20px;
}

.corner-buttons {
  margin-right: 10px;
}

.dialog-content {
  padding: 20px 20px 0 20px;
}

.dialog-content-bottom-padding {
  padding-bottom: 20px;
}

.dialog-bottom {
  display: flex;
  gap: 10px;
  padding: 20px;
}

.button {
  flex: 1 1 50%;
}

.x-dialog {
  display: flex;
}
.x-dialog >>> .help-button {
  margin-top: 2px;
}
</style>