<template>
  <v-dialog :value="value" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Please stand by
        <v-progress-linear class="mb-0" color="white" indeterminate/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoadingDialog',
  props: {
    value: Boolean,
  },
};
</script>