<template>
  <v-dialog
          v-model="show"
          content-class="fit-content"
          scrollable
  >
    <v-card>
      <dialogTitle :title="title">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </dialogTitle>
      <v-card-text class="pb-12 pt-6" style="overflow:auto; ">
        <div v-if="type=='notice'">{{content}}</div>
        <div v-else-if="type=='image'" class="mt-3">
          <img :src="content" />
        </div>
      </v-card-text>
      <!--       <v-card-actions>
                       <v-btn color="primary" @click.stop="show=false">Close</v-btn>
      </v-card-actions>-->
    </v-card>
  </v-dialog>
</template>
<script>
import dialogTitle from "@/commonComponents/dialogTitle.vue";
export default {
  components: { dialogTitle },
  props: ["visible", "title", "content", "type"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>
<style scoped>
.v-dialog{
    overflow-y:unset!important;
}
</style>