<template>
  <div class="x-radio-group">
    <div v-if="row" :class="row ? 'x-radio-group-label-row' : ''">{{ label }}</div>
    <v-radio-group
        :column="column"
        :row="row"
        :rules="computedRules"
        :value="value"
        hide-details="auto"
        @change="$emit('input', $event)">
      <x-radio v-for="(item, i) in items" :key="i" :label="item[dataItemText]" :value="item[dataItemValue]"/>
    </v-radio-group>
    <HelpButton :id="id"/>
  </div>
</template>

<script>
import XRadio from '@/components/basic/XRadio';
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  name: 'XRadioGroup',
  components: {XRadio,HelpButton},
  props: {
    value: String,
    rules: Array,
    required: Boolean,
    items: Array,
    itemValue: String,
    itemText: String,
    row: Boolean,
    column: Boolean,
    label: String,
    id: String,
  },
  data() {
    return {
      dataItemValue: '',
      dataItemText: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.required && !value) {
          this.$emit('input', this.items[0].value);
        }
      },
    },
    items: {
      immediate: true,
      handler(value) {
        if (!value || !value.length) return;
        if (Array.isArray(value[0])) {
          this.dataItemValue = 0;
          this.dataItemText = 1;
        } else if (typeof value[0] === 'object' && value[0].value !== undefined && value[0].text !== undefined) {
          this.dataItemValue = 'value';
          this.dataItemText = 'text';
        } else {
          this.dataItemValue = this.itemValue;
          this.dataItemText = this.itemText;
        }
      },
    },
  },
  computed: {
    computedRules() {
      let rules = this.rules;
      if (!rules) rules = [];
      if (this.required) {
        rules.unshift(this.getRequiredRule());
      }
      return rules;
    },
  },
};
</script>

<style scoped>
.x-radio-group {
  display: flex;
  gap: 16px;
}

.x-radio-group-label-row {
  margin-top: 4px;
}

.x-radio-group >>> .v-input--selection-controls {
  margin-top: 0;
}

.x-radio-group >>> .v-input--radio-group__input {
  gap: 10px;
}

.x-radio-group >>> .help-button {
  margin-top: 2px;
}
</style>