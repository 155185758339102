<template>
  <label v-bind="fieldAttrInput">
    <div v-if="typeof fieldAttributes['value'] !='undefined'" class="">
      <div v-if="typeof fieldAttributes['value']=='object'">
        <div v-for="(line ,id) in fieldAttributes['value']" :key="id">
          {{line}}
          <input type="hidden" :name="field+'[]'" :value="getLabelValue(line,id)" />
        </div>
      </div>

      <div v-else>
        <div
          v-if="!empty(fieldAttributes['mask']) &&( fieldAttributes['mask']=='associatedOption' || fieldAttributes['mask']=='tableOnlyAssociatedOption')"
        >
          <div v-if="!empty(fieldAttributes['associatedOption'][fieldAttributes['value']])">
            <div
              v-if="typeof fieldAttributes['associatedOption'][fieldAttributes['value']]=='object'"
            >{{(fieldAttributes['associatedOption'][fieldAttributes['value']]["name"])}}</div>
            <div v-else>{{fieldAttributes['associatedOption'][fieldAttributes['value']]}}</div>
          </div>
          <div v-else>{{fieldAttributes['value']}}</div>
        </div>
        <div v-else>{{fieldAttributes['value']}}</div>

        <input
          v-if="!empty(fieldAttributes['mask']) &&( fieldAttributes['mask']=='associatedOption' || fieldAttributes['mask']=='tableOnlyAssociatedOption') && isset(fieldAttributes['associatedOption'][fieldAttributes['value']]) && Array.isArray(fieldAttributes['associatedOption'][fieldAttributes['value']])"
          type="hidden"
          name="field"
          v-bind="fieldAttributes['associatedOption'][fieldAttributes['value']]"
          :value="fieldAttributes['value']"
        />

        <input v-else type="hidden" :name="field" :value="fieldAttributes['value']" />
      </div>
    </div>
  </label>
</template>
            <script>
  export default {
    props: {
      fieldAttrInput: Object,
      fieldAttributes: Object,
      type: String,
      field: String,
      value: { default: "" }
    },
    methods: {
      getLabelValue(line, id) {
        let labelValue = line;
        if (typeof this.fieldAttributes["associated"] != "undefined") {
          labelValue = id;
        }
        return labelValue;
      }
    }
  };
</script>
