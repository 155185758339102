<template>
  <v-tooltip top>
    <template v-slot:activator="{on, attrs}">
      <img
          :style="`max-height: ${size}px; max-width: ${size}px;`"
          :src="image.image"
          class="explorer-hw-brand"
          v-bind="attrs"
          v-on="on"
          alt="Explorer hardware brand"/>
    </template>
    {{ image.tooltip }}
  </v-tooltip>
</template>

<script>
import unknown from '@/assets/explorer/placeholder.png';
import tinker_64_48 from '@/assets/explorer/Tinker64_128.png';
import tinker_64_128 from '@/assets/explorer/Tinker64_128.png';
import raspberry_32_48 from '@/assets/explorer/Raspberry32_48.png';
import raspberry_32_128 from '@/assets/explorer/Raspberry32_128.png';
import raspberry_64_48 from '@/assets/explorer/Raspberry64_48.png';
import raspberry_64_128 from '@/assets/explorer/Raspberry64_128.png';
import docker_64_48 from '@/assets/explorer/Docker64_48.png';
import docker_64_128 from '@/assets/explorer/Docker64_128.png';
import intel_64_48 from '@/assets/explorer/Intel64_48.png';
import intel_64_128 from '@/assets/explorer/Intel64_128.png';

export default {
  name: 'ExplorerHwBrand',
  props: {
    degustImageVersion: String,
    size: {
      Number,
      String,
    },
  },
  computed: {
    image() {
      let result = {
        image: unknown,
        tooltip: 'unknown',
      };
      if (this.degustImageVersion) {
        if (/tinker.+(64|\d{4}-\d{2}-\d{2})/.test(this.degustImageVersion)) {
          result = {
            image: this.size === 24 ? tinker_64_48 : tinker_64_128,
            tooltip: 'Tinker OS 64 bit',
          };
        } else if (/Vodafone 2020|explorer-base|VF-QP/.test(this.degustImageVersion)
            && !this.degustImageVersion.includes('64')) {
          result = {
            image: this.size === 24 ? raspberry_32_48 : raspberry_32_128,
            tooltip: 'Raspbian OS 32 bit',
          };
        } else if (/degust.+64/.test(this.degustImageVersion)) {
          result = {
            image: this.size === 24 ? raspberry_64_48 : raspberry_64_128,
            tooltip: 'Raspbian OS 64 bit',
          };
        }
        // --- Docker and Intel are not defined yet ---
        else if (this.degustImageVersion.includes('Docker')) {
          result = {
            image: this.size === 24 ? docker_64_48 : docker_64_128,
            tooltip: 'Docker Container',
          };
        } else if (this.degustImageVersion.includes('Intel')) {
          result = {
            image: this.size === 24 ? intel_64_48 : intel_64_128,
            tooltip: 'Intel 64 bit',
          };
        }
        // --------------------------------------------
      }
      return result;
    },
  },
};
</script>

<style scoped>

</style>