<template>
  <DashboardWidget
      :value="value"
      :range="range"
      class="standalone-widget"
      :style="`width: ${width}px; height: ${height}px;`"
      public
      :uuid="uuid"
      :project-api-key="projectApiKey"/>
</template>

<script>
import DashboardWidget from '@/components/specific/DashboardWidget';

export default {
  name: 'StandaloneWidget',
  components: {
    DashboardWidget,
  },
  data() {
    return {
      value: {
        name: this.getGetParameter('title'),
        type: 'echart',
        chartId: this.$route.fullPath.match(/(?<=\/charts\/)[a-z0-9-]+/)[0],
        filter: this.getGetParameter('filter', '[]'),
      },
      range: this.getRange(),
      width: this.getGetParameter('width', 640),
      height: this.getGetParameter('height', 480),
      uuid: this.getGetParameter('id', ''),
      projectApiKey: this.getGetParameter('project-api-key', ''),
    };
  },
  methods: {
    getRange() {
      const from = this.getGetParameter('from', 'now-1d');
      const to = this.getGetParameter('to', 'now');

      const unixFrom = this.durationToSeconds(from);
      const unixTo = this.durationToSeconds(to);

      return {
        from: new Date(from * 1000),
        to: new Date(to * 1000),
        seconds: unixTo - unixFrom,
      };
    },
  },
};
</script>

<style scoped>

</style>