<template>
  <div :id="id" class="checkbox-field">
    <v-checkbox
      v-bind="{ class: fieldAttrInput.class }"
      v-model="selected"
      :label="label"
      outlined
      dense
      :disabled="localDisabled"
      :hide-details="hideDetails"
      :rules="rules"
      :error-messages="errors"
    ></v-checkbox>
    <input v-if="addInput" type="hidden" :value="value" :name="field" />
  <HelpButton :id="id"/>
  </div>
</template>
<script>
import { getFieldId } from "@/js/helper.js";
import { getCheckboxRules } from "@/js/validators.js";
import HelpButton from "@/components/basic/HelpButton.vue";
export default {
  components: {HelpButton},
  props: {
    fieldAttrInput: Object,
    fieldAttributes: Object,
    field: String,
    value: [Number, String, Boolean],
    disabled: { type: Boolean, default: false },
    hideDetails: { default: false },
    addInput: { default: true },
    result: {},
  },
  data: function () {
    return {
      showPassword: false,
      errors: [],
    };
  },
  created() {
    let errors = [];
    this.rules.forEach((rule) => {
      let text = rule(this.value);
      if (typeof text == "string") {
        errors.push(text);
      }
    });
    this.errors = errors;
  },
  watch: {
    fieldAttrInput: function () {
      this.errors = [];
    },
    value: function () {
      this.errors = [];
    },
  },
  computed: {
    id() {
      return getFieldId(this.fieldAttrInput, this.result, this.field);
    },
    rules() {
      return getCheckboxRules(
        this.fieldAttrInput,
        this.result,
        this.field,
        this
      );
    },
    label() {
      let label = "";
      if (
        typeof this.fieldAttributes.mainTitle == "undefined" ||
        this.fieldAttributes.mainTitle == true
      ) {
        label = this.fieldAttributes.name;
      }
      return label;
    },
    localDisabled() {
      return (
        (typeof this.result != "undefined" &&
          typeof this.result.disableAllInputs != "undefined" &&
          this.result.disableAllInputs == true) ||
        this.disabled ||
        (typeof this.fieldAttrInput != "undefined" &&
          typeof this.fieldAttrInput.disabled != "undefined" &&
          this.fieldAttrInput.disabled == "disabled")
      );
    },
    selected: {
      get: function () {
        if (
          typeof this.fieldAttributes.trueFalse != "undefined" &&
          this.fieldAttributes.trueFalse == true
        ) {
          return this.value;
        } else {
          let selected = false;
          if (this.value == "1") {
            selected = true;
          }
          return selected;
        }
      },
      set(val) {
        this.errors = [];
        if (
          typeof this.fieldAttributes.trueFalse != "undefined" &&
          this.fieldAttributes.trueFalse == true
        ) {
          this.$emit("input", val);
        } else {
          let selected = "0";
          if (val) {
            selected = "1";
          }
          this.$emit("input", selected);
        }
      },
    },
  },
  methods: { getCheckboxRules: getCheckboxRules, getFieldId: getFieldId },
};
</script>
<style scoped>
.checkbox-field {
  display: flex;
}
.checkbox-field >>> .help-button {
  margin-top: 2px;
}
</style>