<template>
  <v-system-bar
      v-if="userInfo != null && userInfo.loggedIn == true" class="upperHeader white--text" color="upperHeader">
    <v-spacer></v-spacer>
    <XSwitch
        v-if="typeof result.sdt != 'undefined' && result.sdt == 1 && menu != null && Object.keys(menu).length > 0 && result.process==''"
        :value="$store.state.fiberNet"
        @input="$store.commit('toggleFibernet')"/>
    <template
        v-if="
        typeof result.process != 'undefined' && result.process == 'userView'
      "><span
        class="mr-2 pr-2 mb-1" style="min-width: 150px;background-color:#ff9900;border-radius: 2px;text-align:center;">This is User View</span>
      <v-icon
          style="cursor: pointer;font-size: 18px;"
          color="#00ffbc"
          title="Switch back to Super Admin!"
          @click="switchAdminView()"
          class="mr-2">
        mdi-account-supervisor-circle
      </v-icon>
      |
    </template>
    <template
        v-if="
        typeof result.sdt != 'undefined' &&
        result.sdt == 1 &&
        menu != null &&
        Object.keys(menu).length > 0 &&
        result.process==''
      ">
      <v-menu offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn text x-small class="white--text" v-on="on">
            <v-icon color="white">mdi-cog-transfer-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <megaMenu
                v-if="typeof mainMenuItem != 'undefined'"
                :submenuList="mainMenuItem['children']"
                :enableTabs="false"
                :tabIndex="0"
                :mainMenuItem="mainMenuItem"
                :menuItem="{}"></megaMenu>
          </v-card-text>
        </v-card>
      </v-menu>
      |
    </template>
    <!-- <notifications></notifications>|-->

    <v-dialog
        content-class="projectDialog" v-model="projectSwitch" max-width="490">
      <fields
          :result="projectInfo" @close-dialog="projectSwitch = false"></fields>
    </v-dialog>

    <v-alert
        :value="oneProject"
        type="error"
        elevation="0"
        dense
        light
        style="z-index: 100; position: absolute; right: 3px; top: 30px"
        transition="scroll-y-transition">{{ language.componentLabel.oneProjectMessage }}
    </v-alert>
    <div v-if="!empty(userInfo.orgName) && result.disableMenu != true">
      <v-btn
          v-if="isset(userInfo.orgName)"
          :title="upperHeaderLanguage.label_org_name"
          @click="switchProjectRequest()"
          text
          x-small
          class="white--text">
        <template v-if="$vuetify.breakpoint.xs">
          {{ trimString(userInfo.orgName) }}
        </template>
        <template v-else>
          Organization: {{ trimString(userInfo.orgName) }}
        </template>
      </v-btn>
      |
    </div>
    <div v-if="!empty(userInfo.projectName) && result.disableMenu != true">
      <v-btn
          :title="upperHeaderLanguage.label_project"
          @click="switchProjectRequest()"
          text
          x-small
          class="white--text"
          id="projectSwitcher">
        <template v-if="$vuetify.breakpoint.xs">
          {{ trimString(userInfo.projectName) }}
        </template>
        <template v-else>
          {{ languagePack('topMiniHeader', 'project_label') }}: {{ trimString(userInfo.projectName) }}
        </template>
      </v-btn>
      |
    </div>
    <v-btn
        :title="logoutTitle" href="?f=logout" text x-small class="white--text" id="logout">{{
        languagePack('topMiniHeader', 'logout_label')
      }}
      <template v-if="$vuetify.breakpoint.smAndUp">
        {{ trimString(userInfo.firstname) }}
      </template>
    </v-btn>

    |
    <v-btn
        :title="upperHeaderLanguage.label_service_desk"
        href="https://service.acctopus.com"
        target="_blank"
        text
        x-small
        class="white--text">
      <span class="hidden-sm-and-down pr-1">get</span>
      support
    </v-btn>
  </v-system-bar>
</template>
<script>
import megaMenu from '@/commonComponents/megaMenu.vue';
//import notifications from "@/components/notificationBox.vue";
import fields from '@/commonComponents/dynamic/fields.vue';
import XSwitch from '@/components/basic/XSwitch';

export default {
  components: {
    XSwitch,
    megaMenu,
    // notifications,
    fields,
  },
  data: function () {
    return {
      upperHeaderLanguage: this.userInfo.upperHeaderLanguage,
      showNotificationTray: false,
      projectSwitch: false,
      projectInfo: null,
      oneProject: false,
    };
  },
  created() {
    this.$root.$refs.upperHeader = this;
  },
  computed: {
    logoutTitle: function () {
      return 'Logout ' + this.trimString(this.userInfo.firstname) + '';
      // return "Logout [User Name]";
    },
    mainMenuItem: function () {
      let systemAdministration = {};
      if (this.menu != null) {
        systemAdministration = this.menu.systemAdministration;
      }
      return systemAdministration;
    },
  },
  watch: {
    projectSwitch(newVal) {
      if (!newVal) {
        this.projectInfo = {};
      }
    },
  },
  props: ['userInfo', 'result', 'menu'],
  methods: {
    trimString(value) {
      if (this.$vuetify.breakpoint.xs) {
        if (value.length > 7) {
          value = value.substring(0, 7);
          value += '.';
        }
      } else {
        if (value.length > 50) {
          value = value.substring(0, 50);
          value += '...';
        }
      }
      return value;
    },
    switchAdminView() {
      this.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=administration&f2=superUserAdministration',
        dataType: 'json',
        data: {
          function: 'userViewBack',
          requestType: 'ajax',
        },
      })
          .then(function () {
            window.location.href = '?f=administration&f2=superUserAdministration';
          })
          .catch((error) => {
            console.log(error);
          });
    },
    showTray: function () {
      this.showNotificationTray = !this.showNotificationTray;
    },

    switchProjectRequest() {
      let options = {
        function: 'getSwitchProject',
        requestType: 'ajax',
        hideButtons: 'true',
      };
      let sentURI = 'serve.php?f=administration&f2=projectAdministration';
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: sentURI,
        data: options,
      })
          .then(function (response) {
            //caller.projectSwitch = true;
            //caller.projectInfo = response.data.result;
            //console.log(response.data.result.fields.projects.associatedOption);
            /*  let projectCount = Object.keys(
              response.data.result.fields.projects.associatedOption
            ).length;

            // console.log(projectCount);
            if (projectCount > 1) {*/
            caller.projectSwitch = true;
            caller.projectInfo = response.data.result;
            console.log(response.data.result);
            /*  } else {
              caller.oneProject = true;
              setTimeout(() => {
                caller.oneProject = false;
              }, 5000);
            }*/
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
    },
    switchProject(/*field, value, text, object*/) {
      /* console.log(field, value, text, object);

      var options = {
        function: "switchProjectAjax",
        requestType: "ajax",
        n_id: value,
      };
      var sentURI = "serve.php?f=administration&f2=projectAdministration";
      //showLoading();
      let caller = this;
      this.frameworkAxiosRequest({
        method: "post",
        url: sentURI,
        data: options,
      })
        .then(function (response) {
          caller.projectSwitch = false;
          //console.log(response);
          if (typeof response.data.result["redirect"] != "undefined") {
            window.location.href = response.data.result["redirect"];
          } else {
            location.reload();
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });*/
    },
  },
};
</script>
<style>
.projectDialog {
  position: absolute;
  top: 0;
  right: 0;
}
</style>