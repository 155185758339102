<template>
    <div class="ml-3">
<!--
        <v-divider></v-divider>
-->
         <v-tabs v-model="tab" vertical >
            <div class="mt-7 childMenuHeader mb-3">{{languagePack('menu_title_category')}}</div>
            <v-tab
                    v-for="(menuItem ,tabIndex,count) in submenuList"
                    :key="tabIndex"
                    @mouseover="changeTab(count,menuItem)"
                    @mouseout="resetTab(count)"
                    class="justify-start"
            ><div>{{languagePack(menuItem.name)}}</div></v-tab>

            <v-tabs-items v-model="tab">
                <!-- <v-tab-item v-for="(menuItem ,tabIndex) in submenuList" :key="tabIndex" transition="slide-y-transition" reverse-transition="slide-y-transition"> -->
                <v-tab-item v-for="(menuItem ,tabIndex) in submenuList" :key="tabIndex">
                    <v-card flat>
                        <v-card-text>
                            <!-- {{menuItem.attr["data-cat-child"]}} -->
                            <v-row style="height:450px">
                                <v-divider
                                           vertical></v-divider>

                                <v-col>
                                    <div class="primary--text childMenuHeader mb-3">{{languagePack('menu_title_recently_used')}}</div>
                                     <menuList :submenuList="menuItem.attr['data-recent']" :disabled="menuItem.attr.disabled"></menuList>
                                </v-col>
                                <v-divider class="mx-4" vertical></v-divider>
                                <v-col>
                                    <div class="primary--text childMenuHeader mb-4">{{availableTitle}}</div>
                                    <breakableListFilter :submenuList="menuItem.attr['data-cat-child']"  :disabled="menuItem.attr.disabled"></breakableListFilter>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </div>

</template>
<script>
  import menuList from "@/commonComponents/menuList.vue";
  import breakableListFilter from "@/commonComponents/breakableListFilter.vue";

  export default {
  components: { menuList, breakableListFilter },
  props: ["submenuList"],

  data: function() {
    return {
      tab: 0,
      activeTab: 0,
      activeCalled: false,
      availableTitle: ""
    };
  },
    mounted() {
      this.availableTitle = this.languagePack('menu_title_available_systems');
  },
  methods: {
    changeTab: function(tabIndex,menuItem) {
        this.activeCalled = setTimeout(
        function() {
          //  if (this.activeTab == tabIndex) {
          this.tab = tabIndex;
          if (typeof menuItem.attr['data-cat'] != "undefined"){
            if (menuItem.attr['data-cat'] == "Protocols") {
              this.availableTitle = this.languagePack('menu_title_available_protocol');
            }else if(menuItem.attr['data-cat'] == "web_app"){
              this.availableTitle = this.languagePack('menu_title_available_application');
            }else{
              this.availableTitle = this.languagePack('menu_title_available_systems');
            }
         }
          // }
        }.bind(this),
        100
      );
    },
    resetTab: function() {
      clearTimeout(this.activeCalled);
    },
  }
};
</script>