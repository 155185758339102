<template>
  <div :id="id + '_container'" class="bool-switch">
    <v-switch
      v-model="localValue"
      :label="fieldAttributes.name"
      :rules="rules"
      v-bind="fieldAttrInput"
      :disabled="disabled"
      :id="id"
      :error-messages="errors"
    ></v-switch>
    <HelpButton :id="id"/>
  </div>
</template>
<script>
import { getFieldId } from "@/js/helper.js";
import HelpButton from "@/components/basic/HelpButton.vue";
export default {
  components: { HelpButton },
  props: [
    "fieldAttributes",
    "fieldAttrInput",
    "field",
    "rules",
    "templateContent",
    "value",
  ],
  data() {
    return {
      errors: [],
      //checkError:typeof this.fieldAttributes.class!="undefined" && this.fieldAttributes.class=="required"?true:false
    };
  },
  created() {
    this.getErrors();
  },
  watch: {
    rules() {
      this.getErrors();
    },
  },
  methods: {
    getErrors() {
      let errors = [];
      if(this.rules){
        this.rules.forEach((text) => {
          if (typeof text == "string") {
            errors.push(text);
          }
        });
      }
      this.errors = errors;
    },
  },
  computed: {
    id() {
      return getFieldId(this.fieldAttrInput, this.templateContent, this.field);
    },
    disabled() {
      let disabled = false;
      if (
        typeof this.templateContent != "undefined" &&
        typeof this.templateContent.disableAllInputs != "undefined" &&
        this.templateContent.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    localValue: {
      get() {
        let intValue = this.value;
        return intValue;
        // if (intValue == 0) {
        //   return false;
        // } else {
        //   return true;
        // }
      },
      set(newValue) {
        // let returnedValue = 0;
        // if (newValue == true) {
        //   returnedValue = 1;
        // }
        this.errors = [];
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
<style scoped>
.bool-switch {
  display: flex;
}
.bool-switch >>> .help-button {
  margin-top: 15px;
}
</style>