<template>
    <div class="switch-group">
        <v-row style="" class="mt-1">
            <v-col class="mt-6" :style="styleEachSwitch">{{ fieldAttributes.name }}</v-col>
            <v-col :style="styleEachSwitch" v-for="(fieldsInfo, fieldName) in fieldAttributes.fields" :key="fieldName">
                <aswitch
                        :fieldAttributes="fieldsInfo"
                        :fieldAttrInput="fieldsInfo.fieldAttrInput"
                        :field="fieldName"
                        @input="emitInput(fieldName, ...arguments)"
                        :rules="rules"
                        :value="valueLine[fieldName]"
                        :templateContent="templateContent"
                ></aswitch>
             </v-col>
        </v-row>
      <HelpButton :id="id"/>
    </div>
</template>
<script>
  import aswitch from "@/commonComponents/aswitch.vue";
  import HelpButton from "@/components/basic/HelpButton.vue";
  export default {
    props: {
      fieldAttrInput: {
        type: Object,
        default() {
          return null;
        },
      },
      fieldAttributes: Object,
      type: String,
      field: String,
      value: { default: "" },
      disabled: Boolean,
      templateContent: {},
      valueLine: {},
      id: String
    },
    created() {
      this.$nextTick(function () {
        this.$emit("revalidate");
      });
    },
    components: { aswitch, HelpButton  },
    methods: {
      emitInput(field, value) {
       // console.log("emitting field-value-changed");
        this.$emit("field-value-changed", field, value);
        this.$emit("revalidate");
      },
    },
    computed: {
      styleEachSwitch(){
         let style = "";
         if(typeof this.fieldAttributes.maxWidth!=='undefined'){
           style = "max-width:"+this.fieldAttributes.maxWidth+"px";
         }
         return style;
      },
      rules() {
        let rules = [];
        rules.push(
          this.selected.length > 0 || this.language.componentLabel.msgAtLeastOne
        );
        return rules;
      },
      selected() {
        let selected = [];
        for (let field of Object.keys(this.fieldAttributes.fields)) {
          if (this.valueLine[field] == 1) {
            selected.push(field);
          }
        }
        return selected;
      },
    },
  };
</script>
<style scoped>
.switch-group {
  display: flex;
}
.switch-group >>> .help-button {
  margin-top: 30px;
}
</style>