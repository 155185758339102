<template>
  <div v-if="!this.empty(templateContent['fields'])" no-gutters>
    <fieldsSingleRows
        v-for="field in fieldsOrder"
        :templateContent="templateContent"
        :field="field"
        :key="field"
        v-on="$listeners"
        :valueLine="valueLine"
        :isValid="isValid">
    </fieldsSingleRows>
  </div>
</template>
<script>
import fieldsSingleRows from "@/commonComponents/fieldsSingleRows.vue";

export default {
  components: {fieldsSingleRows},

  computed: {
    fieldsOrder: function () {
      let fieldsOrder = [];
      if (this.templateContent != null) {
        fieldsOrder = Object.keys(this.templateContent["fields"]);
        if (!this.empty(this.templateContent["fieldsOrder"])) {
          fieldsOrder = this.templateContent["fieldsOrder"];
          if (!fieldsOrder.includes("n_id_key")) {
            fieldsOrder.push("n_id_key");
          }
          if (
              !fieldsOrder.includes("n_hist_id") &&
              typeof this.templateContent["fields"]["n_hist_id"] != "undefined"
          ) {
            fieldsOrder.push("n_hist_id");
          }
          if (
              !fieldsOrder.includes("n_hist_id_key") &&
              typeof this.templateContent["fields"]["n_hist_id_key"] !=
              "undefined"
          ) {
            fieldsOrder.push("n_hist_id_key");
          }
          if (
              typeof this.templateContent.fields.updatedDialogRef !=
              "undefined" &&
              !fieldsOrder.includes("updatedDialogRef")
          ) {
            fieldsOrder.push("updatedDialogRef");
          }
          if (
              typeof this.templateContent.fields.updatedField != "undefined" &&
              !fieldsOrder.includes("updatedField")
          ) {
            fieldsOrder.push("updatedField");
          }
        }
      }
      //console.log(fieldsOrder);
      return fieldsOrder;
    },
  },
  props: ["templateContent", "valueLine", "isValid"],
};
</script>