<template>
  <div>
    <l-map
        ref="map"
        :zoom="options.zoom"
        :center="options.center"
        :roam="options.roam"
        :silent="options.silent"
        :bounds="null">
      <l-icon-default></l-icon-default>
      <l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer>
      <vue2-leaflet-markercluster :options="clusterOptions" @clusterclick="click()" @ready="ready">
        <l-marker
            v-for="(marker, i) of staggeredMarkers"
            :key="i"
            :lat-lng="latLng(marker.position.lat, marker.position.lng)"
            :icon="getIcon(marker)"
            :options="getOptions(marker)"
            @click="openExplorerDialog(marker)">
          <l-tooltip :content="marker.name"/>
        </l-marker>
      </vue2-leaflet-markercluster>
    </l-map>
    <div v-if="staggering" class="map-loading-overlay">
      <div class="map-loading-message">Gathering explorers...</div>
    </div>
    <LoadingDialog v-model="loading"/>
    <ExplorerDialog
        v-model="dialog" :explorer="explorer" @reload-table="$emit('reload-table');"/>
  </div>
</template>

<script>
import {LMap, LIconDefault, LTileLayer, LMarker, LTooltip} from 'vue2-leaflet';
import {latLng, icon, divIcon, point} from 'leaflet';
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import ExplorerDialog from '@/components/specific/ExplorerDialog';
import LoadingDialog from '@/components/basic/LoadingDialog';

const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

export default {
  name: 'DashboardMap',
  components: {
    LoadingDialog,
    ExplorerDialog,
    LMap,
    LIconDefault,
    LTileLayer,
    LMarker,
    LTooltip,
    Vue2LeafletMarkercluster,
  },
  props: {
    options: Object,
  },
  data() {
    return {
      attribution: '',
      resizeObserver: null,
      clusterOptions: {
        iconCreateFunction: (cluster) => {
          const stack = [];
          stack.push(cluster);

          const status = {
            online: 0,
            warning: 0,
            offline: 0,
          };

          let markers = cluster.getAllChildMarkers();
          const explorerCount = markers.length;

          for (const marker of markers) {
            const explorer = marker.options.explorer;
            status[explorer.status]++;
          }

          const onlinePercent = (status.online / explorerCount * 100);
          const warningPercent = (status.warning / explorerCount * 100);

          const html = `<div class="pie-chart" style="
                            background:
                              radial-gradient(
                                  circle closest-side,
                                  #333333 60%,
                                  transparent 0%
                              ),
                              conic-gradient(
                                  var(--v-ok-base) 0,
                                  var(--v-ok-base) ${onlinePercent}%,
                                  var(--v-warning-base) 0,
                                  var(--v-warning-base) ${onlinePercent + warningPercent}%,
                                  var(--v-testStatusError-base) 0,
                                  var(--v-testStatusError-base) 100%
                              );">
                            <div style="
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                line-height: 40px;
                                text-align: center;
                                color: white;
                                text-shadow: black 1px 1px 1px;
                            ">
                            ${explorerCount}
                            </div>
                        </div>`;
          return divIcon({
            html: html,
            className: 'cluster',
            iconSize: point(32, 32),
          });
        },
      },
      loading: false,
      dialog: false,
      explorer: {},
      staggeredMarkers: [],
      staggering: false,
      staggerSource: [],
      progress: 0,
      progressPerTick: 50,
      tickMs: 250,
      markerBuffers: [],
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.$refs.map.$el);
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.staggeredMarkers.length) this.staggerMarkers();
        // if (this.staggering) return;
        // this.staggerSource = this.deepCopy(value);
        // this.update = !!this.staggeredMarkers.length;
        // if (this.update) {
        //   this.staggeredMarkers = value;
        //   this.progress = this.staggeredMarkers.length;
        //   this.removeMarkers();
        // } else {
        // this.staggerMarkers();
        // }
      }
    },
  },
  methods: {
    handleResize() {
      const map = this.$refs.map;
      if (map) map.mapObject.invalidateSize();
    },
    latLng(lat, lng) {
      return latLng(lat, lng);
    },
    click: () => {
    },
    ready: () => {
    },
    getOptions(item) {
      return {
        pane: 'markerPane',
        draggable: false,
        opacity: 1,
        explorer: {
          name: item.name,
          status: item.status,
        },
        className: item.status,
      };
    },
    getIcon(item) {
      const icon = this.deepCopy(iconDefault);
      icon.options.className = `explorer-map-marker-${item.status}`;
      return icon;
    },
    openExplorerDialog(marker) {
      this.loading = true;
      this.services.explorerStatus.getExplorerByName(marker.name, (explorer) => {
        this.explorer = explorer;
        this.loading = false;
        this.dialog = true;
      });
    },
    removeMarkers() {
      this.staggering = true;

      let i = this.progress;
      while (i >= 0 && i > this.progress - Math.trunc(this.progressPerTick / 4)) {
        if (!this.options.markers.find(x => x.explorer.name === this.staggeredMarkers[i].explorer.name)) {
          this.staggeredMarkers.splice(i, 1);
        }
        i--;
      }
      this.progress = this.staggeredMarkers.length;
      if (i > 0) {
        setTimeout(this.removeMarkers, 1000);
        return;
      }
      this.staggerMarkers();
    },
    staggerMarkers() {
      this.staggering = true;
      const oldLength = this.staggeredMarkers.length;
      // if (this.update) {
      //   let i = this.progress;
      //   while (i < this.progress + this.progressPerTick && this.progress < this.options.markers.length) {
      //     const oldMarkerIndex = this.staggeredMarkers.findIndex(x => x.explorer.name === this.options.markers[i].explorer.name);
      //     this.staggeredMarkers[oldMarkerIndex] = this.options.markers[i];
      //     i++;
      //   }
      //   this.progress += i;
      //   setTimeout(this.staggerMarkers, this.tickMs);
      // } else
      if (oldLength < this.options.markers.length) {
        for (let i = oldLength; i < oldLength + this.progressPerTick && this.staggeredMarkers.length < this.options.markers.length; i++) {
          this.staggeredMarkers.push(this.options.markers[i]);
        }
        setTimeout(this.staggerMarkers, this.tickMs);
        return;
      }
      this.staggering = false;
    },
  },
};
</script>

<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>

<style>
.pie-chart {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0;
  outline: 0.5px solid black;
  border-radius: 100%;
}

.explorer-map-marker-online {
  filter: hue-rotate(260deg);
}

.explorer-map-marker-warning {
  filter: hue-rotate(200deg) brightness(1.5);
}

.explorer-map-marker-offline {
  filter: hue-rotate(160deg) saturate(1.5) contrast(2);
}

.map-loading-overlay {
  position: absolute;
  z-index: 10000;
  top: 0;
  height: 100%;
  width: 100%;
}

.map-loading-message {
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 3px 5px;
}
</style>