<template>
  <div class="explorer-statistics">
    <DateTimeRangeAndRefresh v-model="rangeAndRefresh"/>
    <div class="explorer-charts">
      <XChart
          v-for="(chart, i) of charts"
          :key="i"
          :request="chartRequest"
          :request-params="[explorerName, chart, ]"
          :range="rangeAndRefresh.range"
          :refresh="rangeAndRefresh.refresh"
          class="explorer-chart"/>
    </div>
  </div>
</template>

<script>
import DateTimeRangeAndRefresh from '@/components/basic/DateTimeRangeAndRefresh';
import XChart from '@/components/basic/XChart';

export default {
  name: 'ExplorerStatistics',
  components: {
    XChart,
    DateTimeRangeAndRefresh,
  },
  props: {
    explorerName: String,
  },
  data() {
    return {
      rangeAndRefresh: {},
      charts: ['ram', 'cpu', 'swap', 'load', 'processes', 'temperature', 'network-io', 'hdd'],
    };
  },
  methods: {
    chartRequest(explorerName, chart, range, then) {
      let from = parseInt(new Date(new Date().getTime() - 86400 * 1000).getTime() / 1000);
      let to = parseInt(new Date().getTime() / 1000);

      if (range.seconds) {
        from = parseInt(new Date().getTime() / 1000) - range.seconds;
        to = parseInt(new Date().getTime() / 1000);
      } else {
        if (range.from) {
          from = parseInt(range.from.getTime() / 1000);
        }
        if (range.to) {
          to = parseInt(range.to.getTime() / 1000);
        }
      }

      this.frameworkPostRequest('?f=configuration&f2=explorerGraphs', '', {
        src: `explorer-system/${chart}/${explorerName}`,
        from: from,
        to: to,
        requestType: 'ajax',
      }, (response) => {
        then(response.json);
      });
    },
  },
};
</script>

<style scoped>
.explorer-statistics {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.explorer-charts {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 620px;
  padding-right: 20px;
}

.explorer-chart {
  max-width: calc(50% - 10px);
  height: 180px;
}
</style>