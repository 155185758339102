<template>
  <div>
    <v-list-group
      :value="true"
      v-for="(info, i) in menu.children"
      :key="i"
      :prepend-icon="info.icon"
    >
      <template v-slot:activator>
        <v-list-item-title>{{ language[info.name] }}</v-list-item-title>
      </template>
      <div
        v-for="(subChildrenInfo, subChildIndex) in info['attr'][
          'data-cat-child'
        ]"
        :key="i + subChildIndex"
        :menuItem="subChildrenInfo"
      ></div>
      <v-list-item
        v-for="(subChildrenInfo, subChildIndex) in info['attr'][
          'data-cat-child'
        ]"
        :key="subChildIndex"
        link
      >
        <menuLink :menuItem="subChildrenInfo" :disabled="false" :index="subChildIndex"></menuLink>
      </v-list-item>
    </v-list-group>
  </div>
</template>
<script>
import menuLink from "@/commonComponents/menuLink.vue";

export default {
  components: { menuLink },
  props: ["menu", "type"],
};
</script>