<template>
  <div ref="splitButton" class="split-button">
    <div v-if="value" class="splits">
      <XBtn
          v-for="[key, value] of Object.entries(filteredValue)"
          :key="key"
          :text="!tooltips[key] ? (splits[key].text ? splits[key].text(value, key) : value) : ''"
          text-color="white"
          height="25"
          elevation="0"
          :width="columnWidths[key]"
          :tooltip="tooltips[key]"
          class="split"
          :color="getSplitColor(key)"
          :to="splits[key].to ? splits[key].to : undefined"
          @click="handleClick(key)"/>
    </div>
  </div>
</template>

<script>
import XBtn from '@/components/basic/XBtn';

export default {
  name: 'SplitButton',
  components: {XBtn},
  props: {
    value: Object,
    splits: Object,
    total: Number,
    groupLink: String,
    activeSplit: String,
  },
  data() {
    return {
      columnWidths: {},
      tooltips: {},
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.handleResize();
      },
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.$refs.splitButton);
  },
  computed: {
    filteredValue() {
      const filteredValue = {};
      for (const [key, value] of Object.entries(this.value)) {
        if (value && key in this.splits && key !== 'total') filteredValue[key] = value;
      }
      return filteredValue;
    },
    computedTotal() {
      if (this.total) return this.total;
      let total = 0;
      for (const value of Object.values(this.filteredValue)) {
        total += value;
      }
      return total;
    },
    computedActiveSplit() {
      if (this.activeSplit !== undefined) return this.activeSplit;
      return this.dataActiveSplit;
    },
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        const splitButton = this.$refs.splitButton;
        if (!splitButton) return;
        const splitButtonWidth = splitButton.clientWidth;
        const filteredValueLength = Object.keys(this.filteredValue).length;
        let reduction = 3 * (filteredValueLength - 1) / filteredValueLength;
        let correctedWidths = Array(filteredValueLength);
        const columnWidths = {};
        while (correctedWidths.length) {
          correctedWidths = [];
          for (const [key, value] of Object.entries(this.filteredValue)) {
            if (columnWidths[key] === 4) continue;
            let width = value / this.computedTotal * splitButtonWidth - reduction;
            if (width < 4) {
              if (width < 0) width = 0;
              correctedWidths.push(key);
              width = 4;
            }
            columnWidths[key] = width;
          }
          if (correctedWidths.length) {
            let totalWidth = 0;
            for (const [key, value] of Object.entries(this.filteredValue)) {
              if (columnWidths[key] !== 4) totalWidth += value / this.computedTotal * splitButtonWidth;
              else totalWidth += 4;
            }
            totalWidth += 3 * (filteredValueLength - 1);
            reduction = Math.abs(splitButtonWidth - totalWidth)
                / Object.values(columnWidths).filter(x => x > 4).length;
          }
        }

        const tooltips = {};
        for (const [key, value] of Object.entries(this.filteredValue)) {
          this.columnWidths[key] = `${columnWidths[key].toFixed(3)}px`;
          const text = this.splits[key].text ? this.splits[key].text(value, key).toString() :
              this.value[key].toString();
          if (columnWidths[key] >= 9 * text.length) {
            tooltips[key] = '';
          } else {
            tooltips[key] = text;
          }
        }

        this.columnWidths = columnWidths;
        this.tooltips = tooltips;
      });
    },
    handleClick(key) {
      this.dataActiveSplit = key;
      this.$emit('update:active-split', key);
      this.$emit('split-click', key, this.value[key], ...this.splits[key].clickParams);
      if (this.splits[key].click) this.splits[key].click(key, this.value[key], ...this.splits[key].clickParams);
    },
    getSplitColor(key) {
      if (this.computedActiveSplit === 'total' || key === this.computedActiveSplit) return this.splits[key].color;
      return `${this.splits[key].color} lighten-3`;
    },
  },
};
</script>

<style scoped>
.splits {
  width: 100%;
  display: flex;
  gap: 3px;
}

.split >>> .v-btn:not(.v-btn--round).v-size--default {
  width: 100%;
  min-width: 0;
  padding: 0;
  font-size: 12px;
  border-radius: 0;
}

.split:first-child:last-child >>> .v-btn:not(.v-btn--round).v-size--default {
  border-radius: 4px 4px 4px 4px;
}

.split:first-child >>> .v-btn:not(.v-btn--round).v-size--default {
  border-radius: 4px 0 0 4px;
}

.split:last-child >>> .v-btn:not(.v-btn--round).v-size--default {
  border-radius: 0 4px 4px 0;
}

.split >>> .v-btn:not(.v-btn--round).v-size--default > span {
  text-align: center;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
</style>