import Vue from 'vue';

Vue.mixin({
    methods: {
        getRequest(url, params, then, errorHandler) {
            if (url.indexOf('://') === -1) url = `http://${url}`;
            const headers = {};
            this.addAuthorizationHeader(headers).then((accessToken) => {
                if (!accessToken) return;
                this.axios.get(url, {
                    params: {...params},
                    headers: headers,
                }).then((response) => {
                    this.handleResponse(response, then);
                }).catch((error) => {
                    this.handleError(error, errorHandler);
                });
            });
        },
        publicGetRequest(url, params, then, errorHandler) {
            if (url.indexOf('://') === -1) url = `http://${url}`;
            this.axios.get(url, {
                params: {...params},
            }).then((response) => {
                this.handleResponse(response, then);
            }).catch((error) => {
                if (errorHandler) errorHandler(error);
                let data = error.response.data;
                error = data.error;
                if (data.message) error += `: ${data.message}`;
                console.error(error);
            });
        },
        frameworkGetRequest(url, func, params, then) {
            params = this.nullEmptyObjects(params);
            const headers = {};
            this.addAuthorizationHeader(headers).then((accessToken) => {
                if (!accessToken) return;
                this.axios.get(this.getServeUrl(url), {
                    params: {
                        function: func,
                        ajax: true, ...params,
                    },
                    headers: headers,
                    withCredentials: true,
                }).then((response) => this.handleResponse(response, then));
            });
        },
        frameworkLegacyGetRequest(functions, then, sendAccessToken = true) {
            const headers = {};
            if (sendAccessToken) {
                this.addAuthorizationHeader(headers).then((accessToken) => {
                    if (!accessToken) return;
                    this.axios.get('serve.php', {
                        params: {
                            f: functions[0],
                            f2: functions[1],
                            f3: functions[2],
                            f4: functions[3],
                        },
                        headers: headers,
                        withCredentials: true,
                    }).then(then);
                });
            } else {
                this.axios.get('serve.php', {
                    params: {
                        f: functions[0],
                        f2: functions[1],
                        f3: functions[2],
                        f4: functions[3],
                    },
                    headers: headers,
                    withCredentials: true,
                }).then(then);
            }
        },
        frameworkAxiosRequest(config = {}) {
            const caller = this;
            const headers = {};
            config.withCredentials = true;
            if (!this.isPublicUrl(config.url)) {
                return this.addAuthorizationHeader(headers).then((accessToken) => {
                    if (!accessToken) return null;
                    config.headers = headers;
                    return this.axios(config).then((response) => {
                        caller.replaceWithFibernet(response.data);
                        return response;
                    }).catch((error) => {
                        console.log(config);
                        console.log(error);
                    });
                });
            }
            return this.axios(config);
        },
        postRequest(url, data, then, errorHandler) {
            if (url.indexOf('://') === -1) url = `http://${url}`;
            const headers = {};
            this.addAuthorizationHeader(headers).then((accessToken) => {
                let config = null;
                if (headers) {
                    config = {headers: headers};
                }
                if (!accessToken) return;
                this.axios.post(url, data, config).then((response) => this.handleResponse(response, then))
                    .catch((error) => {
                        this.handleError(error, errorHandler);
                    });
            });
        },
        frameworkPostRequest(url, func, params, then) {
            params = this.nullEmptyObjects(params);
            if (!this.isPublicUrl(url)) {
                const headers = {};
                this.addAuthorizationHeader(headers).then((accessToken) => {
                    if (!accessToken) return;
                    this.axios.post(this.getServeUrl(url), {
                        function: func,
                        ajax: true, ...params,
                    }, {
                        withCredentials: true,
                        headers: headers,
                    }).then((response) => this.handleResponse(response, then));
                });
            } else {
                this.axios.post(this.getServeUrl(url), {
                    function: func,
                    ajax: true, ...params,
                }, {
                    withCredentials: true,
                }).then((response) => this.handleResponse(response, then));
            }
        },
        putRequest(url, data, then, errorHandler) {
            if (url.indexOf('://') === -1) url = `http://${url}`;
            const headers = {};
            this.putRequestWithHeaders(url, data, headers, then, errorHandler);
        },
        putRequestWithHeaders(url, data, headers, then, errorHandler) {
            if (url.indexOf('://') === -1) url = `http://${url}`;
            this.addAuthorizationHeader(headers).then((accessToken) => {
                let config = null;
                if (headers) {
                    config = {headers: headers};
                }
                if (!accessToken) return;
                this.axios.put(url, data, config).then((response) => this.handleResponse(response, then))
                    .catch((error) => {
                        this.handleError(error, errorHandler);
                    });
            });
        },
        frameworkPutRequest(url, func, params, then) {
            params = this.nullEmptyObjects(params);
            const headers = {};
            this.addAuthorizationHeader(headers).then((accessToken) => {
                if (!accessToken) return;
                this.axios.put(this.getServeUrl(url), {
                    function: func,
                    ajax: true, ...params,
                }, {
                    withCredentials: true,
                    headers: headers,
                }).then((response) => this.handleResponse(response, then));
            });
        },
        patchRequest(url, data, then, errorHandler) {
            if (url.indexOf('://') === -1) url = `http://${url}`;
            const headers = {};
            this.addAuthorizationHeader(headers).then((accessToken) => {
                let config = null;
                if (headers) {
                    config = {headers: headers};
                }
                if (!accessToken) return;
                this.axios.patch(url, data, config).then((response) => this.handleResponse(response, then))
                    .catch((error) => {
                        this.handleError(error, errorHandler);
                    });
            });
        },
        frameworkPatchRequest(url, func, params, then) {
            params = this.nullEmptyObjects(params);
            const headers = {};
            this.addAuthorizationHeader(headers).then((accessToken) => {
                if (!accessToken) return;
                this.axios.patch(this.getServeUrl(url), {
                    function: func,
                    ajax: true, ...params,
                }, {
                    withCredentials: true,
                    headers: headers,
                }).then((response) => this.handleResponse(response, then));
            });
        },
        deleteRequest(url, params, then, errorHandler) {
            if (url.indexOf('://') === -1) url = `http://${url}`;
            const headers = {};
            this.addAuthorizationHeader(headers).then((accessToken) => {
                let config = null;
                if (headers) {
                    config = {headers: headers};
                }
                if (!accessToken) return;
                this.axios.delete(url, config).then((response) => this.handleResponse(response, then))
                    .catch((error) => {
                        this.handleError(error, errorHandler);
                    });
            });
        },
        frameworkDeleteRequest(url, func, params, then) {
            params = this.nullEmptyObjects(params);
            const headers = {};
            this.addAuthorizationHeader(headers).then((accessToken) => {
                if (!accessToken) return;
                this.axios.delete(this.getServeUrl(url), {
                    params: {
                        function: func,
                        ajax: true, ...params,
                    },
                    withCredentials: true,
                    headers: headers,
                }).then((response) => this.handleResponse(response, then));
            });
        },
        getServeUrl(url) {
            if (!url) {
                url = this.$route.fullPath;
            }
            url = 'serve.php' + url;
            return url;
        },
        handleResponse(response, then) {
            if (!response) {
                then(response);
                return;
            }
            if (response.data !== null && typeof response.data === 'object' && 'responseCode' in response.data) {
                const responseCode = response.data.responseCode;
                if (responseCode === 200 || responseCode === 201 || responseCode === 304) {
                    let data = response.data;
                    if ('response' in data) {
                        data = JSON.parse(data.response);
                    }
                    this.replaceWithFibernet(data);
                    if (then) then(data);
                } else {
                    let data = response.data;
                    if ('response' in data) data = data.response; else if ('header' in data) data = data.header;
                    const apiResponse = JSON.parse(data);
                    console.error(`API error: ${apiResponse.message}`);
                }
            } else if (response.status === 200 || response.status === 201 || response.status === 304 && then) {
                let data = response.data;
                this.replaceWithFibernet(data);
                then(data);
            } else if (typeof then === 'function') {
                then(response);
            }
        },
        storeJwt(jwt) {
            // One hour
            jwt.access_token_expiration = new Date().getTime() + 60 * 60 * 1000;
            localStorage.setItem('jwt', JSON.stringify(jwt));
        },
        getAccessToken() {
            const storedJwt = JSON.parse(localStorage.getItem('jwt'));
            const now = new Date().getTime();
            if (storedJwt) {
                // Check if access token is expired or expires within the next minute
                if (now > storedJwt.access_token_expiration - 60 * 1000) {
                    return this.axios.post(`${process.env.VUE_APP_AUTH_SERVICE}/auth-service/v1/refresh`, {
                        access_token: storedJwt.access_token,
                        refresh_token: storedJwt.refresh_token,
                    }).then((response) => {
                        this.storeJwt(response.data);
                        return Promise.resolve(response.data.access_token);
                    }).catch(() => {
                        this.safeClick('?f=logout');
                        return Promise.resolve(null);
                    });
                }
            } else {
                this.safeClick('?f=logout');
                return Promise.resolve(null);
            }
            return Promise.resolve(storedJwt.access_token);
        },
        addAuthorizationHeader(headers) {
            return this.getAccessToken().then((accessToken) => {
                headers.Authorization = `Bearer ${accessToken}`;
                return accessToken;
            });
        },
        isPublicUrl(url) {
            if(!url) url = this.$route.fullPath;
            return url === '/' || url === 'serve.php' || url.includes('=login') || url.includes('=logout') ||
                url.includes('=register') || url.includes('=resetPasswordRequest');
        },
        handleError(error, errorHandler) {
            if (errorHandler) errorHandler(error);
            if(error.response) {
                let data = error.response.data;
                error = data.error;
                if (data.message) error += `: ${data.message}`;
            }
            console.error(error);
        },
    },
});