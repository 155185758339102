<template>
  <XSelect
      :value="value"
      :items="computedItems"
      chips
      class="tags"
      label="Tags"
      multiple
      @input="$emit('input', $event)"
      dense
      no-spaces-in-values
      combobox/>
</template>

<script>
import XSelect from '@/components/basic/XSelect';

export default {
  name: 'TagSelect',
  components: {XSelect},
  props: {
    value: Array,
    items: Array,
  },
  data() {
    return {
      dataItems: [],
    };
  },
  created() {
    if (this.items === undefined) {
      this.services.explorerStatus.getExplorerTags((tags) => {
        this.dataItems = tags;
      });
    }
  },
  computed: {
    computedItems() {
      if (this.items !== undefined) return this.items;
      return this.dataItems;
    },
  },
};
</script>

<style scoped>
.tags >>> .v-input__slot {
  min-height: 96px;
}
</style>