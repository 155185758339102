<template>
  <component
      v-if="componentType != null && isset(componentType)"
      :is="componentType"
      :result="result"
      :key="type"
      v-bind="additional"
      v-on="$listeners"
      :additional="additional"
      :hideDetails="hideDetails">
  </component>
</template>
<script>
export default {
  name: 'dynamicComponent',
  props: {
    hideDetails: {type: Boolean, default: false},
    type: String,
    result: Object,
    additional: {
      default: function () {
        return {};
      },
    },
    events: {
      default: function () {
        return {};
      },
    },
    checkReadOnly: {
      type: Boolean,
    },
    isValid: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      componentType: null,
    };
  },
  watch: {
    type: function (newValue) {
      this.getComponent(newValue);
    },
  },
  mounted: function () {
    this.$on(this.events, (e) => {
      console.log('event', e);
    });
    // console.log("---->",this.checkReadOnly);
    if (
        this.type === 'trash' &&
        typeof this.checkReadOnly != 'undefined' &&
        this.checkReadOnly === true
    ) {
      this.getComponent('');
    } else {
      this.getComponent(this.type);
    }
  },
  methods: {
    mountEvents() {
      this.$on(this.events, (e) => {
        console.log('event', e);
      }); // Add event listeners
    },
    emitEvent(element, value) {
      console.log(element, value);
    },
    getComponent(newValue) {
      if (newValue != null) {
        // console.log("loading", newValue);
        this.componentType = null;
        import(`@/components/dynamic/${newValue}`)
            .then((loadedComponent) => {
              this.componentType = loadedComponent.default;
              this.mountEvents();
            })
            .catch(() => {
              import(`@/commonComponents/dynamic/${newValue}`)
                  .then((loadedComponent) => {
                    this.componentType = loadedComponent.default;
                    this.mountEvents();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            });
      }
    },
  },
};
</script>