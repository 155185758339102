<template>
  <div class="explorer-test-results">
    <XDataTable
        :headers="headers"
        :items-request="services.explorerStatus.getTestResults"
        :items-request-params="itemsRequestParams"
        :refresh="30"
        :select-actions="selectActions"
        :height="680"
        range
        @range="handleRangeUpdate"
        search
        @search="handleSearchUpdate"
        @refresh="getTestResultsStatusCounts"
        local-storage-key="explorerTestResults">
      <template #above-table>
        <TotalAndSplit
            v-model="testResultStatusCounts"
            :splits="testResultCountsSplits"
            @click-total="setTestStatusFilter(0, 0, -1)"/>
      </template>
      <template #[`item.testInfo`]="{value, row}">
        {{ value }}
        <!--        <TestInfoButton :test-id="row['id']" :test-id-key="row['idKey']"/>-->
        <TestInfoButton :test-id="row['id']" :test-id-key="shaKey(row['id'])"/>
      </template>
      <template #[`item.status`]="{value}">
        <div class="explorer-test-results-status">
          <v-icon :color="testStatusColors[value]">{{ testStatusIcons[value] }}</v-icon>
          {{ getTestStatus(value) }}
        </div>
      </template>
    </XDataTable>
    <LoadingDialog :value="loading"/>
  </div>
</template>

<script>
import {shaKey} from '@/js/helper.js';
import XDataTable from '@/components/basic/XDataTable';
import LoadingDialog from '@/components/basic/LoadingDialog';
import runningTestTypes from '@/cfg/runningTestTypes.json';
import testStatuses from '@/cfg/testStatuses.json';
import TestInfoButton from '@/components/specific/TestInfoButton';
import TotalAndSplit from '@/components/extended/TotalAndSplit';

export default {
  name: 'ExplorerTestResults',
  components: {
    TotalAndSplit,
    TestInfoButton,
    LoadingDialog,
    XDataTable,
  },
  props: {
    explorerName: String,
  },
  data() {
    return {
      headers: [
        {
          value: 'testInfo',
          width: 56,
        },
        {
          text: 'Test No.',
          value: 'id',
          formatter: (value, row) => {
            return `${row['id']}-${row['testGroupId']}`;
          },
        },
        {
          text: 'Test Name',
          value: 'testCaseName',
          href: (value, row) => {
            //return `?f=testing&f2=testInfo&function=getInfo&n_id=${row['id']}&n_id_key=${row['idKey']}`;
            return `?f=testing&f2=testInfo&function=getInfo&n_id=${row['id']}&n_id_key=${shaKey(row['id'])}`;
          },
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          formatter: (value) => {
            return runningTestTypes[value];
          },
        },
        {
          text: 'Explorer',
          value: 'subscriber',
          sortable: true,
        },
        {
          text: 'Owner',
          value: 'owner',
          sortable: true,
        },
        {
          text: 'Start Time',
          value: 'startTime',
          formatter: this.unixToDateTimeString,
          width: 142,
          sortable: true,
        },
        {
          text: 'Finish Time',
          value: 'endTime',
          formatter: this.unixToDateTimeString,
          width: 142,
          sortable: true,
        },
        {
          text: 'Duration',
          value: 'duration',
          formatter: this.formatDuration,
          width: 114,
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          width: 138,
          sortable: true,
        },
      ],
      range: {},
      selectActions: [
        {
          icon: 'mdi-stop',
          text: 'Stop Selected',
          disabled: true,
          tooltip: 'This function is temporarily disabled.',
        },
        {
          icon: 'mdi-folder-zip',
          text: 'Detailed Report (zip)',
          click: (selectedItems) => {
            this.loading = true;
            this.frameworkPostRequest('?f=testing&f2=testInfoPdf', 'getZippedReports', {
              responseType: 'ajax',
              selected: selectedItems,
            }, (result) => {
              this.loading = false;
              window.location.href = result.json['redirect'];
            });
          },
        },
        {
          icon: 'mdi-folder-zip-outline',
          text: 'Overview Report (zip)',
          click: (selectedItems) => {
            this.loading = true;
            this.frameworkPostRequest('?f=testing&f2=testInfoPdfSimple', 'getZippedSimpleReports', {
              responseType: 'ajax',
              selected: selectedItems,
            }, (result) => {
              this.loading = false;
              window.location.href = result.json['redirect'];
            });
          },
        },
      ],
      loading: false,
      testStatusFilter: -1,
      search: '',
      testStatusColors: {
        0: 'testStatusRunning',
        1: 'testStatusRunning',
        2: 'green',
        3: 'yellow',
        4: 'red',
        5: 'blue',
        6: 'blue',
      },
      testStatusIcons: {
        0: 'mdi-timer-sand',
        1: 'mdi-timer-sand',
        2: 'mdi-check-circle',
        3: 'mdi-alert',
        4: 'mdi-alert-box',
        5: 'mdi-close-octagon-outline',
        6: 'mdi-progress-clock',
      },
      testResultStatusCounts: {
        total: 0,
      },
      testResultCountsSplits: {
        pending: {
          text: (value) => `Pending: ${value}`,
          color: 'pendingGray',
          click: this.setTestStatusFilter,
          clickParams: [0],
        },
        running: {
          text: (value) => `Running: ${value}`,
          color: 'testStatusRunning',
          click: this.setTestStatusFilter,
          clickParams: [1],
        },
        ok: {
          text: (value) => `Ok: ${value}`,
          color: 'ok',
          click: this.setTestStatusFilter,
          clickParams: [2],
        },
        warning: {
          text: (value) => `Result Warning: ${value}`,
          color: 'testStatusWarning',
          click: this.setTestStatusFilter,
          clickParams: [3],
        },
        error: {
          text: (value) => `Result Error: ${value}`,
          color: 'testStatusError',
          click: this.setTestStatusFilter,
          clickParams: [4],
        },
        aborted: {
          text: (value) => `Aborted: ${value}`,
          color: 'testStatusAborted',
          click: this.setTestStatusFilter,
          clickParams: [5],
        },
        runtimeWarning: {
          text: (value) => `Processing Warning: ${value}`,
          color: 'testStatusRuntimeWarning',
          click: this.setTestStatusFilter,
          clickParams: [7],
        },
        runtimeError: {
          text: (value) => `Processing Error: ${value}`,
          color: 'testStatusRuntimeError',
          click: this.setTestStatusFilter,
          clickParams: [8],
        },
      },
    };
  },
  computed: {
    itemsRequestParams() {
      return [this.explorerName, this.testStatusFilter];
    },
  },
  methods: {
    shaKey: shaKey,
    formatDuration(value, row) {
      const endTime = row['endTime'] ? row['endTime'] : parseInt(new Date().getTime() / 1000);
      return this.toColonDuration(endTime - row['startTime']);
    },
    getTestStatus(value) {
      return testStatuses[value];
    },
    getTestResultsStatusCounts() {
      const fromTo = this.getUnixFromToByRange(this.range);
      const params = {
        from: fromTo.from,
        to: fromTo.to,
        search: this.search,
      };
      this.services.explorerStatus.getTestResultsStatusCounts(this.explorerName, params, (statusCounts) => {
        this.testResultStatusCounts = statusCounts;
      });
    },
    setTestStatusFilter(key, value, testStatus) {
      this.testStatusFilter = testStatus;
    },
    handleRangeUpdate(value) {
      this.range = value;
      this.getTestResultsStatusCounts();
    },
    handleSearchUpdate(value) {
      this.search = value;
      this.getTestResultsStatusCounts();
    },
  },
};
</script>

<style scoped>
.explorer-test-results {
  width: 100%;
  height: 100%;
}

.explorer-test-results-status {
  display: flex;
  gap: 3px;
  align-items: center;
}
</style>