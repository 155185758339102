<template>
  <div>
    <dynamicComponent
        v-if="typeof fieldAttributes['inputFieldFormatter'] != 'undefined'"
        :type="fieldAttributes['inputFieldFormatter']"
        :result="templateContent"
        :additional="{
        field: field,
        fieldAttributes: fieldAttributes,
        value: value,
        valueLine: valueLine,
        fieldAttrInput: fieldAttrInput
      }"
        v-on="$listeners"
        :checkReadOnly="checkReadOnly"
        :hideDetails="hideDetails">
    </dynamicComponent>

    <defaultFieldTypes
        v-else
        :fieldAttributes="fieldAttributes"
        :field="field"
        :templateContent="templateContent"
        v-on="$listeners"
        :checkReadOnly="checkReadOnly"
        :value="value"
        :valueLine="valueLine"
        :hideDetails="hideDetails">
    </defaultFieldTypes>
  </div>
</template>
<script>
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import defaultFieldTypes from "./defaultFieldTypes.vue";
import {getfieldAttrInput} from "@/js/helper";

export default {
  components: {
    defaultFieldTypes,
    dynamicComponent,
  },
  data: function () {
    return {};
  },
  props: [
    "fieldAttributes",
    "field",
    "templateContent",
    "checkReadOnly",
    "value",
    "valueLine",
    "hideDetails"
  ],
  computed: {
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes)
    }
  },
  // methods: {
  //   emitInput(value) {
  //     this.$emit("input", value);
  //   }
  // }
};
</script>