<template>
  <div>
    <primaryTextfield
        v-if="inputType === 'text' || inputType === 'number' || inputType === 'file'"
        :type="inputType"
        :name="field"
        :fieldAttrInput="fieldAttrInput"
        :label="fieldAttributes.name"
        :rules="rules_text_field_100"
        counter
        hint="This field uses maxlength attribute"
        v-model="localValue"
        v-on="$listeners"
        :templateContent="templateContent"
        :hideDetails="hideDetails"></primaryTextfield>
    <password
        v-else-if="inputType === 'password'"
        autocomplete="new-password"
        :show="false"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        :field="field"
        v-model="localValue"
        :templateContent="templateContent"></password>
    <fieldLabel
        v-else-if="inputType === 'label'"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        v-model="localValue"
        :field="field"></fieldLabel>
    <div v-else-if="inputType === 'string'" v-bind="fieldAttrInput" class="mt-2">
      {{ fieldAttributes.string }}
    </div>

    <radio
        v-else-if="inputType === 'radio'"
        v-bind="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        v-model="localValue"
        :field="field"
        :result="templateContent"></radio>

    <checkbox
        v-else-if="inputType === 'checkbox'"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        v-model="localValue"
        :field="field"
        :disabled="disabled"
        :result="templateContent"></checkbox>
    <associatedOption
        v-else-if="
        inputType === 'select' ||
        inputType === 'combobox' ||
        inputType === 'autocomplete'
      "
        :type="inputType"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        :value="localValue"
        v-on="$listeners"
        :field="field"
        :templateContent="templateContent"
        :disabled="fieldAttrInput.disabled"
        :hideDetails="hideDetails"></associatedOption>
    <multipleSelect
        v-else-if="inputType === 'multipleSelect'"
        :type="inputType"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        :value="localValue"
        v-on="$listeners"
        :field="field"
        :templateContent="templateContent"
        :disabled="fieldAttrInput.disabled"
        :hideDetails="hideDetails"></multipleSelect>
    <addableOptions
        v-else-if="inputType === 'addable'"
        :type="inputType"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        :value="localValue"
        v-on="$listeners"
        :field="field"
        :templateContent="templateContent"
        :disabled="fieldAttrInput.disabled"
        :hideDetails="hideDetails"></addableOptions>
    <!-- <v-select
          v-else-if="inputType=='select'"
          v-bind="fieldAttrInput"
          :label="fieldAttributes.name"
          :items="fieldAttributes.associatedOption"
    ></v-select>-->
    <div  v-else-if="inputType === 'textarea'" class="textarea-field">
      <v-textarea
        :autofocus="autofocus"
        :placeholder="placeholder"
        v-bind="fieldAttrInput"
        v-model="localValue"
        :label="fieldAttributes.name"
        outlined
        dense
        :name="field"
        :counter="fieldAttrInput.maxlength"
        :disabled="disabled"
        :rules="getRules(fieldAttrInput, templateContent, field)"
        :id="id">
      </v-textarea>
      <HelpButton :id="id" />
    </div>
    <switchGroup
        v-else-if="inputType === 'switchGroup'"
        :type="inputType"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        :value="localValue"
        v-on="$listeners"
        :field="field"
        :templateContent="templateContent"
        :disabled="fieldAttrInput.disabled"
        :valueLine="valueLine"></switchGroup>
    <aswitch
        v-else-if="inputType === 'switch'"
        :type="inputType"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        :value="localValue"
        v-on="$listeners"
        :field="field"
        :templateContent="templateContent"
        :disabled="disabled"></aswitch>
    <boolSwitch
        v-else-if="inputType === 'boolSwitch'"
        :type="inputType"
        :fieldAttrInput="fieldAttrInput"
        :fieldAttributes="fieldAttributes"
        :value="localValue"
        v-on="$listeners"
        :field="field"
        :templateContent="templateContent"
        :disabled="disabled"></boolSwitch>
    <div v-else-if="inputType === 'hidden'">
      <div v-if="typeof localValue == 'object'">
        <div v-for="(line, id) in localValue" :key="id">
          <input type="hidden" :name="field + '[]'" :value="localValue[id]"/>
        </div>
      </div>
      <input
          v-else type="hidden" v-bind="fieldAttrInput" :value="localValue" :name="field"/>
    </div>
    <span v-else>unable to find the type {{ inputType }} of {{ field }}</span>
  </div>
</template>
<script>
import {getRules} from '@/js/validators.js';
import fieldLabel from '@/commonComponents/fieldLabel.vue';
import radio from './radio.vue';
import password from './password.vue';
import associatedOption from '@/commonComponents/associatedOption.vue';
import primaryTextfield from '@/commonComponents/primaryTextfield.vue';
import checkbox from '@/commonComponents/checkbox.vue';
import switchGroup from '@/commonComponents/switchGroup.vue';
import aswitch from '@/commonComponents/aswitch.vue';
import boolSwitch from '@/commonComponents/boolSwitch.vue';
import addableOptions from '@/commonComponents/addableOptions.vue';
import multipleSelect from '@/commonComponents/multipleSelect.vue';
import {getfieldAttrInput, getPlaceholder, getFieldId} from '@/js/helper.js';
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  components: {
    radio,
    password,
    associatedOption,
    checkbox,
    primaryTextfield,
    fieldLabel,
    switchGroup,
    aswitch,
    boolSwitch,
    addableOptions,
    multipleSelect,
    HelpButton
  },
  data: function () {
    return {
      rules_text_field_100: [(v) => v.length <= 100 || 'Max 100 characters'],
    };
  },
  computed: {
    autofocus() {
      let autofocus = "";
      if (this.fieldAttrInput.autofocus === true) {
        autofocus = "autofocus";
      }
      return autofocus;
    },
    id() {
      return getFieldId(this.fieldAttrInput, this.templateContent, this.field);
    },
    disabled() {
      let disabled = this.checkReadOnly;
      if (
          (typeof this.templateContent != 'undefined' &&
              typeof this.templateContent.disableAllInputs != 'undefined' &&
              this.templateContent.disableAllInputs === true) ||
          this.fieldAttrInput.disabled === true
      ) {
        disabled = true;
      }
      return disabled;
    },
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    inputType() {
      let inputType = 'text';
      if (typeof this.fieldAttributes['type'] != 'undefined') {
        inputType = this.fieldAttributes['type'];
      }
      return inputType;
    },
    localValue: {
      get() {
        return this.value;
      },
      set: function (newValue, newText) {
        this.$emit('input', newValue, newText);
      },
    },
    placeholder() {
      return getPlaceholder(this.fieldAttrInput);
    },
  },
  methods: {getRules: getRules},

  props: [
    'fieldAttributes',
    'field',
    'templateContent',
    'checkReadOnly',
    'value',
    'valueLine',
    'hideDetails',
  ],
};
</script>
<style scoped>
.textarea-field {
  display: flex;
}
.textarea-field >>> .help-button {
  margin-top: 15px;
}
</style>
          