<template>
  <v-dialog persistent v-bind="properties" :value="value">
    <v-card class="mx-auto">
      <v-card-title class="headline-box-headline primary white--text">{{
        title
      }}</v-card-title>
      <div class="mt-2">
        <dialogContent :result="{}">
          <slot>
            <div v-html="message"></div>
          </slot>
        </dialogContent>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <primaryButton
          type="button"
          @click="emitOk()"
          :label="okTitle"
          icon="mdi-check"
          color="primary"
          v-show="!hideOkButton"
          :id="buttonsIDPrefix + '_ok_button'"
          data-type="ok_button"
        ></primaryButton>
        <slot name="additionalButtons"></slot>
        <cancel
          v-show="!hideCancelButton"
          @click="emitCancel()"
          :buttonsIDPrefix="buttonsIDPrefix"
        ></cancel>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cancel from "@/commonComponents/cancel.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import dialogContent from "@/commonComponents/dialogContent.vue";
export default {
  components: { cancel, primaryButton, dialogContent },

  props: {
    value: Boolean,
    title: String,
    message: String,
    okTitle: { default: "OK" },
    cancelTitle: { default: "Cancel" },
    maxWidth: { default: 0 },
    hideOkButton: { type: Boolean, default: false },
    hideCancelButton: { type: Boolean, default: false },
    buttonsIDPrefix: { default: "" },
  },
  computed: {
    properties() {
      let properties = {};
      if (this.maxWidth > 0) {
        this.$set(properties, "max-width", this.maxWidth);
      }
      return properties;
    },
  },
  methods: {
    emitOk: function () {
      this.dialog = false;
      this.$emit("confirmation-ok", true);
    },
    emitCancel: function () {
      this.dialog = false;
      this.$emit("confirmation-ok", false);
    },
  },
};
</script>>