<template>
    <div v-if="userInfo &&  userInfo.loggedIn == true && !$vuetify.breakpoint.mobile" class="mb-3 mt-3">
        <v-container fluid v-if="typeof userInfo!='undefined' &&
                                 typeof userInfo['isDemoProject']!='undefined' &&
                                 userInfo['isDemoProject']!=null &&
                                 userInfo['isDemoProject']===true">
            <v-row align="center">
                <v-col>
                    <v-toolbar height="55px" class="mt-n3 mb-n7" style="background-color:#66a56994;z-index: 1!important;">
                        <v-row>
                        <v-col cols="9" xl="9" lg="7" md="4">
                            <div  class="ml-2" style="color: rgb(30,58,15);">
                                Acctopus Degust Demo Project (view only)<br>
                                Once you have installed your first Degust Explorer, you will want to switch to your own project.</div>
                        </v-col>
                        <v-col cols="3" xl="3" lg="5" md="8">
                            <v-row class="mt-n2" style="float: right;">
                                <v-col style="max-width: 225px;">
                                    <div style="width: fit-content">
                                        <addField
                                                :path="tableUrl"
                                                :additionalAttributes="{
                                                    function: 'downloadImageRequest',
                                                    requestType: 'ajax',
                                                 }"
                                                :dialogAttributes="{}"
                                                :showDialog="openDialogFlag"
                                                @dialog-closed="openDialogFlag == false"
                                        >
                                            <div class="">
                                                <primaryButton
                                                        type="button"
                                                        label="Get Explorer Image"
                                                        color="primary"
                                                        id="importExplorer"
                                                ></primaryButton>
                                            </div>
                                        </addField>
                                    </div>
                                </v-col>
                                <v-col style="max-width: 225px;">
                                    <primaryButton
                                            type="button"
                                            label="Change Project"
                                            color="primary"
                                            id="changeProject"
                                            @click="changeProject()"
                                            :disabled="disabled"
                                    ></primaryButton>
                                </v-col>
                            </v-row>

                        </v-col>
                        </v-row>
                    </v-toolbar>
                </v-col>
            </v-row>
         </v-container>
    </div>
</template>

<script>
  import primaryButton from "@/commonComponents/primaryButton.vue";
  import addField from "@/commonComponents/addField.vue";

  export default {
   props: ["userInfo", "result"],
    data() {
      return {
        openDialogFlag: false,
        tableUrl: "serve.php?f=administration&f2=explorers",
        timer: null,
        interval:30000,
        projectDetails:null,
        orgId:null,
        explorerList:null,
        disabled:true
      };
    },
    components: {
      primaryButton,
      addField
    },
    /*   created() {
     //this.init();
     // console.log(this.$vuetify.breakpoint.name);
    },*/
    beforeDestroy() {
      clearTimeout(this.timer);
    },
    watch: {
      "userInfo": function (value) {
        //console.log(" changed", value);
    /*    if(value!=null &&
          value.isExplorerExist!=null &&
          value.isExplorerExist===true){
          this.disabled = false;
        }*/
        if(value!=null &&
          value.isDemoProject!=null &&
          value.isDemoProject===true){
          this.getProjectDetails();
        }
      },
    },
    methods: {

 /*     async  init() {
        console.log('start');
        await this.sleep(5000);
        console.log('finish wait',this.userInfo);
        if(this.userInfo!=null &&
          this.userInfo['isExplorerExist']!=null &&
          this.userInfo['isExplorerExist']===true){
          this.disabled = false;
        }
        if(this.userInfo!=null &&
          this.userInfo['isDemoProject']!=null &&
          this.userInfo['isDemoProject']===true){
          console.log('call pro details');
          this.getProjectDetails();
        }
      },
      sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      },*/

      changeProject(){
        console.log(this.projectDetails.length);
        console.log(this.orgId[0]);
        if(this.projectDetails!='null' && this.projectDetails.length==1){ //for one project change
           this.frameworkAxiosRequest({
             method: 'POST',
             url: 'serve.php?f=administration&f2=projectAdministration',
             dataType: 'json',
             data: {
               project: this.projectDetails[0].n_id,
               organisations: this.orgId[0],
               requestType: 'ajax',
               function: 'switchProjectAjax',
             },
           })
               .then(function () {
              location.reload();
            })
            .catch(function (response) {
              console.log(response);
            });
        }else if(this.projectDetails!='null' && this.projectDetails.length>1){//more than one list out!
             this.$root.$refs.upperHeader.switchProjectRequest();
        }
      },
      getProjectDetails() {
        //console.log("u-->",this.userInfo);
        clearTimeout(this.timer);
        let caller = this;
        this.frameworkAxiosRequest({
          method: 'POST',
          url: this.tableUrl,
          dataType: 'json',
          data: {
            function: 'checkProjectDetails',
            requestType: 'ajax',
          },
        })
            .then(function (post) {
            try {
              if (typeof post.data.result!='undefined' && typeof post.data.result.json != "undefined" &&  post.data.result.json != null) {
                caller.projectDetails = post.data.result.json.projectDetails;
                caller.orgId = post.data.result.json.orgId;
                caller.explorerList = post.data.result.json.explorerList;
                //console.log(caller.explorerList);
                if(caller.explorerList!='null' && typeof caller.explorerList!='undefined' && caller.explorerList.length>0){
                  caller.disabled=false;
                  caller.interval=0;
                }
              } else {
                //caller.option = null;
              }
              //  console.log(caller.option);
              caller.$nextTick(() => {
                if (caller.interval > 0) {
                  caller.timer = setTimeout(function () {
                    caller.getProjectDetails();
                  }, caller.interval);
                }
              });
            } catch (error) {
              console.log(error);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },

  }
</script>

<style scoped>

</style>