<template>
  <v-row no-gutters>
    <v-col v-bind="colsBindings">
      <fieldtypes
          :fieldAttributes="fieldAttributes"
          :field="field"
          :templateContent="templateContent"
          v-on="$listeners"
          :checkReadOnly="checkReadOnly"
          :value="value"
          :valueLine="valueLine"
          :hideDetails="hideDetails">
      </fieldtypes>
    </v-col>
    <v-col
        v-if="enableAdd !== false"
        cols="1"
        style="max-width: 2%"
        class="mt-2">
      <div v-if="typeof fieldAttributes['addingURL'] != 'undefined'">
        <dynamicComponent
            v-if="typeof fieldAttributes['addingFunction'] != 'undefined'"
            :type="fieldAttributes['addingFunction']"
            :result="templateContent"
            :additional="{
            fieldAttributes,
            field: field,
            updatedDialogRef: this.templateContent.dialogRef,
            additionalAttributes: additionalAttributes,
            value: value,
            valueLine: valueLine,
            id: id + '_adding_button',
          }"
            @updated-fields="emitUpdateFields"
            :hideDetails="hideDetails">
        </dynamicComponent>
        <addField
            v-else
            :path="fieldAttributes['addingURL']"
            :additionalAttributes="additionalAttributes"
            :dialogAttributes="fieldAttributes['addingDialogOptions']"
            @updated-fields="emitUpdateFields">
          <v-icon title="Add" class="ml-1" :id="id + '_adding_button'">mdi-plus-box-outline</v-icon>
        </addField>
      </div>
    </v-col>

    <!-- <a
      v-if="typeof fieldAttributes['addingURL']!='undefined'"
      @click="addFieldUpdateSelect( fieldAttributes['addingURL'],fieldAttributes['additionlAddingOptions'])"
    >
       onclick='{$addingFunction}(this,"{$attributes['addingURL']}", {$additionalOptions}, {$addingDialogOptionsJson} );'

      <i {convertAttributeArrayToString($addingNewoptionAttrs)}></i>
      <v-icon>mdi-plus</v-icon>
    </a>-->
  </v-row>
</template>
<script>
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import addField from "@/commonComponents/addField.vue";
import fieldtypes from "@/commonComponents/fieldTypes.vue";
import {getFieldId} from "@/js/helper.js";

export default {
  components: {
    fieldtypes,
    addField,
    dynamicComponent,
  },
  data() {
    return {};
  },
  computed: {
    id() {
      let fieldAttrInput = {};
      if (typeof this.fieldAttributes.fieldAttrInput != "undefined") {
        fieldAttrInput = this.fieldAttributes.fieldAttrInput;
      }
      return getFieldId(fieldAttrInput, this.templateContent, this.field);
    },
    enableAdd() {
      let enableAdd = true;
      if (
          typeof this.templateContent != "undefined" &&
          typeof this.templateContent.enableAdd !== "undefined"
      ) {
        enableAdd = this.templateContent.enableAdd;
      }
      return enableAdd;
    },
    colsBindings() {
      let colsBindings = {};
      if (this.enableAdd !== false) {
        colsBindings = {style: "max-width: 98%"};
      }
      return colsBindings;
    },
    additionalAttributes() {
      let options = {
        function: "getInsertedFieldsUpdateSelect",
        requestType: "ajax",
        updatedField: this.field,
        updatedDialogRef: this.templateContent.dialogRef,

        //objectID: this.templateContent.fields.n_id.value,
        // objectKey: this.templateContent.fields.n_id_key.value,
      };
      options = {
        ...options,
        ...this.fieldAttributes["additionlAddingOptions"],
      };
      return options;
    },
  },
  methods: {
    emitUpdateFields(info) {
      this.$emit("updated-fields", info);
    },
  },
  //   addFieldUpdateSelect(caller, uri, additionalOptions, dialogOptions) {
  //     if (typeof dialogOptions == "undefined") {
  //       dialogOptions = { width: 1200 };
  //     }
  //     var callerId = "";
  //     var $caller = $(caller);
  //     var $idRow = $caller.closest(".fields").find("#n_idRow");
  //     var id = $idRow.find('[name="n_id"]').val();
  //     var idKey = $idRow.find('[name="n_id_key"]').val();
  //     var fatherIndex = $caller.closest(".ui-dialog").css("z-index");
  //     //var $callingObjectRow=$caller.closest("tr");
  //     $caller.uniqueId();
  //     callerId = $caller.attr("id");

  //     let $opendWindow = $(".editDialog").filter(
  //       '[data-callerid="' + callerId + '"]'
  //     );
  //     if ($opendWindow.length == 1) {
  //       $opendWindow.dialog("moveToTop");

  //       return $opendWindow;
  //     } else {
  //       var options = {};
  //       var title = "Loading...";

  //       options = {
  //         function: "getInsertedFieldsUpdateSelect",
  //         requestType: "ajax",
  //         callerId: callerId,
  //         objectID: id,
  //         objectKey: idKey,
  //       };
  //       var $returnedDialog = getDialogWithDefaultOptions(
  //         options,
  //         uri,
  //         dialogOptions,
  //         title,
  //         callerId,
  //         additionalOptions,
  //         "",
  //         $caller
  //       );
  //       //$returnedDialog = dialogStackUp($caller,$returnedDialog);
  //       return $returnedDialog;
  //     }
  //   },
  // },

  function() {
    return {
      /*  v-if="typeof $attributes['addingURL'] !='undefined'"
    {$addingFunction="AddFieldUpdateSelect"}
  v-if="typeof $attributes['addingFunction'] !='undefined'"
    {$addingFunction=$attributes['addingFunction']}
  {/if}
   {$addingNewoptionAttrs["class"]="fas fa-plus-square fs16"}

    v-if="typeof fieldAttributes["fieldAttrAddingOption"] !='undefined'"

   {$addingNewoptionAttrs=mergeTwoAttributesArray(fieldAttributes["fieldAttrAddingOption"],$addingNewoptionAttrs)}
  {/if}

   {$additionalOptions="{ }"}
  v-if="typeof fieldAttributes["additionlAddingOptions"] !='undefined'"
   {$additionalOptions=json_encode(fieldAttributes["additionlAddingOptions"])}
  {/if}

  {$addingDialogOptionsJson="{ }"}
  v-if="typeof fieldAttributes["addingDialogOptions"] !='undefined'"
   {$addingDialogOptionsJson=json_encode(fieldAttributes["addingDialogOptions"])}
  {/if}

  {$givenClassName=getclassNamefromURL({$attributes['addingURL']})}
  v-if=empty($givenClassName)
  {$givenClassName=$className}
  {/if}*/
    };
  },

  props: {
    fieldAttributes: {
      type: Object,
      /*default: function() {
          return {};
        }*/
    },
    field: {
      type: String,
      /*default: function() {
          return {};
        }*/
    },
    templateContent: Object,
    checkReadOnly: {type: Boolean},
    value: {},
    valueLine: {},
    hideDetails: {type: Boolean, default: false},
  },
};
</script>