<template>
  <v-row v-bind="fieldAttrRow" no-gutters>
    <v-col
        v-for="(fieldInfo, innerfield) in fields"
        :key="innerfield"
        v-bind="fieldInfo.colAttrs"
        :class="getClass(fieldInfo)">
      <fieldsValues
          :fieldAttributes="mergeFieldAttributes(fieldInfo)"
          :field="innerfield"
          :templateContent="localTemplateContent"
          @input="checkvalue('input', innerfield, ...arguments)"
          @change="checkvalue('change', innerfield, ...arguments)"
          v-on="$listeners"
          :checkReadOnly="checkReadOnly"
          :value="valueLine[innerfield]"
          :hideDetails="hideDetails"
          :valueLine="valueLine">
      </fieldsValues>
    </v-col>
  </v-row>
</template>
<script>
import fieldsValues from "@/commonComponents/fieldsValues.vue";

export default {
  components: {fieldsValues},
  props: {
    hideDetails: {type: Boolean, default: false},
    fieldAttrRow: {},
    fields: {type: Object},
    checkReadOnly: {type: Boolean},
    templateContent: {},
    valueArray: {},
    order: {},
    valueLine: {
      type: Object,
    },
  },
  computed: {
    localTemplateContent() {
      return {
        ...this.templateContent,
        fields: this.fields,
        valueArray: this.valueArray,
        enableAdd: false,
        order: this.order,
        valueLine: this.valueLine,
        mainContent: this.templateContent
      };
    },
  },
  methods: {
    getClass(fieldInfo) {
      let className = "mr-3 ";
      if (
          typeof fieldInfo.colAttrs != "undefined" &&
          typeof fieldInfo.colAttrs.class != "undefined"
      ) {
        if (fieldInfo.colAttrs.class.indexOf("mr-0") > -1) {
          // removing default padding
          className = "";
        }
        className = className + fieldInfo.colAttrs.class;
      }
      return className;
    },
    checkvalue(eventName, field, value, text) {
      if (eventName === "input") {
        this.$emit("field-value-changed", field, value, text);
      } else {
        this.$emit("field-value-changed-changed", field, value, text);
      }
    },
    mergeFieldAttributes(fieldInfo) {
      //this is used for dynamic disable according to input conditions
      if (this.checkReadOnly) {
        if (typeof fieldInfo.fieldAttrInput == "undefined") {
          this.$set(fieldInfo, "fieldAttrInput", {});
        }
        this.$set(fieldInfo.fieldAttrInput, "disabled", true);
      } else {
        if (typeof fieldInfo.fieldAttrInput != "undefined" && !fieldInfo.fieldAttrInput.preserveDisabled) {
          this.$delete(fieldInfo.fieldAttrInput, "disabled");
        }
      }
      return fieldInfo;
    },
  },
};
</script>