<template>
  <div class="test-status-legend">
    <div v-for="[text, color] of Object.entries(colors)" :key="text" class="legend-item">
      <div class="legend-color" :style="`background-color: var(--v-${color}-base);`"/>
      <span>{{ testStatusTranslation(text) }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      colors: {
        'pending': 'pendingGray',
        'running': 'testStatusRunning',
        'ok': 'ok',
        'resultWarning': 'testStatusWarning',
        'resultError': 'testStatusError',
        'aborted': 'testStatusAborted',
        'processingWarning': 'testStatusRuntimeWarning',
        'processingError': 'testStatusRuntimeError',
      },
    };
  },
  methods: {
    testStatusTranslation(text) {
      return this.language.testStatusFull[text];
    },
  },
};
</script>
<style scoped>
.test-status-legend {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  font-size: 14px;
  color: #666666;
  background-color: #eee;
  border-radius: 4px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.legend-color {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  opacity: 0.8;
}
</style>