import Vue from 'vue';
import '@/mixins/requests';

class Service {
    constructor(url) {
        this.url = url;
    }

    getRouteUrl(route, ...params) {
        for (const param of params) {
            route = route.replace(/{.+?}/, param.toString());
        }
        return `${this.url}/${route}`;
    }
}

const dictionaryService = new Service(`${process.env.VUE_APP_DICTIONARY_SERVICE}/dictionary-service/v1`);
const dictionaryServiceUrls = {
    radius: 'radius',
};

const chartService = new Service(`${process.env.VUE_APP_CHART_SERVICE}`);
const chartServiceUrls = {
    dashboardList: 'chart-service/v3/dashboard/list',
    chartById: 'chart-service/v3/chartById/{id}',
    chartByIdPublic: 'chart-service/v3/public/chartById/{id}',
    testStatus: 'chart-service/v4/test-status',
    explorerMapByName: 'chart-service/v3/explorer/map/{name}',
    link: 'chart-service/v3/link/{id}',
    generateLink: 'chart-service/v3/generate-link',
};

const almService = new Service(`${process.env.VUE_APP_ALM_SERVICE}/alm-service/v1`);
const almServiceUrls = {
    uploadResults: 'upload-results',
    folders: 'folders',
    sync: 'sync',
};

const explorerStatusService = new Service(`${process.env.VUE_APP_EXPLORER_STATUS_SERVICE}`);
const explorerStatusServiceUrls = {
    explorers: 'explorers/v1/explorers',
    explorerCounts: 'explorers/v1/explorers/counts',
    explorer: 'explorers/v1/explorer/{id}',
    explorerWithVersion: 'explorers/v1/explorer/{id}/{version}',
    toggle: 'explorers/v1/explorer/{id}/toggle/{active}',
    csvImportStatus: 'csv-import/v1/csv-import-status',
    importCsv: 'csv-import/v1/import-csv/{function}',
    reboot: 'explorer-management/v1/reboot/{name}',
    explorerConfiguration: 'explorer-configuration/v2/explorer/{name}',
    systemInfo: 'systeminfo/v1/systeminfo/{name}',
    status: 'status-view/v2/status/{name}',
    statusSystemInfo: 'system-status/v1/systeminfo/{name}',
    testResults: 'test-results/v1/{name}',
    testResultsStatusCounts: 'test-results/v1/status-counts/{name}',
    tags: 'explorer-status-service/v1/tags',
    checkTagSyntax: 'explorer-status-service/v1/check-tag-syntax',
    serviceConfiguration: 'explorer-configuration/v2/{id}/services',
};

const testService = new Service(`${process.env.VUE_APP_TEST_SERVICE}/portal-service/v1`);
const testServiceUrls = {
    tests: 'tests',
    categories: 'categories',
};

const sipSubscriberService = new Service(`${process.env.VUE_APP_SIP_SUBSCRIBER_SERVICE}/sip-subscriber-service/v1`);
const sipSubscriberServiceUrls = {
    sipSubscribers: 'sip-subscribers',
    sipSubscriber: 'sip-subscriber',
    sipSubscriberWithId: 'sip-subscriber/{id}',
};

const userService = new Service(`${process.env.VUE_APP_USER_SERVICE}/user-service/v1`);
const userServiceUrls = {
    userData: 'user-data',
    project: 'projects/project',
    projectHash: 'projects/project-hash',
    projectNames: 'projects/names',
    projectNamesHash: 'projects/names-hash',
};

const imageCreatorService = new Service(`${process.env.VUE_APP_IMAGE_CREATOR_SERVICE}`);
const imageCreatorServiceUrls = {
    create: 'image-creator-service/v1/create',
};

const helpService = new Service(`${process.env.VUE_APP_HELP_SERVICE}/help-service/v1`);
const helpServiceUrls = {
    list: 'list',
    text: 'text/{id}',
};

const cacheFromLocalStorage = JSON.parse(localStorage.getItem('cache'));
const cache = cacheFromLocalStorage !== null ? cacheFromLocalStorage : {};
const defaultUpdateAfter = 25;
const updateAfterValues = {
    getRadiusAttributes: 600,
};

const cacheResult = (result, request, then) => {
    cache[request] = {
        value: result,
        lastUpdate: new Date() / 1000,
    };
    try {
        localStorage.setItem('cache', JSON.stringify(cache));
    } catch (error) {
        console.error(error);
    }
    then(result);
};

const isInCache = (request, then) => {
    if (cache[name] !== undefined) {
        const updateAfter = updateAfterValues[name] !== undefined ? updateAfterValues[name] : defaultUpdateAfter;
        if (new Date().getTime() / 1000 - cache[name].lastUpdate < updateAfter) {
            then(cache[name].value);
            return true;
        }
    }
    return false;
};

const getRequest = (url, caller, params, then, error) => {
    const request = `GET:${url}`;
    if (isInCache(request, then)) return;
    caller.getRequest(url, params, (result) => {
        cacheResult(result, request, then);
    }, error);
};

const publicGetRequest = (url, caller, params, then, error) => {
    const request = `GET:${url}`;
    if (isInCache(request, then)) return;
    caller.publicGetRequest(url, params, (result) => {
        cacheResult(result, request, then);
    }, error);
};

const postRequest = (url, caller, data, then, error) => {
    caller.postRequest(url, data, then, error);
};

const putRequest = (url, caller, data, then, error) => {
    caller.putRequest(url, data, then, error);
};

const putRequestWithHeaders = (url, caller, data, headers, then, error) => {
    caller.putRequestWithHeaders(url, data, headers, then, error);
};

const patchRequest = (url, caller, data, then, error) => {
    caller.patchRequest(url, data, then, error);
};

const deleteRequest = (url, caller, params, then, error) => {
    caller.deleteRequest(url, params, then, error);
};

Vue.mixin({
    computed: {
        services() {
            const caller = this;
            return {
                dictionary: {
                    getRadiusAttributes(then, error) {
                        getRequest(dictionaryService.getRouteUrl(dictionaryServiceUrls.radius), caller, null, then,
                            error);
                    },
                },
                explorerStatus: {
                    getExplorers(status, tags, online, params, then, error) {
                        const combinedParams = {
                            status: status !== undefined ? status : -1,
                            tags: tags !== undefined ? tags : '',
                            online: online !== undefined ? online : -1, ...params,
                        };
                        getRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.explorers), caller,
                            combinedParams, then, error);
                    },
                    getExplorerCounts(status, tags, search, then, error) {
                        const params = {
                            status: status !== undefined ? status : -1,
                            tags: tags !== undefined ? tags : '',
                            search: search !== undefined ? search : '',
                        };
                        getRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.explorerCounts), caller,
                            params, then, error);
                    },
                    getExplorer(id, version, then, error) {
                        getRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.explorerWithVersion, id,
                                version),
                            caller, null, then, error);
                    },
                    updateExplorer(explorer, then, error) {
                        patchRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.explorer, explorer.id),
                            caller, explorer, then, error);
                    },
                    deleteExplorer(id, then, error) {
                        deleteRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.explorer, id), caller,
                            null, then, error);
                    },
                    toggle(id, active, then, error) {
                        patchRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.toggle, id, active),
                            caller, null, then, error);
                    },
                    getCsvImportStatus(then, error) {
                        getRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.csvImportStatus), caller,
                            null, then, error);
                    },
                    importCsv(csv, type, then, error) {
                        const formData = new FormData();
                        formData.append('csv', csv);
                        putRequestWithHeaders(
                            explorerStatusService.getRouteUrl(explorerStatusServiceUrls.importCsv, type),
                            caller,
                            formData, {
                                'Content-Type': 'multipart/form-data',
                            },
                            then, error);
                    },
                    reboot(explorerName, then, error) {
                        postRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.reboot, explorerName),
                            caller, null, then, error);
                    },
                    getExplorerByName(explorerName, then, error) {
                        getRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.explorerConfiguration,
                            explorerName), caller, null, then, error);
                    },
                    updateExplorerByName(explorerName, configuration, then, error) {
                        patchRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.explorerConfiguration,
                            explorerName), caller, configuration, then, error);
                    },
                    getSystemInfo(explorerName, then, error) {
                        getRequest(
                            explorerStatusService.getRouteUrl(explorerStatusServiceUrls.systemInfo, explorerName),
                            caller, null, then, error);
                    },
                    getStatus(explorerName, then, error) {
                        getRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.status, explorerName),
                            caller, null, then, error);
                    },
                    getStatusSystemInfo(explorerName, then, error) {
                        getRequest(
                            explorerStatusService.getRouteUrl(explorerStatusServiceUrls.statusSystemInfo, explorerName),
                            caller, null, then, error);
                    },
                    getTestResults(explorerName, testStatus, params, then, error) {
                        const combinedParams = {
                            testStatus: testStatus !== undefined ? testStatus : -1, ...params,
                        };
                        getRequest(
                            explorerStatusService.getRouteUrl(explorerStatusServiceUrls.testResults, explorerName),
                            caller, combinedParams, then, error);
                    },
                    getTestResultsStatusCounts(explorerName, params, then, error) {
                        getRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.testResultsStatusCounts,
                            explorerName), caller, params, then, error);
                    },
                    getExplorerTags(then, error) {
                        getRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.tags), caller, null,
                            then,
                            error);
                    },
                    checkTagSyntax(query, then, error) {
                        postRequest(explorerStatusService.getRouteUrl(explorerStatusServiceUrls.checkTagSyntax), caller,
                            {
                                query: query,
                            }, then, error);
                    },
                    saveServiceConfiguration(explorerId, serviceConfiguration, then, error) {
                        patchRequest(
                            explorerStatusService.getRouteUrl(explorerStatusServiceUrls.serviceConfiguration,
                                explorerId),
                            caller, serviceConfiguration, then, error);
                    },
                },
                chart: {
                    getDashboardList(then, error) {
                        getRequest(chartService.getRouteUrl(chartServiceUrls.dashboardList), caller, null, then, error);
                    },
                    getChartById(id, params, then, error) {
                        getRequest(chartService.getRouteUrl(chartServiceUrls.chartById, id), caller, params, then,
                            error);
                    },
                    getChartByIdPublic(uuid, params, then, error) {
                        publicGetRequest(chartService.getRouteUrl(chartServiceUrls.chartByIdPublic, uuid), caller,
                            params,
                            then,
                            error);
                    },
                    getTestStatus(params, then, error) {
                        getRequest(chartService.getRouteUrl(chartServiceUrls.testStatus), caller, params, then, error);
                    },
                    getExplorerMapByName(name, then, error) {
                        getRequest(chartService.getRouteUrl(chartServiceUrls.explorerMapByName, name), caller, null,
                            then, error);
                    },
                    getExternalLink(chartId, then, error) {
                        getRequest(chartService.getRouteUrl(chartServiceUrls.link, chartId), caller, null, then,
                            error);
                    },
                    createExternalLink(chartId, filter, then, error) {
                        postRequest(chartService.getRouteUrl(chartServiceUrls.generateLink), caller, {
                            chartId: chartId,
                            filter: filter,
                        }, then, error);
                    },
                    deleteExternalLink(chartId, then, error) {
                        deleteRequest(chartService.getRouteUrl(chartServiceUrls.link, chartId), caller, null,
                            then, error);
                    },
                },
                alm: {
                    uploadResults(data, then, error) {
                        postRequest(almService.getRouteUrl(almServiceUrls.uploadResults), caller, data, then, error);
                    },
                    getFolders(data, then, error) {
                        postRequest(almService.getRouteUrl(almServiceUrls.folders), caller, data, then, error);
                    },
                    sync(projectHash, then, error) {
                        patchRequest(almService.getRouteUrl(almServiceUrls.sync), caller, {domain: projectHash}, then,
                            error);
                    },
                },
                test: {
                    getTests(categories, params, then, error) {
                        const combinedParams = {
                            categories: JSON.stringify(categories), ...params,
                        };
                        getRequest(testService.getRouteUrl(testServiceUrls.tests), caller, combinedParams, then, error);
                    },
                    getCategories(then, error) {
                        getRequest(testService.getRouteUrl(testServiceUrls.categories), caller, null, then, error);
                    },
                },
                sipSubscriber: {
                    getSipSubscribers(params, then, error) {
                        getRequest(sipSubscriberService.getRouteUrl(sipSubscriberServiceUrls.sipSubscribers), caller,
                            params, then, error);
                    },
                    getSipSubscriber(id, version, then, error) {
                        getRequest(
                            sipSubscriberService.getRouteUrl(sipSubscriberServiceUrls.sipSubscriberWithId, id, version),
                            caller, null, then, error);
                    },
                    createSipSubscriber(subscriber, then, error) {
                        putRequest(sipSubscriberService.getRouteUrl(sipSubscriberServiceUrls.sipSubscriber), caller,
                            subscriber, then, error);
                    },
                    updateSipSubscriber(subscriber, then, error) {
                        patchRequest(sipSubscriberService.getRouteUrl(sipSubscriberServiceUrls.sipSubscriberWithId,
                            subscriber.id), caller, subscriber, then, error);
                    },
                    deleteSipSubscriber(id, then, error) {
                        deleteRequest(
                            sipSubscriberService.getRouteUrl(sipSubscriberServiceUrls.sipSubscriberWithId, id), caller,
                            null, then, error);
                    },
                },
                user: {
                    getUserData(then, error) {
                        getRequest(userService.getRouteUrl(userServiceUrls.userData), caller, null, then, error);
                    },
                    getProject(then, error) {
                        getRequest(userService.getRouteUrl(userServiceUrls.project), caller, null, then, error);
                    },
                    getProjectHash(then, error) {
                        getRequest(userService.getRouteUrl(userServiceUrls.projectHash), caller, null, then, error);
                    },
                    getProjectNames(then, error) {
                        getRequest(userService.getRouteUrl(userServiceUrls.projectNames), caller, null, then, error);
                    },
                    getProjectNamesHash(then, error) {
                        getRequest(userService.getRouteUrl(userServiceUrls.projectNamesHash), caller, null, then,
                            error);
                    },
                },
                imageCreator: {
                    create(data, then, error) {
                        postRequest(imageCreatorService.getRouteUrl(imageCreatorServiceUrls.create), caller, data, then,
                            error);
                    },
                },
                help: {
                    getList(then, error) {
                        publicGetRequest(helpService.getRouteUrl(helpServiceUrls.list), caller, null, then, error);
                    },
                    getText(id, then, error) {
                        publicGetRequest(helpService.getRouteUrl(helpServiceUrls.text, id), caller, null, then, error);
                    },
                },
            };
        },
    },
});