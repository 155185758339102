<template>
  <div class="secondLevel tabContainer" v-bind="mainMenuItem.divattr">
    <!-- <div v-if="enableTabs===true">
     <v-btn class="megaTab">
       <a v-bind="menuItem.attr">{{language[menuItem["name"]]}}</a>
     </v-btn>
   </div>-->
    <div class="mega-container" :style="{ width: containerWidth+'px'}">
      <v-divider v-show="showDivider"></v-divider>
      <menuOptions
          :iterator="submenuList"
      ></menuOptions>
    </div>

    <!-- <div class="notLoggedIn" v-if="loggedIn!=true">{{error('notLoggedIn')}}</div> -->
  </div>
</template>
<script>
import menuOptions from "@/commonComponents/menuOptions.vue";

export default {
  components: {menuOptions},
  data: function () {
    return {
      showDivider: true,
    };
  },
  computed: {
    loggedIn: function () {
      let loggedIn = false;
      if (typeof this.$store.state.pagePost.data != "undefined") {
        loggedIn = this.$store.state.pagePost.data.userInfo.loggedIn;
      }
      return loggedIn;
    },
    containerWidth: function () {
      let containerWidth = 600;
      if (typeof this.mainMenuItem["containerWidth"] != "undefined") {
        containerWidth = this.mainMenuItem["containerWidth"];
      }
      return containerWidth;
    }
  },
  mounted() {
    this.showDivider = !(Object.keys(this.submenuList)[0] === "testing" || Object.keys(this.submenuList)[0] === "users");
  },
  props: ["mainMenuItem", "enableTabs", "submenuList", "menuItem"]
};

// {$tabsWidth=0}
// {$tabsCount=count($submenuList)}
// v-if=$tabsCount >1
// {math equation="($containerWidth /$tabsCount)"  assign="tabsWidthWithPad"}
// {math equation="$tabsWidthWithPad-10"  assign="tabsWidth"}
// {/if}
</script>


